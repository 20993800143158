import { graphQL, GraphQLResponse } from "@pedal/pedal-api";
import { Garage } from "../types";
import { GarageFields } from "../queries";

export function setVehicleGaragePartner(vehicleId: string, garageId: string) {
    return graphQL.sendCore<GraphQLResponse<"setVehicleGaragePartner", Garage | null>>({
        query: `mutation SetVehicleGaragePartner($vehicleId: UUID!, $garageId: UUID!) {
    setVehicleGaragePartner(vehicleId: $vehicleId, garageId: $garageId) {
        ${GarageFields}
    }
}`,
        variables: {
            vehicleId,
            garageId,
        },
    });
}
