import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Icon } from "./configure.svg";
import { constants } from "../constants";

const useStyles = makeStyles({
    icon: {
        width: constants.defaultSize,
        height: constants.defaultSize,
        display: "block",
    },
});

type ConfigureProps = {
    role?: string;
};

function Configure({ role }: ConfigureProps) {
    const style = useStyles();
    return <Icon className={style.icon} role={role} />;
}

export { Configure };
