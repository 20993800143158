import React from "react";
import { Typography, Grid, GridProps } from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import { Garage } from "../types";

const Img = styled("img")({
    display: "block",
    width: "40px",
    height: "40px",
});

const useStyles = makeStyles((theme) => ({
    servicesContainer: {
        [theme.breakpoints.up("md")]: {
            flex: 1,
        },
    },
    services: {
        width: "100%",
        display: "grid",
        gridTemplateColumns: "auto auto auto auto auto",
        gridTemplateRows: "auto",
        gridAutoFlow: "row",
        gridGap: theme.spacing(2),
        justifyContent: "start",
        [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "auto auto auto auto",
            gridGap: theme.spacing(3),
        },
    },
}));

type Props = {
    garage: Garage;
} & Pick<GridProps, "item" | "xs" | "sm" | "md" | "lg" | "xl">;

const GarageItemServices = (props: Props) => {
    const { garage, ...rest } = props;
    const styles = useStyles();

    const servicesWithIcons = garage.services.filter((s) => s.icon !== undefined);

    return (
        <Grid container direction="column" className={styles.servicesContainer} {...rest}>
            <Typography variant="h6" title={garage.name} gutterBottom>
                Services offered
            </Typography>
            <div className={styles.services}>
                {servicesWithIcons.map((s, index) => (
                    <Img key={index} src={s.icon} alt={s.type} title={s.type} />
                ))}
            </div>
        </Grid>
    );
};

export { GarageItemServices };
