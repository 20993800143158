class SocialUserDoesNotExist extends Error {
    constructor(m: string) {
        super(m);

        this.code = "SocialUserDoesNotExist";

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, SocialUserDoesNotExist.prototype);
    }

    public readonly code: string;
}

type ErrorCodes = "user.subscription.has.expired" | "SocialUserDoesNotExist";

type Error = {
    code: ErrorCodes;
    message?: string;
    subscribeLink?: string;
};

export { SocialUserDoesNotExist };
export type { ErrorCodes, Error };
