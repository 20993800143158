import { listenEvent, raiseEvent } from "@pedal/infrastructure";
import { auth } from "@pedal/pedal-api";
import { notConcurrent } from "@pedal/utility";
import { AppOnlyEventTypes } from "./eventTypes";

const requestNewToken = notConcurrent(function (isRetry: boolean) {
    return new Promise<string>((resolve) => {
        raiseEvent(AppOnlyEventTypes.accessTokenRequested, { isRetry });

        const disposer = listenEvent<{ accessToken: string }>(
            "accessTokenRetrieved",
            ({ accessToken }) => {
                resolve(accessToken);
                disposer();
            }
        );
    });
});

function initAuthOverrides() {
    const tokenCache: {
        accessToken?: string;
    } = {};

    auth.load = () => Promise.resolve();
    auth.login = () => Promise.resolve(null);
    auth.logout = () => Promise.resolve();

    auth.refreshToken = async () => {
        tokenCache.accessToken = await requestNewToken(true);
    };

    auth.acquireToken = async () => {
        if (tokenCache.accessToken) {
            return tokenCache.accessToken;
        }

        tokenCache.accessToken = await requestNewToken(false);

        return tokenCache.accessToken;
    };
    auth.changePassword = () => raiseEvent(AppOnlyEventTypes.changePassword);
}

export { initAuthOverrides };
