import React from "react";
import { Grid, Typography, IconButton } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { TextButton } from "../../button";

const GridValue = styled(Grid)({
    flex: "1",
});

type Props = {
    name?: string;
    value: string;
    actionText?: string;
    actionIcon?: React.ReactNode;
    onClick?: () => void;
};

function InlineActionableContent({ name, value, actionText, actionIcon, onClick }: Props) {
    return (
        <Grid container direction="row" alignItems="center" spacing={1} wrap="nowrap">
            <GridValue item container direction="column">
                {name && (
                    <Grid item>
                        <Typography variant="body2">{name}</Typography>
                    </Grid>
                )}
                <Grid item xs="auto">
                    <Typography variant="body1">{value ?? "--"}</Typography>
                </Grid>
            </GridValue>
            <Grid item xs="auto">
                {actionText && (
                    <TextButton variant="body2" color="primary" onClick={onClick}>
                        {actionText}
                    </TextButton>
                )}
                {actionIcon && (
                    <IconButton aria-label="edit" onClick={onClick} edge="end" size="small">
                        {actionIcon}
                    </IconButton>
                )}
            </Grid>
        </Grid>
    );
}

export { InlineActionableContent };
