import { graphQL, GraphQLMutationResponse } from "@pedal/pedal-api";

export function deleteUser(userId: string) {
    return graphQL.sendCore<GraphQLMutationResponse<"deleteUser">>({
        query: `mutation DeleteUser($userId: String!) {
    deleteUser(userId: $userId) {
        messages
    }
}`,
        variables: {
            userId,
        },
    });
}
