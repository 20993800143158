import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableRow, TableCell, Typography } from "@material-ui/core";
import { MotHistoryRow } from "./MotHistoryRow";
import { VehicleMotHistoryItem } from "./types";

type VehicleMotHistoryProps = {
    items: VehicleMotHistoryItem[];
    motExpiryDate?: string;
    denseMode: boolean;
};

function VehicleMotHistory({ items, motExpiryDate, denseMode }: VehicleMotHistoryProps) {
    const classes = useStyles();

    return (
        <Table className={classes.root} aria-label="mot history table">
            <TableBody>
                {motExpiryDate ? (
                    <TableRow>
                        <TableCell colSpan={denseMode ? 2 : 4}>
                            <Typography variant="h6">Next test due on {motExpiryDate}</Typography>
                        </TableCell>
                    </TableRow>
                ) : null}
                {items.map((item) => (
                    <MotHistoryRow key={item.testNumber} item={item} denseMode={denseMode} />
                ))}
            </TableBody>
        </Table>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        "& .row:first-child > *": {
            paddingTop: theme.spacing(1.5),
        },
        "& .row:last-child > *": {
            borderBottom: "unset",
            paddingBottom: theme.spacing(1),
        },
    },
}));

export { VehicleMotHistory };
export type { VehicleMotHistoryProps };
