import React from "react";
import MUIDataTable, { MUIDataTableColumn, MUIDataTableIsRowCheck } from "mui-datatables";
import { styled } from "@material-ui/core/styles";
import { baseOptions, detailColumn } from "./constants";

const MUIDataTableStyled = styled(MUIDataTable)((obj) => {
    return {
        "& > :nth-child(2)": {
            paddingBottom: obj.theme.spacing(4),
        },
    };
});

export interface DataTableProps {
    columns: Array<MUIDataTableColumn>;
    data: Array<object>;
    options: DataTableOptions;
    hideDetailColumn?: boolean;
    className?: string;
}

export interface DataTableOptions {
    page: number;
    count: number;
    rowsPerPage?: number;
    expandableRows?: boolean;
    rowsExpanded?: any[];
    isRowExpandable?: (dataIndex: number, expandedRows?: MUIDataTableIsRowCheck) => boolean;
    renderExpandableRow?: (
        rowData: any[],
        rowMeta: { dataIndex: number; rowIndex: number }
    ) => React.ReactNode;
    onTableChange?: (action: string, tableState: any) => void;
    onColumnSortChange?: (changedColumn: any, direction: any) => void;
}

function DataTable(props: DataTableProps) {
    const columns = React.useMemo(() => {
        if (props.hideDetailColumn) {
            return props.columns;
        } else {
            return [...props.columns, detailColumn];
        }
    }, [props.hideDetailColumn, props.columns]);

    const opts = { ...baseOptions, ...props.options };

    return (
        <MUIDataTableStyled
            className={props.className}
            columns={columns}
            data={props.data}
            options={opts}
            title={null}
        />
    );
}

export { DataTable };
