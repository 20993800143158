import { logger, raiseEvent } from "@pedal/infrastructure";
import { Location } from "./types";
import { EventTypes } from "../../eventTypes";
import { DeviceLocationData } from "../../location";

type Locations = { locations: Array<Location> };
type ResultType = Location | Locations;

type LocationUpdate = (result: ResultType) => void;

function hasLocations(input: ResultType): input is Locations {
    return (input as Locations).locations !== undefined;
}

function processLocation(location: Location) {
    if (location.coords.latitude === 0 || location.coords.longitude === 0) {
        logger.warn(
            `Invalid location retrieved. lat: ${location.coords.latitude}, long: ${location.coords.longitude}`
        );

        return;
    }

    raiseEvent<DeviceLocationData>(EventTypes.deviceLocationRequestComplete, {
        coordinates: location.coords,
        isBackgroundUpdate: true,
    });
}

// this is called when the location is asynchronously updated from the device (device detects movement)
function locationUpdate(): LocationUpdate {
    return function (result) {
        if (hasLocations(result)) {
            const locations = result.locations;
            if (locations.length === 0) {
                logger.warn("No location data sent");
                return;
            }

            locations.sort((a, b) => b.timestamp - a.timestamp);
            const latestLocation = locations[0];

            processLocation(latestLocation);
        } else {
            processLocation(result);
        }
    };
}

export { locationUpdate };
export type { LocationUpdate };
