import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { style } from "../style";
import { NotificationIcon } from "./NotificationIcon";

type NotificationContentProps = {
    title: string;
    dateString: string;
    icon: string;
    messageAsHtml?: string;
    wrapDescription?: boolean;
    source?: React.ReactNode;
};

const NotificationContent = (props: NotificationContentProps) => {
    const { title, dateString: date, icon, messageAsHtml, wrapDescription, source } = props;
    const styles = useStyles();

    return (
        <Grid container direction="column" className={styles.contentContainer}>
            <Grid item className={styles.sectionContainer}>
                <Grid container direction="row" alignItems="center">
                    <Grid item className={styles.iconContainer}>
                        <NotificationIcon src={icon} alt="notification" />
                    </Grid>
                    <Grid item className={styles.titleContainer}>
                        <Typography variant="body2" className={styles.text}>
                            {date}
                        </Typography>
                        <Typography variant="subtitle2" title={title} className={styles.text}>
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {messageAsHtml && (
                <Grid item className={styles.sectionContainer}>
                    <Typography
                        variant="body2"
                        className={styles.text}
                        style={{ whiteSpace: wrapDescription ? "normal" : "nowrap" }}
                        dangerouslySetInnerHTML={{ __html: messageAsHtml }}
                    />
                </Grid>
            )}
            {source && <div className={styles.source}>{source}</div>}
        </Grid>
    );
};

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        position: "relative",
    },
    sectionContainer: {
        marginBottom: theme.spacing(1.5),
    },
    iconContainer: {
        marginRight: theme.spacing(1.5),
    },
    titleContainer: {
        overflow: "hidden",
        flex: 1,
    },
    text: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    source: {
        top: -theme.spacing(style.padding),
        right: -theme.spacing(style.padding),
        position: "absolute",
        maxWidth: "130px",
        height: "30px",
    },
}));

export { NotificationContent };
export type { NotificationContentProps };
