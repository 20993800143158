import React, { ReactChild } from "react";
import { Box, CssBaseline, Container, ContainerProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ConfirmDialog } from "@pedal/component-library";
import { getAppOptions } from "@pedal/infrastructure";
import { SignOut } from "features/drawer-menu/sign-out/SignOut";
import { HeaderContainer, FooterContainer } from "./layout";

type AppContainerProps = {
    isApp: boolean;
    hideMenuButton?: boolean;
    maxWidth: ContainerProps["maxWidth"];
    children: ReactChild;
};

function AppContainer({ isApp, hideMenuButton = false, maxWidth, children }: AppContainerProps) {
    const classes = useStyles();

    return (
        <div className={classes.app}>
            <CssBaseline />
            <HeaderContainer maxWidth={maxWidth} link="/">
                {!hideMenuButton ? (
                    <Box p={2} marginRight={-2}>
                        <SignOut />
                    </Box>
                ) : (
                    <Box p={3}></Box>
                )}
            </HeaderContainer>
            <Container className={classes.appContent} maxWidth={maxWidth}>
                {children}
            </Container>
            {!isApp ? <FooterContainer /> : null}
            <ConfirmDialog />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    app() {
        const insets = getAppOptions()?.insets;
        return {
            paddingTop: insets?.top,
            display: "flex",
            minHeight: "100vh",
            flexDirection: "column",
        };
    },
    appContent: {
        flex: 1,
        marginBottom: theme.spacing(2),
    },
}));

export { AppContainer };
