import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { style } from "../style";

type NotificationSourceProps = {
    icon: string;
    type: string;
    color?: string;
    style?: React.CSSProperties;
};

const NotificationSource = (props: NotificationSourceProps) => {
    const styles = useStyles(props);
    const { icon, type, style } = props;

    return (
        <div className={styles.container} style={style}>
            <img className={styles.image} src={icon} alt={type} />
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        height: "100%",
        padding: theme.spacing(0.5, 1),
        overflow: "hidden",
        borderRadius: `0px ${
            theme.spacing(style.borderRadius) - style.borderWidthPixels
        }px 0px ${theme.spacing(style.borderRadius)}px`,
        backgroundColor: ({ color }: NotificationSourceProps) => {
            return color;
        },
    },
    image: {
        display: "block",
        width: "100%",
        height: "100%",
        objectFit: "contain",
    },
}));

export { NotificationSource };
export type { NotificationSourceProps };
