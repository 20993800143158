import { logger, raiseEvent } from "@pedal/infrastructure";
import { auth } from "../authentication/authenticationService";
import { configuration } from "../config";

const contentType = "application/json;";

export function postJson<T>(url: string, body?: any) {
    return sendJson<T>(url, "POST", body);
}

export function getJson<T>(url: string) {
    return sendJson<T>(url, "GET");
}

async function sendJson<T>(
    url: string,
    method: "POST" | "PUT" | "PATCH" | "GET",
    body?: any,
    hasResponseBody = true
) {
    const fullUrl = `${configuration.apiUri}${url}`;
    const res = await sendInternal(
        () =>
            new Request(fullUrl, {
                method: method,
                body: body ? JSON.stringify(body) : undefined,
                mode: "cors",
                headers: {
                    "Content-Type": contentType,
                    "X-Pedal-Client-Type": "Consumer",
                },
            }),
        hasResponseBody,
        0
    );

    return res as T;
}

async function sendInternal(
    requestConstructor: () => Request,
    hasResponseBody: boolean,
    retryCount: number
): Promise<any> {
    const token = await auth.acquireToken();

    const request = requestConstructor();

    request.headers.append("Authorization", `Bearer ${token}`);
    request.headers.append("Accept", contentType);

    const response = await fetch(request);

    let json: any;
    if (!response.ok || hasResponseBody) {
        json = await response.json();
    }

    // if it's a 400 or 422, these are validation errors and are expected
    if (!response.ok && response.status !== 400 && response.status !== 422) {
        if (response.status === 401 && json.code === "invalid_token" && retryCount < 3) {
            // token has expired, try and refresh if possible and rerun the operation
            logger.info("Requesting new token and retrying operation");
            await auth.refreshToken();
            return await sendInternal(requestConstructor, hasResponseBody, retryCount + 1);
        }

        // on unauthorised errors, raise event for rest of the system to handle the error
        if (response.status === 403) {
            raiseEvent("userAppAuthError", json);
        }

        if (response.headers.get("Content-Type")?.startsWith("application/problem+json")) {
            const res = json as ProblemDetails;
            throw new Error(`${res.title}: ${res.description}`);
        } else {
            throw json;
        }
    }

    // check to see if subscription was downgraded
    if (response.headers.has("X-Pedal-Subscription-Downgraded")) {
        raiseEvent("onUserMembershipDowngraded");
    }

    return json;
}

interface ProblemDetails {
    title: string;
    description: string;
}
