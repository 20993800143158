import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { VehicleDataState } from "../vehicleTypes";
import { Weather } from "api";
import { getLocationWeather as getLocationWeatherApi } from "./queries";

const getLocationWeather = createAsyncThunk(
    "vehicleLocationWeather/getLocationWeather",
    async ({ vehicleId, asyncUpdate }: { vehicleId: string; asyncUpdate?: boolean }) => {
        const weather = await getLocationWeatherApi(vehicleId);

        if (weather !== null) {
            const dayOrNight = weather.typeId.endsWith("d") ? "day" : "night";

            // get the icon
            const icon = await import(
                `@pedal/icons/dist/weather/${dayOrNight}/${weather.typeId}.svg`
            );

            weather.iconUrl = icon.default;

            return { weather: weather };
        }

        return { weather: undefined };
    }
);

const initialState: VehicleDataState<Weather> = {
    loading: false,
};
const vehicleLocationWeatherSlice = createSlice({
    name: "vehicleLocationWeather",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getLocationWeather.pending, (state, action) => {
            if (!action.meta.arg.asyncUpdate) {
                state.loading = true;
            }
        });
        builder.addCase(getLocationWeather.rejected, (state) => {
            state.loading = false;
            state.data = undefined;
        });
        builder.addCase(getLocationWeather.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.weather;
        });
    },
});

export { getLocationWeather };

export default vehicleLocationWeatherSlice.reducer;
