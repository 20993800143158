import React from "react";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { podContentPadding } from "../Pod/Pod";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(0, -podContentPadding),
    },
}));

/**
 * A divider that can be used within the content of a pod.
 *
 * @returns {JSX.Element} The component element.
 */
const PodContentDivider = () => {
    const styles = useStyles();
    return <Divider className={styles.root} />;
};

export { PodContentDivider };
