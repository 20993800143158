import React, { useState, useEffect, CSSProperties, ReactChild } from "react";
import { makeStyles } from "@material-ui/styles";
import { ContentLoader } from "../ContentLoader/ContentLoader";
import clsx from "clsx";

const useClasses = makeStyles({
    root: {
        position: "relative",
        height: "100%",
        width: "100%",
    },
});

type DelayProps = {
    style?: CSSProperties;
    className?: string;
    children: ReactChild;
    loaderSize?: number;
    /**
     * Optional delay to use for when to show the children content.
     */
    delay?: number;
};

/**
 * Shows the children after a specified delay. While the delay is in progress, a loader is shown instead.
 *
 * @param {DelayProps} props The component props.
 * @returns {JSX.Element} The component.
 */
function Delay(props: DelayProps): JSX.Element {
    const { className, style, children, loaderSize, delay = 500 } = props;

    const [show, setShow] = useState(false);
    const styles = useClasses();

    useEffect(() => {
        const handle = setTimeout(() => setShow(true), delay);

        return () => clearTimeout(handle);
    }, [delay]);

    return (
        <div style={style} className={clsx(styles.root, className)}>
            {show ? children : <ContentLoader size={loaderSize} />}
        </div>
    );
}

export { Delay };
export type { DelayProps };
