import React from "react";
import { DialogActions } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { ActionButton, ActionButtonProps } from "../../button";

const useStyles = makeStyles((theme) => ({
    actions: {
        justifyContent: "center",
        padding: theme.spacing(1),
    },
}));

type Props = {
    disabled?: boolean;
    loading?: boolean;
    okButtonMessage: string;
    className?: string;
    onCancel: NonNullable<ActionButtonProps["onClick"]>;
    onOk: NonNullable<ActionButtonProps["onClick"]>;
};

function CommonDialogActions(props: Props) {
    const classes = useStyles();

    return (
        <DialogActions className={`${classes.actions} ${props.className}`}>
            <ActionButton
                onClick={props.onCancel}
                endIcon={<HighlightOffIcon />}
                text="Cancel"
                disabled={props.disabled}
            />
            <ActionButton
                onClick={props.onOk}
                endIcon={<ArrowForwardIcon />}
                text={props.okButtonMessage}
                disabled={props.disabled}
                loading={props.loading}
            />
        </DialogActions>
    );
}

export { CommonDialogActions };
