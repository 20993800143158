import { withStyles } from "@material-ui/core/styles";
import { VehicleDataItemProps } from "./VehicleDataItem";

export function captilizeValue(Component: (props: VehicleDataItemProps) => JSX.Element) {
    return withStyles({
        value: {
            textTransform: "capitalize",
        },
    })(Component);
}
