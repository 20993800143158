import { raiseEvent, listenEvent } from "@pedal/infrastructure";
import { EventTypes } from "../eventTypes";
import { setConnectedDeviceType, resetDeviceType } from "store/location/locationSlice";
import { getCurrentVehicleNotifications } from "store/vehicle/notifications/slice";
import {
    updateVehicleSensor,
    updateVehicleSubscription,
    removeSensor,
    setCurrentVehicle,
} from "store/vehicle";
import { Store } from "store";
import { VehicleSensor } from "api";

function setupSensorEventListeners({ dispatch, getState }: Store) {
    // sensor added
    listenEvent(EventTypes.sensorAdded, (data?: { sensor: VehicleSensor; vehicleId: string }) => {
        if (!data) {
            return;
        }

        const { sensor, vehicleId } = data;

        const currentVehicleId = getState().currentVehicle.vehicle?.id;
        if (vehicleId !== currentVehicleId) {
            dispatch(setCurrentVehicle(vehicleId));
            return;
        }

        dispatch(updateVehicleSensor(sensor));

        dispatch(updateVehicleSubscription({ plan: "PEDAL_CONNECT" }));

        // refresh the notifications
        dispatch(getCurrentVehicleNotifications());

        // set location device type
        dispatch(setConnectedDeviceType({ deviceType: "SENSOR", resetLocation: true }));
    });

    // sensor removed
    listenEvent(EventTypes.onRemoveSensor, (data?: { vehicleId: string }) => {
        if (!data) {
            return;
        }

        const { vehicleId } = data;

        const currentVehicleId = getState().currentVehicle.vehicle?.id;
        if (vehicleId !== currentVehicleId) {
            dispatch(setCurrentVehicle(vehicleId));
            return;
        }

        dispatch(removeSensor(vehicleId));

        dispatch(updateVehicleSubscription({ plan: "PEDAL_PRO" }));

        // refresh the notifications
        dispatch(getCurrentVehicleNotifications());

        // reset location device type
        dispatch(resetDeviceType());

        raiseEvent(EventTypes.sensorRemoved, { vehicleId });
    });
}

export { setupSensorEventListeners };
