import { makeStyles } from "@material-ui/core/styles";

function useDisabledOverlayStyles(inactive: boolean) {
    const styles = useStyles();
    const className = inactive ? undefined : styles.root;

    return className;
}

const useStyles = makeStyles({
    root: {
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        opacity: 0.3,
    },
});

export { useDisabledOverlayStyles };
