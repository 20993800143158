import React from "react";
import { raiseEvent } from "@pedal/infrastructure";
import { EventTypes } from "../eventTypes";

function useSubscriptionUpgrade(vehicleId: string) {
    return React.useCallback(() => {
        raiseEvent(EventTypes.displayMembershipUpgrade, { routeId: vehicleId });
    }, [vehicleId]);
}

export { useSubscriptionUpgrade };
