import { raiseEvent } from "@pedal/infrastructure";
import { AppDispatch } from "store/types";
import { EventTypes } from "../eventTypes";
import { setLocation, locationUpdate, SetLocation, LocationUpdate } from "./interop";
import { RootState } from "store";

declare global {
    interface Window {
        pedalApp: Partial<{
            setLocation: SetLocation;
            locationUpdate: LocationUpdate;
            // deprecated - use raiseEvent
            setVehicle: (vehicleId: string) => void;
            raiseEvent: typeof raiseEvent;
        }>;
    }
}

function initializeInteropObject() {
    // init pedalapp global object, this is used from the app directly by executing scripts against the global object
    window.pedalApp = {
        // deprecated - use raiseEvent
        setVehicle: (vehicleId) => raiseEvent(EventTypes.onChangeVehicle, { vehicleId }),
        raiseEvent: raiseEvent,
    };
}

function initializeAppStoreInteropObject(_: () => RootState, __: AppDispatch) {
    window.pedalApp.setLocation = setLocation();
    window.pedalApp.locationUpdate = locationUpdate();
}

export { initializeInteropObject, initializeAppStoreInteropObject };
