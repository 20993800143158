export function isHexLightColor(hexColor: string) {
    if (hexColor.length !== 7) {
        throw Error("Only six-digit hex colors are allowed.");
    }

    const matches = hexColor.substring(1).match(/.{1,2}/g);
    if (matches === null) {
        return undefined;
    }

    const r = parseInt(matches[0], 16);
    const g = parseInt(matches[1], 16);
    const b = parseInt(matches[2], 16);

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
        return true;
    }

    return false;
}

export function lightenDarkenColor(col: string, amt: number) {
    let usePound = false;
    if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
    }

    const num = parseInt(col, 16);

    let r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    let b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    let g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

export function opacity(hex: string, opacity: number) {
    if (typeof hex !== "string" || !/^#([A-Fa-f0-9]{3}$|[A-Fa-f0-9]{6}$|[A-Fa-f0-9]{8}$)/.test(hex))
        throw new Error("Invalid hexadecimal color value");
    if (typeof opacity !== "number" || opacity > 1 || opacity < 0)
        throw new Error("Opacity should be float between 0 - 1");

    let color = hex.substring(1);
    if (color.length === 8) color = color.substring(0, color.length - 2);
    if (color.length === 3) color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];

    color += Math.round(opacity * 255)
        .toString(16)
        .padStart(2, "0");

    return `#${color}`.toUpperCase();
}
