import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { isDefault, NotificationCode } from "@pedal/vehicle/notifications";
import * as mutations from "./mutations";
import { getNotificationsQuery } from "./getNotificationsQuery";
import { VehicleNotificationsState } from "../vehicleTypes";
import { RootState } from "../../index";

const getCurrentVehicleNotifications = createAsyncThunk(
    "vehicleNotifications/getCurrentVehicleNotifications",
    async (arg: undefined, options) => {
        const currentState = options.getState() as RootState;

        const currentVehicleId = currentState.currentVehicle.vehicle!.id;
        const currentUserId = currentState.user.id;
        const partner = currentState.partner.data!;

        const notifications = await getNotificationsQuery(currentVehicleId, currentUserId, partner);

        return { notifications };
    }
);

const dismissNotification = createAsyncThunk(
    "vehicleNotifications/skip",
    async ({ notificationId }: { notificationId: string }) => {
        const res = await mutations.skipNotification(notificationId);
        return res;
    }
);

const deleteNotification = createAsyncThunk(
    "vehicleNotifications/delete",
    ({ notificationId }: { notificationId: string }) => {
        mutations.deleteNotification(notificationId);
    }
);

const resolveNotification = createAsyncThunk(
    "vehicleNotifications/resolve",
    ({ notificationId }: { notificationId: string }) => {
        mutations.resolveNotification(notificationId);
    }
);

const vehicleInitialState: VehicleNotificationsState = {
    loading: false,
    notifications: [],
};
const vehicleNotificationsSlice = createSlice({
    name: "vehicleNotifications",
    initialState: vehicleInitialState,
    extraReducers: (builder) => {
        builder.addCase(getCurrentVehicleNotifications.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCurrentVehicleNotifications.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getCurrentVehicleNotifications.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.notifications = action.payload.notifications;
            }
        });
        builder.addCase(dismissNotification.pending, (state, action) => {
            const notificationId = action.meta.arg.notificationId;

            state.notifications = state.notifications.filter((n) => n.id !== notificationId);
        });
        builder.addCase(deleteNotification.fulfilled, (state, action) => {
            const notificationId = action.meta.arg.notificationId;

            state.notifications = state.notifications.filter((n) => n.id !== notificationId);
        });
        builder.addCase(resolveNotification.fulfilled, (state, action) => {
            const notificationId = action.meta.arg.notificationId;

            state.notifications = state.notifications.filter((n) => n.id !== notificationId);
        });
    },
    reducers: {
        removeByCode: (state, action: PayloadAction<NotificationCode>) => {
            state.notifications = state.notifications.filter(
                (n) => !isDefault(n) || n.code !== action.payload
            );
        },
        resetNotifications: (state) => {
            if (!state) {
                return state;
            }

            state.loading = true;
            state.notifications = [];
        },
    },
});

export {
    getCurrentVehicleNotifications,
    dismissNotification,
    deleteNotification,
    resolveNotification,
};
export const { resetNotifications, removeByCode } = vehicleNotificationsSlice.actions;

export default vehicleNotificationsSlice.reducer;
