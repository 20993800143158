import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        "& > *": {
            marginBottom: theme.spacing(2),
            "&:last-child": {
                marginBottom: 0,
            },
        },
    },
}));

function VehicleDataBoxList({ children }: { children: React.ReactNode }) {
    const styles = useStyles();
    return <div className={styles.container}>{children}</div>;
}

export { VehicleDataBoxList };
