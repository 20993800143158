import { colors } from "@pedal/styleguide";
import govUk from "@pedal/icons/dist/logos/gov-uk.png";
import {
    SystemNotificationContext,
    DefaultNotificationItemView,
    NotificationCode,
} from "@pedal/vehicle/notifications";
import { Partner } from "api";
import { getPartnerBannerBlobUrl } from "../../../partner/services";
import { getServiceProvider } from "../../../serviceProvider/services";

const ukGovSource: DefaultNotificationItemView["source"] = {
    icon: govUk,
    type: "gov.uk",
    color: colors.govUk.black,
};

const pedalSource: DefaultNotificationItemView["source"] = {
    icon: "/pedal-white.svg",
    type: "Pedal",
    color: colors.platinum,
};

function getSourceFromCode(code: NotificationCode) {
    switch (code) {
        case NotificationCode.MotIsExpiring:
        case NotificationCode.RoadTaxIsExpiring:
            return ukGovSource;
        default:
            return pedalSource;
    }
}

async function getSourceFromPartner(
    partner: Partner
): Promise<DefaultNotificationItemView["source"] | undefined> {
    const blobUrl = await getPartnerBannerBlobUrl(partner.id);
    if (!blobUrl) {
        return;
    }

    return {
        icon: blobUrl,
        type: partner.name,
        color: partner.configuration?.theme?.color,
    };
}

async function getSourceFromServiceProvider(
    context: SystemNotificationContext
): Promise<DefaultNotificationItemView["source"] | undefined> {
    if (!context.contextId) {
        return;
    }

    const provider = await getServiceProvider(context.contextId);
    if (!provider) {
        return;
    }

    return {
        icon: provider.bannerUrl,
        type: context.contextName ?? provider.name,
        style: {
            backgroundColor: provider.primaryColor ?? undefined,
            borderLeft: provider.borderColor ? `2px solid ${provider.borderColor}` : undefined,
            borderBottom: provider.borderColor ? `2px solid ${provider.borderColor}` : undefined,
        },
    };
}

export {
    ukGovSource,
    pedalSource,
    getSourceFromCode,
    getSourceFromPartner,
    getSourceFromServiceProvider,
};
