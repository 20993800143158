import React from "react";
import { Grid, Typography, IconButton } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import { isPromise } from "../../utils";

const GridValue = styled(Grid)({
    flex: "1",
});

const SaveIconButton = styled(IconButton)({
    marginTop: "6px",
});

type InlineEditableContentProps = {
    name?: string;
    value: string;
    field: React.ReactElement;
    isSubmitting?: boolean;
    onSubmit?: () => void | Promise<void>;
};

function InlineEditableContent({
    name,
    value,
    field,
    isSubmitting,
    onSubmit: onSubmitProps,
}: InlineEditableContentProps) {
    const [edit, setEdit] = React.useState(false);

    const onEditClick = React.useCallback(() => setEdit(true), []);
    const onSubmit = React.useCallback(() => {
        const res = onSubmitProps && onSubmitProps();
        if (res && isPromise(res)) {
            res.finally(() => setEdit(false));
        } else {
            setEdit(false);
        }
    }, [onSubmitProps]);

    return (
        <Grid container direction="row" alignItems={!edit ? "center" : "flex-start"} wrap="nowrap">
            <GridValue item container direction="column">
                {!edit && name ? (
                    <Grid item>
                        <Typography variant="body2">{name}</Typography>
                    </Grid>
                ) : null}
                <Grid item xs="auto">
                    {edit ? field : <Typography variant="body1">{value ?? "--"}</Typography>}
                </Grid>
            </GridValue>
            <Grid item xs="auto">
                {!edit ? (
                    <IconButton aria-label="edit" onClick={onEditClick} edge="end" size="small">
                        <EditIcon fontSize="small" color="primary" />
                    </IconButton>
                ) : (
                    <SaveIconButton
                        aria-label="save"
                        onClick={onSubmit}
                        edge="end"
                        size="small"
                        disabled={isSubmitting}
                    >
                        <SaveIcon fontSize="small" color="primary" />
                    </SaveIconButton>
                )}
            </Grid>
        </Grid>
    );
}

export { InlineEditableContent };
