import { NotificationSource } from "../types";

type NotificationSourceGetter =
    | NotificationSource
    | PromiseLike<NotificationSource>
    | (() => NotificationSource)
    | (() => PromiseLike<NotificationSource>);

function getNotificationSource(getter: NotificationSourceGetter) {
    if (typeof getter === "function") {
        return Promise.resolve(getter());
    }

    return Promise.resolve(getter);
}

export { getNotificationSource };
export type { NotificationSourceGetter };
