const configuration = {
    logoutLink: process.env.REACT_APP_PEDALAPP_LOGOUT_LINK as string | undefined,
    signupBaseUrl: process.env.REACT_APP_SIGNUP_BASEURI as string,
    sensor: {
        stock: {
            pfmb01: Number.parseInt(process.env.REACT_APP_SENSOR_PFMB01_STOCK!),
            pfmb03: Number.parseInt(process.env.REACT_APP_SENSOR_PFMB03_STOCK!),
        },
        prices: {
            pfmb01: Number.parseFloat(process.env.REACT_APP_SENSOR_PFMB01_PRICE!),
            pfmb03: Number.parseFloat(process.env.REACT_APP_SENSOR_PFMB03_PRICE!),
            shipping: Number.parseFloat(process.env.REACT_APP_SENSOR_SHIPPING_PRICE!),
        },
        showRawPrice: process.env.REACT_APP_SENSOR_SHOW_RAW_PRICE === "1",
        vat: Number.parseInt(process.env.REACT_APP_SENSOR_VAT!),
    },
    privacyPolicyLink: process.env.REACT_APP_PEDALAPP_Privacy_Policy,
    termsConditionsLink: process.env.REACT_APP_PEDALAPP_Terms_Conditions,
    cookiePolicyLink: process.env.REACT_APP_PEDALAPP_Cookie_Policy,
    eulaAgreementLink: process.env.REACT_APP_PEDALAPP_Eula_Agreement,
    alexaAppLink: process.env.REACT_APP_PEDALAPP_ALEXA_APP_LINK,
    googleAssistantAppLink: process.env.REACT_APP_PEDALAPP_GOOGLE_ASSISTANT_APP_LINK,
    actionLinks: {
        ved: {
            sorn: "https://sorn.service.gov.uk",
            renew: "https://vehicletax.service.gov.uk",
        },
        insurance: "https://car.gocompare.com",
    },
};

function noLogoutUrl() {
    configuration.logoutLink = undefined;
}

export { configuration, noLogoutUrl };
