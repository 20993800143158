import { logger, raiseEvent } from "@pedal/infrastructure";
import { EventTypes } from "./eventTypes";

function parseDeepLink(url: string): { vehicleId: string | undefined; path: string | undefined } {
    // we need to be able to match
    // pedal://vehicles/{vehicleId}/path
    // pedal.dev://vehicles/{vehicleId}/path
    // pedal.xyz.dev://vehicles/{vehicleId}/path
    // pedal://vehicles/{vehicleId}/path
    // as well as pedal://add/vehicle
    const groups = /pedal(?:\.[a-zA-Z0-9]+)*:\/\/(.*?)(?:\/(.*?))?\/(.*)/gm.exec(url);
    if (!groups) {
        return {
            vehicleId: undefined,
            path: undefined,
        };
    }

    switch (groups[1]) {
        case "vehicles":
            return {
                vehicleId: groups[2] === "default" ? undefined : groups[2],
                path: groups[3],
            };
        case "add":
            return {
                vehicleId: undefined,
                path: `${groups[1]}/${groups[3]}`,
            };
        default:
            return {
                vehicleId: undefined,
                path: undefined,
            };
    }
}

type Result =
    | [string, Record<string, unknown> | undefined]
    | ((vehicleId: string) => [string, Record<string, unknown> | undefined]);

const pathMap: Record<string, Result> = {
    "mot-history": (vehicleId) => [EventTypes.showVehicleMotHistory, { routeId: vehicleId }],
    valuation: (vehicleId) => [EventTypes.showValuations, { vehicleId }],
    location: (vehicleId) => [EventTypes.viewLocation, { routeId: vehicleId }],
    dashboard: [EventTypes.showDashboard, undefined],
    "add-driver": [EventTypes.showManageSettings, undefined],
    garage: (vehicleId) => [EventTypes.showGarageSelector, { routeId: vehicleId }],
    "upgrade-membership": (vehicleId) => [
        EventTypes.displayMembershipUpgrade,
        { routeId: vehicleId },
    ],
    "fuel-map": [EventTypes.showFuelMap, undefined],
    "add/vehicle": [EventTypes.addVehicle, undefined],
    "add/pedal-connect": [EventTypes.addPedalConnect, undefined],
    "add/connect-service": [EventTypes.addPedalConnect, undefined],
    "add/driver": [EventTypes.addDriver, undefined],
};

function handleLinking(path: string | undefined, vehicleId: string | undefined, timeout: number) {
    if (path && vehicleId) {
        const dataFn = pathMap[path];
        if (dataFn) {
            const data = typeof dataFn === "function" ? dataFn(vehicleId) : dataFn;
            setTimeout(raiseEvent.bind(undefined, data[0], data[1]), timeout);

            return;
        }
    }

    logger.warn(`Cannot handle link`, path, vehicleId);
}

export { parseDeepLink, handleLinking };
