import { listenEvent } from "@pedal/infrastructure";
import { setCurrentVehicle } from "store/vehicle";
import { Store } from "store/types";
import { parseDeepLink, handleLinking } from "../deepLinking";

function setupDeepLinkingHandler({ getState, dispatch }: Store) {
    return listenEvent("onDeepLink", ({ url }: { url: string }) => {
        let { path, vehicleId } = parseDeepLink(url);
        if (!path) {
            return;
        }

        const currentVehicleId = getState().currentVehicle.vehicle?.id;

        if (vehicleId) {
            if (currentVehicleId !== vehicleId) {
                dispatch(setCurrentVehicle(vehicleId));
                handleLinking(path, vehicleId, 300);
            } else {
                handleLinking(path, vehicleId, 0);
            }

            return;
        }

        vehicleId = currentVehicleId;
        handleLinking(path, vehicleId, 0);
    });
}

export { setupDeepLinkingHandler };
