import React from "react";
import { Checkbox, ListItemText, ListItemIcon } from "@material-ui/core";

export const selectListUtilities = {
    defaultRenderer: function <Item extends { name: string; checked: boolean }>(item: Item) {
        const labelId = `selectlist-label-${item.name}`;
        return (
            <>
                <ListItemText id={labelId} primary={item.name} />
                <ListItemIcon>
                    <Checkbox
                        edge="end"
                        checked={item.checked}
                        tabIndex={-1}
                        color="primary"
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                    />
                </ListItemIcon>
            </>
        );
    },
};
