import { AppDispatch } from "store/types";
import { vehicleConfigured, setInitialConfigurationRequired } from "store/vehicle";
import { getCurrentVehicleNotifications } from "store/vehicle/notifications/slice";
import { raiseEvent } from "@pedal/infrastructure";
import { EventTypes } from "common";

function onSkipVehicleConfiguration(vehicleId: string, dispatch: AppDispatch) {
    // remove configure notification
    dispatch(vehicleConfigured());

    // raise vehicle configured event
    raiseEvent(EventTypes.vehicleConfigured, { vehicleId });

    // set initial configuration required to false
    dispatch(setInitialConfigurationRequired(false));

    // refresh notifications
    dispatch(getCurrentVehicleNotifications());
}

export { onSkipVehicleConfiguration };
