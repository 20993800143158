import React from "react";
import { Link } from "react-router-dom";
import { Container, Grid, Box, ContainerProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "@pedal/styleguide";

const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
    },
    appLogo: {
        height: "100%",
        width: "105px",
        display: "inline-flex",
        alignItems: "center",
        "& img": {
            width: "105px",
        },
    },
    partnerLogo: {
        position: "relative",
        "& > a": {
            display: "block",
            textDecoration: "none",
            color: "inherit",
        },
        "& img": {
            maxHeight: "75px",
            maxWidth: "250px",
            width: "100%",
            display: "block",
        },
        "& .powered-by": {
            position: "absolute",
            bottom: "-3px",
            right: "0",
            display: "none",
            alignItems: "center",
            whiteSpace: "nowrap",
            flexWrap: "nowrap",
            "& > img": {
                width: "57px",
                height: "15px",
                marginLeft: "5px",
            },
            "&.visible": {
                display: "flex",
            },
        },
    },
    end: {
        "& button": {
            color: colors.pedalBlue,
        },
    },
}));

type HeaderProps = {
    link?: string;
    maxWidth?: ContainerProps["maxWidth"];
    className?: ContainerProps["className"];
    style?: ContainerProps["style"];
    children: {
        appLogo: React.ReactNode;
        end: React.ReactNode;
    };
};

const WrapWithLink = (link: string | undefined, C: React.ReactNode) => {
    if (link) {
        return <Link to={link}>{C}</Link>;
    }

    return C;
};

const AddPoweredBy = (C: React.ReactNode) => {
    if (!C) {
        return null;
    }

    return (
        <>
            {C}
            <Box className="powered-by visible">
                <span>Powered by</span>
                <img src="/pedal-text-black.svg" alt="logo" loading="eager" />
            </Box>
        </>
    );
};

const Header = (props: HeaderProps) => {
    const styles = useStyles(props);
    const { link, maxWidth, className, style, children } = props;
    const { appLogo, end } = children;

    let logo: React.ReactNode;
    let logoClassName: string;
    if (appLogo) {
        logo = AddPoweredBy(appLogo);
        logoClassName = styles.partnerLogo;
    } else {
        logo = <img src="/pedal-text-black.svg" alt="logo" />;
        logoClassName = styles.appLogo;
    }

    logo = WrapWithLink(link, logo);

    return (
        <header className={className} style={style}>
            <Container maxWidth={maxWidth}>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={styles.container}
                    wrap="nowrap"
                >
                    <Grid item className={logoClassName}>
                        {logo}
                    </Grid>
                    <Grid item className={styles.end}>
                        {end}
                    </Grid>
                </Grid>
            </Container>
        </header>
    );
};

export { Header };
export type { HeaderProps };
