import React from "react";
import { raiseEvent } from "@pedal/infrastructure";
import { EventTypes } from "../eventTypes";

function useAddDriver(vehicleId: string) {
    return React.useCallback(() => {
        raiseEvent(EventTypes.addDriver, { vehicleId });
    }, [vehicleId]);
}

export { useAddDriver };
