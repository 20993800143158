import React from "react";
import { Grid } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { VehicleDataItem } from "@pedal/component-library";
import { VehicleStatus } from "./types";
import { useVehicleStatusData } from "./hooks";

type Props = {
    data: VehicleStatus;
    showMpg: boolean;
};

const Container = styled(Grid)({
    height: "100%",
});

const VehicleStatusData = (props: Props) => {
    const items = useVehicleStatusData(props.data);

    return (
        <Container container direction="row" justify="flex-start" alignItems="center" spacing={2}>
            {items.map((items, index) => {
                if (props.showMpg !== true && index === 0) {
                    return null;
                }

                return (
                    <Grid key={index} item xs={props.showMpg ? 4 : 6}>
                        {items.map((data, index) => (
                            <VehicleDataItem key={index} {...data} />
                        ))}
                    </Grid>
                );
            })}
        </Container>
    );
};

export { VehicleStatusData };
