// this is deprecated. Use graphql representation of enum instead (capital, underscores)
// together with TS enums as values
export function toEnumMember<T>(t: T, graphQLEnumString: string) {
    const key = graphQLEnumString
        .split("_")
        .map((item) => item.charAt(0).toUpperCase() + item.substring(1).toLowerCase())
        .join("") as keyof typeof t;

    return t[key];
}
