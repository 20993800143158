import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    marker: {
        height: "32px",
        display: "block",
    },
});

type PedalMarkerProps = {
    alt?: string;
};

const PedalMarker = ({ alt }: PedalMarkerProps) => {
    const styles = useStyles();
    return <img className={styles.marker} src="/pedal-balloon.svg" alt={alt} />;
};

export { PedalMarker };
export type { PedalMarkerProps };
