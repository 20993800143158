import { listenEvent } from "@pedal/infrastructure";
import { EventTypes } from "../eventTypes";

function setupSubscriptionUpgradeListeners() {
    listenEvent(EventTypes.requestMembershipUpgrade, (data: { updateLink: string }) => {
        if (!data) {
            return;
        }

        window.open(data.updateLink, "_blank");
    });
}

export { setupSubscriptionUpgradeListeners };
