import { ThemeOptions } from "@material-ui/core/styles";
import { colors } from "./colors";

declare module "@material-ui/core/styles/overrides" {
    interface ComponentNameToClassKey {
        MUIDataTable: "paper";
        MUIDataTableHeadRow: "root";
        MUIDataTablePagination: "root";
    }
}

const headerFontOptions = {
    fontFamily: "'Titillium Web'",
    color: colors.slate,
    fontWeight: 500,
};

const themeOptions: ThemeOptions = {
    typography: {
        fontFamily: ["'Montserrat', sans-serif"].join(","),
        h1: {
            fontSize: "3rem", // 48
            ...headerFontOptions,
        },
        h2: {
            fontSize: "2.125rem", // 34
            ...headerFontOptions,
        },
        h3: {
            fontSize: "1.5rem", // 24
            ...headerFontOptions,
        },
        h4: {
            fontSize: "1.25rem", // 20
            ...headerFontOptions,
        },
        h5: {
            fontSize: "1.125rem", // 18
            ...headerFontOptions,
        },
        h6: undefined,
        subtitle1: {
            fontSize: "1.25rem",
            fontWeight: 500,
        },
        subtitle2: {
            fontWeight: 500,
        },
        body1: {
            color: colors.black,
        },
        button: {
            fontSize: "1.125rem",
        },
    },
    palette: {
        primary: {
            main: colors.primary,
        },
        error: {
            main: colors.red,
        },
        background: {
            default: colors.white,
        },
    },
    props: {
        MuiOutlinedInput: {
            margin: "dense",
        },
    },
    overrides: {
        MuiInputBase: {
            adornedStart: {
                color: colors.platinumDark,
            },
            adornedEnd: {
                color: colors.platinumDark,
                "& .MuiIconButton-root": {
                    color: "inherit",
                },
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 24,
                backgroundColor: colors.ice,
                "& fieldset": {
                    borderColor: colors.platinumDark,
                },
                "&:hover:not(.Mui-focused) fieldset": {
                    borderColor: `${colors.platinumExtraDark} !important`,
                    borderWidth: "2px",
                },
                "&.Mui-focused:not(.Mui-error)": {
                    "& .MuiInputAdornment-root": {
                        color: colors.primary,
                    },
                },
                "&.Mui-error": {
                    "& .MuiInputAdornment-root": {
                        color: colors.red,
                    },
                },
            },
            input: {
                color: colors.black,
                "&::placeholder": {
                    color: colors.platinumDark,
                    opacity: 1,
                },
            },
        },
        MuiButton: {
            root: {
                "text-transform": "inherit",
                borderRadius: 24,
            },
            label: {
                justifyContent: "space-between",
                lineHeight: "2rem",
            },
            endIcon: {
                fontSize: "2rem",
            },
        },
        MuiDialog: {
            paperWidthSm: {
                maxWidth: 700,
            },
        },
        MuiDialogActions: {
            root: {
                justifyContent: "space-evenly",
            },
            spacing: {
                "&> :not(:first-child)": {
                    marginLeft: "16px",
                },
            },
        },
        MuiTypography: {
            h5: {},
        },
        MuiTableCell: {
            root: {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        MUIDataTable: {
            paper: {
                boxShadow: "none",
            },
        },
        MUIDataTableHeadRow: {
            root: {
                "& th": {
                    borderBottom: "none",
                },
            },
        },
        MUIDataTablePagination: {
            root: {
                padding: 0,
            },
        },
    },
};

export { themeOptions };
