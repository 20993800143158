/*
    this file is used to app as an interop service for when pedalapp is being used in app mode via the mobile
    app wrapper
*/
import { noLogoutUrl } from "config";
import { Store } from "store/types";
import {
    initLoggerOverrides,
    initializeEventForwarders,
    initializeStoreEventForwarders,
    initializeAppStoreInteropObject,
    initializeInteropObject,
    initAuthOverrides,
} from "./app-interop";

type Input = {
    store: Store;
};

function initializeAppInterop(overrideAuth: boolean) {
    // init pedalapp global object
    initializeInteropObject();

    // overrides
    noLogoutUrl();
    initLoggerOverrides();

    if (overrideAuth) {
        initAuthOverrides();
    }

    return initializeEventForwarders();
}

function initializeAppStoreInterop({ store }: Input) {
    // init pedalapp global object
    initializeAppStoreInteropObject(() => store.getState(), store.dispatch);

    // init pedalapp global object
    return initializeStoreEventForwarders(() => store.getState(), store.dispatch);
}

export { initializeAppInterop, initializeAppStoreInterop };
