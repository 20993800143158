import { graphQL } from "@pedal/pedal-api";
import { Weather } from "api";

type VehicleWeather = {
    location: {
        weather: Weather | null;
    } | null;
};
export async function getLocationWeather(vehicleId: string) {
    const res = await graphQL.query<"vehicle", VehicleWeather>({
        query: `
vehicle (id: "${vehicleId}") {
    location {
        weather {
            typeId
            description
            temp
            tempFeelsLike
            humidity
            pressure
            windSpeed
            precipitation
            cityAddress
            weatherLastUpdated
        }
    }
}`,
    });

    if (res.data.vehicle !== null && res.data.vehicle.location !== null) {
        return res.data.vehicle.location.weather;
    }

    return null;
}
