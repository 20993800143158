import { logger } from "./logger";

type UrlRetriever = string | (() => Promise<string | undefined>);

const blobObjectUrlRetriever = async (url: UrlRetriever) => {
    if (typeof url !== "string") {
        const retrievedUrl = await url();
        if (!retrievedUrl) {
            return undefined;
        }

        url = retrievedUrl;
    }

    const request = new Request(url, {
        method: "GET",
    });

    try {
        const res = await fetch(request);

        if (!res.ok) {
            logger.warn("Failed to fetch blob. Http result was not OK");
            return undefined;
        }

        const blob = await res.blob();

        return URL.createObjectURL(blob);
    } catch (e) {
        logger.warn("Failed to fetch blob");
        return undefined;
    }
};

const getCachedBlobObjectUrl = (func: typeof blobObjectUrlRetriever) => {
    const cache: Partial<Record<string, string | null>> = {};

    return async (key: string, url: UrlRetriever) => {
        const cacheKey = key;
        let logo = cache[cacheKey];
        if (logo !== undefined) {
            return logo ?? undefined;
        }

        logo = await func(url);
        if (logo) {
            cache[cacheKey] = logo;
        } else {
            cache[cacheKey] = null;
        }

        return logo;
    };
};

const getBlobObjectUrl = getCachedBlobObjectUrl(blobObjectUrlRetriever);

export { blobObjectUrlRetriever, getBlobObjectUrl };
