import React from "react";
import { PermissionRequired } from "./PermissionRequired";
import { PermissionRequiredPod } from "./PermissionRequiredPod";

type BaseProps = { requiresLocationPermission: boolean };

function withLocationPermissionRequired<TProps extends BaseProps>(
    Component: React.ComponentType<Omit<TProps, "requiresLocationPermission">>
) {
    return ({ requiresLocationPermission, ...rest }: TProps) => {
        if (requiresLocationPermission) {
            return <PermissionRequired />;
        }

        return <Component {...rest} />;
    };
}

function withLocationPermissionRequiredPod<TProps extends BaseProps>(
    Component: React.ComponentType<Omit<TProps, "requiresLocationPermission">>
) {
    return ({ requiresLocationPermission, ...rest }: TProps) => {
        if (requiresLocationPermission) {
            return <PermissionRequiredPod />;
        }

        return <Component {...rest} />;
    };
}

export { withLocationPermissionRequired, withLocationPermissionRequiredPod };
export type { BaseProps };
