import { listenEvent } from "@pedal/infrastructure";
import { RootState } from "store";
import { EventTypes } from "../../eventTypes";
import { sendMessageHandler } from "../messageHandlers";

function getVehicleLoadedListener(getState: () => RootState) {
    return listenEvent(EventTypes.vehicleLoaded, async () => {
        const vehicle = getState().currentVehicle.vehicle;
        if (!vehicle) {
            return;
        }

        sendMessageHandler(EventTypes.vehicleLoaded, {
            vehicleId: vehicle.id,
            userId: vehicle.userId,
            sensorId: vehicle.pedalConnect?.sensor?.id,
            imageUrl: vehicle.imageUrl,
            data: vehicle.data,
        });
    });
}

export { getVehicleLoadedListener };
