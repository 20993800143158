import { listenEvent } from "@pedal/infrastructure";
import { AppOnlyEventTypes } from "../eventTypes";
import { EventTypes } from "../../eventTypes";
import { createSendMessageHandler } from "../messageHandlers";

const forwardEvents = [
    EventTypes.onAppInitialized,
    EventTypes.onAppInitializedError,
    EventTypes.onSignOut,
    //AppOnlyEventTypes.onLog,
    EventTypes.onError,
    EventTypes.openAppSettings,
    EventTypes.openShareSettings,
    EventTypes.navigateToReview,
    AppOnlyEventTypes.accessTokenRequested,
    EventTypes.vehicleAdded,
    EventTypes.vehicleConfigured,
    EventTypes.vehicleSold,
    EventTypes.pedalConnectServiceConsent,
    EventTypes.openChat,
    EventTypes.openNewChat,
    EventTypes.showValuations,
    EventTypes.onRemoveVehicle,
    EventTypes.accountDeleted,
    AppOnlyEventTypes.appAnalytics,
    EventTypes.shareData,
    AppOnlyEventTypes.changePassword,
    AppOnlyEventTypes.userAppAuthError,
    EventTypes.requestMembershipUpgrade,
    EventTypes.goToStore,
    EventTypes.onUserMembershipUpdated,
];

function getGlobalListeners() {
    return forwardEvents.map((e) => listenEvent(e, createSendMessageHandler(e)));
}

export { getGlobalListeners };
