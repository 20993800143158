import React from "react";
import { raiseEvent } from "@pedal/infrastructure";
import { EventTypes } from "../eventTypes";

function useAddPedalConnect(vehicleId: string) {
    return React.useCallback(() => {
        raiseEvent(EventTypes.addPedalConnect, { vehicleId });
    }, [vehicleId]);
}

export { useAddPedalConnect };
