import { graphQL } from "@pedal/pedal-api";
import { VehicleDetails } from "../types";

type Response = Omit<VehicleDetails, "batteryCapacityKwh"> & {
    electricVehicleDetails: {
        batteryDetails: Array<{
            capacityKwh: number;
        }>;
    } | null;
};

export async function getVehicleDetails(vehicleId: string) {
    const res = await graphQL.query<"vehicle", Response>({
        query: `
vehicle (id: "${vehicleId}") {
    vrm
    engineCapacity
    transmissionType
    seatingCapacity
    numberOfDoors
    dateFirstRegistered
    doorPlan
    fuelType
    mileage
    isElectricVehicle
    electricVehicleDetails {
        batteryDetails {
            capacityKwh
        }
    }
}`,
    });

    return res.data.vehicle;
}
