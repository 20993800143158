import { isPossiblePhoneNumber as isPossiblePhoneNumberCore } from "react-phone-number-input/input";
import { defaultCountry } from "./constants";

function isPossiblePhoneNumber(value: string | undefined): boolean {
    if (!value) {
        return false;
    }

    const res = isPossiblePhoneNumberCore(value, defaultCountry);

    return res;
}

export { isPossiblePhoneNumber };
