import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { VehicleDataState } from "../vehicleTypes";
import { getVehicleDetails as getVehicleDetailsApi } from "./queries";
import { VehicleDetails } from "./types";

const getVehicleDetails = createAsyncThunk(
    "vehicleDetails/getVehicleDetails",
    async ({ vehicleId }: { vehicleId: string }) => {
        const res = await getVehicleDetailsApi(vehicleId);
        if (res === null) {
            return undefined;
        }

        const { electricVehicleDetails, ...rest } = res;

        return {
            ...rest,
            batteryCapacityKwh: electricVehicleDetails?.batteryDetails[0]?.capacityKwh,
        };
    }
);

const initialState: VehicleDataState<VehicleDetails> = {
    loading: false,
};
const vehicleDetailsSlice = createSlice({
    name: "vehicleDetails",
    initialState: initialState,
    reducers: {
        updateMileage: (state, action: PayloadAction<{ mileage?: number }>) => {
            if (!state.data || !action.payload.mileage) {
                return;
            }

            state.data.mileage = action.payload.mileage;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getVehicleDetails.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getVehicleDetails.rejected, (state) => {
            state.loading = false;
            state.data = undefined;
        });
        builder.addCase(getVehicleDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
    },
});

export { getVehicleDetails };

export const { updateMileage } = vehicleDetailsSlice.actions;

export default vehicleDetailsSlice.reducer;
