import { graphQL } from "@pedal/pedal-api";

type ResponseType = {
    imageUrl: string | null;
};

export async function getOfferImageUrl(offerId: string) {
    const res = await graphQL.query<"offer", ResponseType>({
        query: `
offer (id: "${offerId}") {
    imageUrl
}`,
    });

    return res.data.offer?.imageUrl;
}
