import { graphQL, GraphQLMutationResponse } from "@pedal/pedal-api";

export function configureNotifications(
    linkedUserVehicles: Array<{ vehicleId: string; receiveNotification: boolean }>
) {
    return graphQL.sendCore<GraphQLMutationResponse<"configureNotifications">>({
        query: `mutation ConfigureNotifications($input: ConfigureNotificationsInput!) {
    configureNotifications(input: $input) {
        messages
    }
}`,
        variables: {
            input: {
                linkedUserVehicles,
            },
        },
    });
}
