import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { toCurrencyDisplay } from "../../utils";

type VehicleValuationDetailsProps = {
    awaiting: boolean;
    valuation: number | undefined;
    className?: string;
};

const VehicleValuationDetails = (props: VehicleValuationDetailsProps) => {
    const styles = useStyles(props);
    const { awaiting, valuation, className } = props;

    return (
        <div className={className}>
            <Typography variant="h6" className={styles.valuation}>
                {awaiting ? "Awaiting" : valuation ? toCurrencyDisplay(valuation) : "--"}
            </Typography>
            <Typography variant="body2">Valuation</Typography>
        </div>
    );
};

const useStyles = makeStyles({
    valuation: {
        lineHeight: 1,
    },
});

export { VehicleValuationDetails };
