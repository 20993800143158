import React from "react";
import { useTheme } from "@material-ui/core";
import { SwiperProps } from "swiper/react";

function useSwiperProps(): SwiperProps {
    const theme = useTheme();
    return React.useMemo(
        () => ({
            spaceBetween: theme.spacing(2),
            breakpoints: {
                [theme.breakpoints.values.xs]: {
                    slidesPerView: 1,
                },
                [theme.breakpoints.values.sm]: {
                    slidesPerView: 2,
                },
                [theme.breakpoints.values.md]: {
                    slidesPerView: 3,
                },
            },
        }),
        [theme]
    );
}

export { useSwiperProps };
