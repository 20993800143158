import React from "react";
import { MobileStepper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AddButton, AddButtonProps } from "../../button";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "transparent",
        justifyContent: "center",
        padding: 0,
    },
    add: {
        marginLeft: theme.spacing(0.5),
    },
}));

type VehiclesStepperProps = {
    totalSteps: number;
    activeStep: number;
    addButtonProps?: Pick<AddButtonProps, "onClick" | "disabled" | "size">;
};

const VehiclesStepper = ({ activeStep, totalSteps, addButtonProps }: VehiclesStepperProps) => {
    const styles = useStyles();
    return (
        <MobileStepper
            variant="dots"
            steps={totalSteps}
            position="static"
            activeStep={activeStep}
            className={styles.root}
            nextButton={
                addButtonProps && (
                    <AddButton aria-label="Add" className={styles.add} {...addButtonProps} />
                )
            }
            backButton={null}
        />
    );
};

export { VehiclesStepper };
export type { VehiclesStepperProps };
