import { graphQL, GraphQLMutationResponse } from "@pedal/pedal-api";
import { toDateString } from "@pedal/utility";

const configureVehicle = async (vehicleId: string, insuranceExpiryDate: Date | null) => {
    const res = await graphQL.sendCore<GraphQLMutationResponse<"configureVehicle">>({
        query: `mutation Mutation($vehicleId: UUID!, $vehicleInput: ConfigureVehicleInput!) {
    configureVehicle(vehicleId: $vehicleId, input: $vehicleInput) {
        messages
    }
}`,
        variables: {
            vehicleId,
            vehicleInput: {
                insuranceExpiryDate: toDateString(insuranceExpiryDate),
            },
        },
    });

    return res;
};

export { configureVehicle };
