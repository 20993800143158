import { graphQL, GraphQLMutationResponse } from "@pedal/pedal-api";

export function resendSignupInvite(userId: string) {
    return graphQL.sendCore<GraphQLMutationResponse<"resendSignupInvite">>({
        query: `mutation ResendSignupInvite($userId: String!) {
    resendSignupInvite(userId: $userId) {
        messages
    }
}`,
        variables: {
            userId,
        },
    });
}
