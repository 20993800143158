import AuthModule from "./AuthModule";
import * as azureB2CConfig from "./config/azureB2CConfig";

const authModule = new AuthModule(
    azureB2CConfig.AZURE_B2C_CONFIG,
    azureB2CConfig.loginRequest,
    azureB2CConfig.tokenRequest
);

async function acquireToken() {
    const token = await authModule.getPedalAppToken();
    return token;
}

function load() {
    return authModule.loadAuthModule();
}

async function login() {
    const currentAccount = authModule.getCurrentAccount();
    if (currentAccount !== null) {
        return currentAccount;
    }

    const login = await authModule.login();

    return login;
}

async function logout(postLogoutRedirectUri: string | undefined) {
    await authModule.logout(postLogoutRedirectUri);
    localStorage.clear();
    sessionStorage.clear();
}

function changePassword() {
    authModule.changePassword(azureB2CConfig.b2cPolicies.authorities.forgotPassword.authority);
}

function refreshToken() {
    // noop, is managed by web
    return Promise.resolve();
}

export const auth = {
    acquireToken,
    load,
    login,
    logout,
    changePassword,
    refreshToken,
};
