import React from "react";
import { Grid, Typography, TypographyProps, SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    iconText: {
        color: grey[500],
        marginRight: theme.spacing(1),
    },
}));

function IconText({
    className,
    text,
    variant,
    Icon,
}: {
    className?: string;
    text: string;
    variant?: TypographyProps["variant"];
    Icon: typeof SvgIcon;
}) {
    const styles = useStyles();
    return (
        <Grid className={className} container direction="row" alignItems="center">
            <Icon className={styles.iconText} />
            <Typography variant={variant}>{text}</Typography>
        </Grid>
    );
}

export { IconText };
