import { locale, numericDateStringOptions, timeOptions } from "@pedal/utility";

function toDateTimeString(dateTime: string) {
    const date = new Date(dateTime);
    const dateStr = date.toLocaleString(locale, numericDateStringOptions);

    if (date.getUTCHours() === 0 && date.getUTCMinutes() === 0 && date.getUTCSeconds() === 0) {
        return dateStr;
    }

    return `${dateStr} | ${date.toLocaleString(locale, timeOptions)}`;
}

export { toDateTimeString };
