import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DataTableProps } from "./DataTable";

const withPaddingUseStyles = makeStyles((theme) => ({
    root: function ({ padding }: { padding: number }) {
        return {
            "& > :nth-child(2)": {
                paddingLeft: theme.spacing(padding),
                paddingRight: theme.spacing(padding),
            },
            "& .MuiToolbar-root": {
                paddingLeft: theme.spacing(padding),
                paddingRight: theme.spacing(padding),
            },
        };
    },
}));

const noHeaderStyles = makeStyles((theme) => {
    return {
        root: {
            "& .MuiTableHead-root": {
                display: "none",
            },
        },
    };
});

const withPadding = (DataTable: React.ComponentType<DataTableProps>, padding: number = 3) => (
    props: DataTableProps
) => {
    const styles = withPaddingUseStyles({ padding });
    return <DataTable {...props} className={styles.root} />;
};

const noHeader = (DataTable: React.ComponentType<DataTableProps>) => (props: DataTableProps) => {
    const styles = noHeaderStyles(props);
    return <DataTable {...props} className={styles.root} />;
};

export { withPadding, noHeader };
