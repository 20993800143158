import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { styled, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        flexWrap: "nowrap",
    },
    value: {
        color: "#152333",
        letterSpacing: "0.44px",
    },
    container: {
        width: "100%",
        overflow: "hidden",
    },
    titleContainer: {
        letterSpacing: "0.4px",
        lineHeight: "1.33",
    },
    title: {
        color: "#3c495c",
        letterSpacing: "0.4px",
        lineHeight: "1.33",
    },
    text: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
});

const Img = styled("img")({
    margin: "0 auto",
    display: "block",
    width: "32px",
});

type VehicleDataItemProps = {
    /**
     * The logo to use for the data item.
     */
    logo: string;
    /**
     * The title of the data item.
     */
    title: string;
    /**
     * The value of the data item.
     */
    value: string;
} & StyleProps;

type StyleSections = "value";
type StyleProps = {
    /**
     * Class name to apply to the parent of the component.
     */
    className?: string;
    /**
     * Classes which can be applied to sections of the component.
     */
    classes?: Record<StyleSections, string>;
};

const VehicleDataItem = (props: VehicleDataItemProps) => {
    const { logo, title, value, className, classes } = props;

    const styles = useStyles();
    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
            className={`${styles.root} ${className}`}
        >
            <Grid item>
                <Img src={logo} alt={title} title={title} />
            </Grid>
            <Grid
                item
                container
                direction="column"
                alignItems="flex-start"
                className={styles.container}
            >
                <Grid item className={styles.container}>
                    <Typography
                        title={value}
                        variant="body1"
                        className={`${styles.value} ${styles.text}${
                            classes?.value ? " " + classes.value : ""
                        }`}
                    >
                        {value}
                    </Typography>
                </Grid>
                <Grid item className={`${styles.titleContainer} ${styles.container}`}>
                    <Typography
                        title={title}
                        variant="caption"
                        className={`${styles.title} ${styles.text}`}
                    >
                        {title}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export { VehicleDataItem };
export type { VehicleDataItemProps, StyleProps };
