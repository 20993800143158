import { graphQL, GraphQLMutationResponse } from "@pedal/pedal-api";
import { DeviceCoordinates } from "api";

export function setDeviceLocation(vehicleId: string, input: DeviceCoordinates) {
    return graphQL.sendCore<GraphQLMutationResponse<"setDeviceLocation">>({
        query: `mutation SetDeviceLocation($vehicleId: UUID!, $input: LocationInput!) {
    setDeviceLocation(vehicleId: $vehicleId, input: $input) {
        messages
    }
}`,
        variables: {
            vehicleId,
            input,
        },
    });
}
