import React from "react";
import { NotificationItemBase } from "../NotificationItemBase";
import { NotificationCategory } from "../types";
import { NotificationContent, NotificationContentProps } from "../NotificationContent";
import { NotificationActions, NotificationAction } from "./NotificationActions";

type NotificationItemProps = NotificationContentProps & {
    category: NotificationCategory;
    actions?: Array<NotificationAction | undefined>;
};

const NotificationItem = (props: NotificationItemProps) => {
    const { category, actions, ...contentProps } = props;

    return (
        <NotificationItemBase category={category}>
            {{
                content: <NotificationContent {...contentProps} />,
                actions: actions ? <NotificationActions actions={actions} /> : undefined,
            }}
        </NotificationItemBase>
    );
};

export { NotificationItem };
export type { NotificationItemProps };
