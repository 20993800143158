import React from "react";
import { Grid, Typography, useControlled } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SwitchBase } from "../SwitchBase";

type ListItem = {
    id: string;
    label: string;
};

type SwitchListProps = {
    value?: Array<string>;
    onChange?: (value: Array<string>) => void;
    defaultValue?: Array<string>;
    items: Array<ListItem>;
};

const useStyles = makeStyles((theme) => ({
    list: {
        "& > div": {
            marginBottom: theme.spacing(0.5),
            "&:last-child": {
                marginBottom: 0,
            },
        },
    },
}));

function SwitchList(props: SwitchListProps) {
    const styles = useStyles();
    const { onChange, defaultValue, value, items } = props;

    const [selectedValues, setSelectedValues] = useControlled({
        controlled: value,
        default: defaultValue,
        name: "SwitchList",
    });
    const selectedValuesRef = React.useRef(selectedValues ?? []);
    const onChangeRef = React.useRef(onChange);

    React.useEffect(() => {
        onChangeRef.current = onChange;
    }, [onChange]);

    const onChangeSwitch = React.useCallback(
        function (e: React.ChangeEvent, checked: boolean, itemId: string) {
            const values = [...selectedValuesRef.current];
            if (checked) {
                values.push(itemId);
            } else {
                const index = values.indexOf(itemId);
                if (index >= 0) {
                    values.splice(index, 1);
                }
            }

            selectedValuesRef.current = values;
            setSelectedValues(values);
            onChangeRef.current && onChangeRef.current(values);
        },
        [setSelectedValues]
    );

    return (
        <Grid container direction="column" className={styles.list}>
            {items.map((i) => (
                <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography variant="body2">{i.label}</Typography>
                    <SwitchBase
                        checked={selectedValuesRef.current.includes(i.id)}
                        onChange={(e, checked) => onChangeSwitch(e, checked, i.id)}
                        color="default"
                    />
                </Grid>
            ))}
        </Grid>
    );
}

export { SwitchList };
export type { SwitchListProps };
