import React from "react";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ActionButton } from "../../button";

const useStyles = makeStyles((theme) => ({
    rootPanel: {
        background: theme.palette.primary.main,
        padding: 1,
        borderRadius: 8,
    },
    panel: {
        padding: theme.spacing(2),
        borderRadius: 8,
    },
    titleContainer: {
        padding: theme.spacing(2),
        color: theme.palette.common.white,
    },
    title: {
        lineHeight: 1.1,
        textTransform: "capitalize",
    },
    confirmButton: {
        marginTop: theme.spacing(2),
    },
}));

type VehicleConfirmPanelProps = {
    title: string;
    subtitle?: string;
    confirmButton: string;
    onConfirm: () => void;
    children: React.ReactNode;
};

function VehicleConfirmPanel({
    title,
    subtitle,
    confirmButton,
    onConfirm,
    children,
}: VehicleConfirmPanelProps) {
    const styles = useStyles();
    const [confirmed, setConfirmed] = React.useState(false);

    const onConfirmClick = () => {
        setConfirmed(true);
        onConfirm();
    };

    return (
        <Paper className={styles.rootPanel}>
            <div className={styles.titleContainer}>
                <Typography variant="h6" className={styles.title}>
                    {title}
                </Typography>
                {subtitle && <Typography variant="body2">{subtitle}</Typography>}
            </div>
            <Paper className={styles.panel} variant="outlined">
                {children}
                <ActionButton
                    className={styles.confirmButton}
                    onClick={onConfirmClick}
                    text={confirmButton}
                    disabled={confirmed}
                    loading={confirmed}
                    fullWidth
                ></ActionButton>
            </Paper>
        </Paper>
    );
}

export { VehicleConfirmPanel };
