import React, { ReactChild } from "react";
import { ZeroStatePod, DefaultPod } from "../";
import { hasNamedChildrenSlots } from "../../utils";
import { AnalyticsTrackProps } from "../../analytics";

type DataPodProps = {
    logo?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    title: string;
    subtitle?: ReactChild;
    description: ReactChild;
    onDataPodTitleClick?: () => void;
    onZeroStatePodClick?: () => void;
    titleExtension?: ReactChild;
    isLoading?: boolean;
    className?: string;
    children?:
        | {
              content: ReactChild | null;
              footer?: ReactChild;
          }
        | ReactChild
        | null;
} & AnalyticsTrackProps;

function DataPod({
    onDataPodTitleClick,
    onZeroStatePodClick,
    titleExtension,
    isLoading,
    logo,
    title,
    subtitle,
    description,
    className,
    children,
    ...analytics
}: DataPodProps) {
    const { content, footer } = !children
        ? { content: null, footer: undefined }
        : hasNamedChildrenSlots(children)
        ? children
        : { content: children, footer: undefined };

    if (isLoading || !content) {
        return (
            <ZeroStatePod
                logo={logo}
                title={title}
                description={description}
                loading={isLoading}
                onClick={onZeroStatePodClick}
                className={className}
                {...analytics}
            />
        );
    }

    return (
        <DefaultPod
            logo={logo}
            title={title}
            subtitle={subtitle}
            onTitleClick={onDataPodTitleClick}
            titleExtension={titleExtension}
            className={className}
            {...analytics}
        >
            {{
                content: content,
                footer: footer,
            }}
        </DefaultPod>
    );
}

export { DataPod };
export type { DataPodProps };
