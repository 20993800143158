import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0, 0, 0, 1),
        margin: 0,
        listStylePosition: "inside",
    },
}));

type UnorderedListProps = {
    items: React.ReactNode[];
};

const UnorderedList = ({ items }: UnorderedListProps) => {
    const styles = useStyles();
    return (
        <ul className={styles.root}>
            {items.map((item, index) => (
                <li key={index}>{item}</li>
            ))}
        </ul>
    );
};

export { UnorderedList };
export type { UnorderedListProps };
