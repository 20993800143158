const baseColors = {
    black: "#152333",
    gunMetal: "#273444",
    white: "#fff",

    slate: "#3C495C",
    slateDark: "#2E3A4D",
    slateExtraDark: "#1E2B3B",

    platinum: "#8592a6",
    platinumDark: "#738399",
    platinumExtraDark: "#60728a",

    silver: "#E0E6ED",
    silverDark: "#D3DCE6",
    silverExtraDark: "#C0CCDA",

    ice: "#F9FAFC",
    iceDark: "#EFF2F7",
    iceExtraDark: "#e5e9f2",

    red: "#d0021b",
    darkred: "#a61123",

    amber: "#FFB233",
    amberDark: "#F59D0A",

    primary: "#1B7785",
    pedalBlue: "#1c356b",
    gold: "#ffb258",

    backgroundGrey: "#fafafa",
    footer: "#2e2e2e",
    govUk: {
        black: "#0b0c0c",
    },
};

const colors = {
    ...baseColors,
    warning: baseColors.amberDark,
};

export { colors };
