import { MouseEventHandler } from "react";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TransparentButton } from "../../button";
import { podIconSize } from "../size";
import clsx from "clsx";

type PodActionButtonProps = {
    logo: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    className?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    loading?: boolean;
    disabled?: boolean;
} & Pick<
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    "aria-label" | "aria-labelledby"
>;

/**
 * Pod action button component is commonly used with a Pod in the footer section, to
 * show any actions which can be taken with the pod.
 *
 * @param {PodActionButtonProps} props The component props.
 * @returns {JSX.Element} The component element.
 */
const PodActionButton = (props: PodActionButtonProps) => {
    const { logo: Logo, className, loading, disabled, onClick, ...ariaLabel } = props;
    const styles = useStyles();

    return (
        <TransparentButton
            disabled={disabled}
            onClick={onClick}
            className={clsx(styles.button, className)}
            {...ariaLabel}
        >
            <Logo className={styles.logo} />
            {loading && <CircularProgress color="primary" size={24} className="loader" />}
        </TransparentButton>
    );
};

const useStyles = makeStyles({
    button: {
        display: "block",
        position: "relative",
        "& .loader": {
            position: "absolute",
            margin: "auto",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
    },
    logo: {
        display: "block",
        width: podIconSize.height,
        height: podIconSize.width,
    },
});

export { PodActionButton };
export type { PodActionButtonProps };
