export type GlobalState = {
    appLogo: AppLogo | undefined;
    appVersion: string;
    theme: AppTheme;
    capabilities: AppCapabilities;
};

export type AppCapabilities = {
    location: boolean;
    notifications: boolean;
};

export type AppLogo = {
    logo: string;
    name: string;
};

export type AppTheme = {
    color: string | undefined;
    isLight: boolean;
};

export function createInitialGlobalState(isAppMode: boolean, appVersion: string): GlobalState {
    return {
        appLogo: undefined,
        appVersion: appVersion,
        theme: {
            color: undefined,
            isLight: true,
        },
        capabilities: {
            location: true,
            notifications: isAppMode,
        },
    };
}
