import { getAppOptions, listenEvent } from "@pedal/infrastructure";
import { auth } from "@pedal/pedal-api";
import { RootState } from "store";
import { EventTypes } from "../../eventTypes";
import { AppOnlyEventTypes } from "../eventTypes";
import { sendMessageHandler } from "../messageHandlers";
import { noop } from "lodash";

function getOnStoreCreatedListener(getState: () => RootState) {
    if (getAppOptions()?.versionIsAtleast(1, 2, 0)) {
        return noop;
    }

    return listenEvent(EventTypes.onStoreCreated, async () => {
        const token = await auth.acquireToken();
        const user = getState().user;

        sendMessageHandler(AppOnlyEventTypes.userLoaded, {
            token,
            userId: user.id,
            userDevices: user.userDevices,
        });
    });
}

export { getOnStoreCreatedListener };
