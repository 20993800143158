import { ReactChild } from "react";

export function hasNamedChildrenSlots<T extends { content: ReactChild | ReactChild[] | null }>(
    props: unknown
): props is T {
    return (props as T).content !== undefined;
}

export function wrapAsFunction<T>(e: ((t: T) => ReactChild | null) | ReactChild | null) {
    if (typeof e === "function") {
        return e;
    } else {
        return () => e;
    }
}
