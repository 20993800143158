import { listenEvent } from "@pedal/infrastructure";
import { EventTypes } from "../../eventTypes";
import { AppOnlyEventTypes } from "../eventTypes";
import { sendMessageHandler } from "../messageHandlers";

function getNotificationPermissionRequestedListener() {
    return listenEvent(EventTypes.notificationPermissionRequested, () => {
        sendMessageHandler(AppOnlyEventTypes.pushNotificationPermissionRequested, null);
    });
}

export { getNotificationPermissionRequestedListener };
