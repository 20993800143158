type Disposable = () => void;

type Disposer = {
    add: (disposable: Disposable) => void;
    (): void;
};

function createDisposer(): Disposer {
    const items: Array<Disposable> = [];
    const func: Disposer = () => {
        items.forEach((f) => f());
    };

    func.add = (disposable) => items.push(disposable);

    return func;
}

function disposer(...disposables: Array<Disposable>): Disposable {
    return () => {
        disposables.forEach((f) => f());
    };
}

export { createDisposer, disposer };
