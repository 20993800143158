import { eventHandlers as vehicleEventHandlers } from "./vehicle/vehicleSlice";
import { eventHandlers as locationEventHandlers } from "./location/locationSlice";
import { RootState } from "./types";

type Handler = (payload: any, state: RootState) => void;

const eventHandlersRecord: Partial<Record<string, Handler | Array<Handler>>> = {
    ...vehicleEventHandlers,
};

const eventHandlers: Array<{
    filter: (type: string) => boolean;
    handler: Handler;
}> = [...locationEventHandlers];

export function handle(type: string, payload: any, getState: () => RootState) {
    const handler = eventHandlersRecord[type];
    if (handler) {
        const state = getState();
        if (Array.isArray(handler)) {
            handler.forEach((f) => f(payload, state));
        } else {
            handler(payload, state);
        }

        return;
    }

    const handlerRecord = eventHandlers.find((h) => h.filter(type));
    if (handlerRecord) {
        // we should cache this handler
        const existing = eventHandlersRecord[type];
        if (existing) {
            if (Array.isArray(existing)) {
                existing.push(handlerRecord.handler);
            } else {
                delete eventHandlersRecord[type];
                eventHandlersRecord[type] = [existing, handlerRecord.handler];
            }
        } else {
            eventHandlersRecord[type] = handlerRecord.handler;
        }

        const state = getState();
        handlerRecord.handler(payload, state);
    }
}
