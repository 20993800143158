import { logger } from "@pedal/infrastructure";
import { AppOnlyEventTypes } from "./eventTypes";
import { createSendMessageHandler } from "./messageHandlers";

function initLoggerOverrides() {
    // here we override logger to also send a message to the app.
    // This is not the best way to do it, a usage of a logging framework would be better
    const eventForward = createSendMessageHandler(AppOnlyEventTypes.onLog);
    Object.entries(logger).forEach(([key, func]) => {
        (logger as any)[key] = function (...args: unknown[]) {
            // only forward errors or warnings
            if (key === "warn" || key === "error") {
                eventForward({ type: key, args });
            }

            return func(...args);
        };
    });
}

export { initLoggerOverrides };
