import React from "react";
import { raiseEvent } from "@pedal/infrastructure";
import { useStore } from "store";
import { EventTypes } from "../../eventTypes";
import { ReturnType, PropTypes } from "./types";

function useDeviceLocationInternal<T extends PropTypes>(key: T): ReturnType<T> {
    const { locationState, supportsLocation, initialConfigurationRequired } = useStore((r) => ({
        locationState: r.currentLocation,
        supportsLocation: r.currentVehicle.vehicle?.capabilities.supportsLocation === true,
        initialConfigurationRequired: r.allVehicles.initialConfigurationRequired,
    }));

    // request location effect
    React.useEffect(() => {
        if (
            supportsLocation &&
            !initialConfigurationRequired &&
            !locationState.isConnected &&
            locationState.coordinates === undefined &&
            !locationState.loading &&
            !locationState.requiresPermission
        ) {
            raiseEvent(EventTypes.deviceLocationRequested);
        }
    }, [locationState, supportsLocation, initialConfigurationRequired]);

    if (locationState[key]) {
        return {
            [key]: locationState[key],
            loading: false,
            requiresPermission: undefined,
        } as ReturnType<T>;
    }

    if (locationState.requiresPermission || initialConfigurationRequired || !supportsLocation) {
        return { [key]: undefined, loading: false, requiresPermission: true } as ReturnType<T>;
    }

    if (locationState.loading) {
        return { [key]: undefined, loading: true, requiresPermission: undefined } as ReturnType<T>;
    }

    if (locationState.isConnected || locationState.coordinates !== undefined) {
        // we simply have no location data from the device yet
        return { [key]: undefined, loading: false, requiresPermission: undefined } as ReturnType<T>;
    }

    // we are in browser or mobile land, which means we are requesting location
    return { [key]: undefined, loading: true, requiresPermission: undefined } as ReturnType<T>;
}

function useDeviceLocation(): ReturnType<"location"> {
    return useDeviceLocationInternal("location");
}

function useDeviceCoordinates(): ReturnType<"coordinates"> {
    return useDeviceLocationInternal("coordinates");
}

export { useDeviceLocation, useDeviceCoordinates };
