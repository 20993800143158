import React, { MouseEventHandler, ReactChild } from "react";
import { Typography, CircularProgress } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

type TextButtonProps = {
    variant: "subtitle2" | "body1" | "body2";
    color?: "primary" | "inherit";
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    children: ReactChild;
    className?: string;
    loading?: boolean;
};

const TextButton = (props: TextButtonProps) => {
    const styles = useStyles(props);
    const { className, variant, disabled, onClick, loading, children } = props;
    return (
        <Typography
            className={clsx(styles.root, className)}
            component="button"
            type="button"
            variant={variant}
            disabled={disabled || loading}
            onClick={onClick}
        >
            {children}
            {props.loading && <CircularProgress color="primary" size={18} className="loader" />}
        </Typography>
    );
};

function getColor(color: TextButtonProps["color"], theme: Theme) {
    switch (color) {
        case "primary":
            return theme.palette.primary.main;
        case "inherit":
            return "inherit";
        default:
            return theme.palette.text.secondary;
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "transparent",
        border: "none",
        margin: "0",
        padding: "0",
        cursor: "pointer",
        outline: "none",
        position: "relative",
        color: (props: TextButtonProps) =>
            props.disabled || props.loading
                ? theme.palette.grey[500]
                : getColor(props.color, theme),
        "& .loader": {
            position: "absolute",
            margin: "auto",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
    },
}));

export { TextButton };
export type { TextButtonProps };
