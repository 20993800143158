import { Dispatch, AnyAction, MiddlewareAPI } from "redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { isActionResponse } from "@pedal/pedal-api";
import { raiseEvent } from "@pedal/infrastructure";
import createSignalRMiddleware from "./signalR";
import { createInitialGlobalState } from "./global/globalTypes";
import { rootReducer } from "./rootReducer";
import { handle } from "./storeEventHandlers";
import { InitialState, RootState } from "./types";

type CreateStoreOptions = { isAppMode: boolean; version: { string: string } };

async function createStore(
    { isAppMode, version }: CreateStoreOptions,
    initialState?: InitialState
) {
    const globalState = {
        global: createInitialGlobalState(isAppMode, version.string),
    };

    const state = initialState ? { ...globalState, ...initialState } : globalState;

    const signalRMiddleware = await createSignalRMiddleware();

    // signalr needs to come first, before standard thunk
    const beforeDefaultMiddleware = [signalRMiddleware];
    const afterDefaultMiddleware = [apiActionErrorHandler, eventHandlerMiddleware];
    const middleware = [
        ...beforeDefaultMiddleware,
        ...getDefaultMiddleware(),
        ...afterDefaultMiddleware,
    ];

    const store = configureStore({
        reducer: rootReducer,
        middleware: middleware,
        preloadedState: state,
    });

    return store;
}

function apiActionErrorHandler(api: MiddlewareAPI<Dispatch, RootState>) {
    return (next: Dispatch) =>
        (action: AnyAction): any => {
            const res = next(action);
            const type = res.type as string;
            const payload = res["payload"];

            if (type.endsWith("/fulfilled") && payload && isActionResponse(payload)) {
                if (!payload.success) {
                    raiseEvent("onErrorMessage", payload.messages);
                }
            }

            return res;
        };
}

function eventHandlerMiddleware(api: MiddlewareAPI<Dispatch, RootState>) {
    return (next: Dispatch) =>
        (action: AnyAction): any => {
            const res = next(action);

            const type = res.type as string;
            const payload = res["payload"];

            handle(type, payload, api.getState);

            return res;
        };
}

export { createStore };
export type { CreateStoreOptions };
