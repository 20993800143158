import { graphQL } from "@pedal/pedal-api";
import { VehicleTrip, VehicleCarbonEmissions, VehicleStatus } from "../types";

type Response = {
    carbonEmissions: VehicleCarbonEmissions | null;
    trip: VehicleTrip | null;
    status: VehicleStatus | null;
};

export async function getVehicleCarbonEmissions(vehicleId: string) {
    const res = await graphQL.query<"vehicle", Response>({
        query: `
vehicle (id: "${vehicleId}") {
    carbonEmissions {
        currentTripEmissions
        project {
            id
            name
            rootTechnology
            averagePricePerTonneCentsUsd
            photos
        }
    }
    trip {
        tripStart
        tripEnd
        tripStartLocation {
            display
        }
        tripEndLocation {
            display
        }
    }
    status {
        isMoving
    }
}`,
    });

    return res.data.vehicle;
}
