// internal events which are only required for the app
enum AppOnlyEventTypes {
    userLoaded = "userLoaded",
    onLog = "onLog",

    locationRequested = "locationRequested",
    locationPermissionRequested = "locationPermissionRequested",

    pushNotificationPermissionRequested = "pushNotificationPermissionRequested",

    // access-token
    accessTokenRequested = "accessTokenRequested",

    // analytics
    appAnalytics = "appAnalytics",

    // user
    changePassword = "changePassword",

    userAppAuthError = "userAppAuthError",
}

export { AppOnlyEventTypes };
