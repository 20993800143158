import React from "react";
import { Header, HeaderProps } from "@pedal/layout";
import { useStore } from "store";

type Props = {
    children: React.ReactNode;
    link?: string;
} & Pick<HeaderProps, "maxWidth" | "className" | "style">;

const HeaderContainer = ({ maxWidth, className, style, link, children }: Props) => {
    const global = useStore((s) => s.global);

    return (
        <Header maxWidth={maxWidth} link={link} className={className} style={style}>
            {{
                appLogo: global.appLogo ? (
                    <img src={global.appLogo.logo} alt={global.appLogo.name} />
                ) : null,
                end: children,
            }}
        </Header>
    );
};

export { HeaderContainer };
