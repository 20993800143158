import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { fuelIcon } from "../icons";
import { iconSizes } from "../sizes";
import { AnalyticsTrackProps, useAnalyticsProps } from "../../analytics";
import clsx from "clsx";

const useStyles = makeStyles({
    root: {
        justifyContent: "inherit",
    },
    img: {
        display: "block",
        height: iconSizes.height,
    },
    fuelType: {
        textTransform: "capitalize",
    },
    fuelPrice: {
        lineHeight: 1.2,
    },
});

export type FuelPriceDetailsProps = {
    costInPence: number;
    costUnit: string;
    onClick?: () => void;
    className?: string;
} & AnalyticsTrackProps;

const FuelPriceDetails = ({
    costInPence,
    costUnit,
    onClick,
    className,
    ...analytics
}: FuelPriceDetailsProps) => {
    const styles = useStyles();
    const analyticProps = useAnalyticsProps(analytics);
    return (
        <Grid
            container
            direction="row"
            className={clsx(styles.root, className)}
            alignItems="center"
            spacing={1}
            wrap="nowrap"
            onClick={onClick}
            {...analyticProps}
        >
            <Grid item>
                <img src={fuelIcon} alt="fuel" className={styles.img} />
            </Grid>
            <Grid item>
                <Grid container direction="column">
                    <Grid item>
                        <Typography variant="h6" className={styles.fuelPrice}>
                            {costInPence}p
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" component="span">
                            {costUnit}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export { FuelPriceDetails };
