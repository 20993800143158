import { listenEvent, raiseEvent } from "@pedal/infrastructure";
import { EventTypes } from "../eventTypes";
import { getMeSubscriptions } from "../queries";

function setupOnUserMembershipUpDowngraded() {
    listenEvent(EventTypes.onUserMembershipDowngraded, async () => {
        const res = await getMeSubscriptions();
        if (res.data.me) {
            raiseEvent(EventTypes.onUserMembershipUpdated, res.data.me);
        }
    });
    listenEvent(EventTypes.onUserMembershipUpgraded, async () => {
        const res = await getMeSubscriptions();
        if (res.data.me) {
            raiseEvent(EventTypes.onUserMembershipUpdated, res.data.me);
        }
    });
}

export { setupOnUserMembershipUpDowngraded };
