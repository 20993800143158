import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Table, TableBody } from "@material-ui/core";
import { MotHistoryRowShimmer } from "./MotHistoryRowShimmer";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .row:first-child > *": {
            paddingTop: theme.spacing(1.5),
        },
        "& .row:last-child > *": {
            borderBottom: "unset",
            paddingBottom: theme.spacing(1),
        },
    },
}));

type VehicleMotHistoryShimmerProps = {
    numberOfItems: number;
};

function VehicleMotHistoryShimmer({ numberOfItems }: VehicleMotHistoryShimmerProps) {
    const classes = useStyles();

    const theme = useTheme();
    const denseMode = useMediaQuery(theme.breakpoints.only("xs"));

    return (
        <Table className={classes.root} aria-label="loading mot history table">
            <TableBody>
                {Array.from({ length: numberOfItems }, (_, i) => (
                    <MotHistoryRowShimmer key={i} denseMode={denseMode} />
                ))}
            </TableBody>
        </Table>
    );
}

export { VehicleMotHistoryShimmer };
export type { VehicleMotHistoryShimmerProps };
