import { FormikHelpers } from "formik";
import { GraphQLError, GraphQLBaseResponse } from "@pedal/pedal-api";

type Response = GraphQLError[] | GraphQLBaseResponse;

function isResponse(response: Response): response is GraphQLBaseResponse {
    return (response as GraphQLBaseResponse).errors !== undefined;
}

export function bindErrorsToForm<TValues>(
    response: Response | undefined,
    helpers: FormikHelpers<TValues>
) {
    let errors: GraphQLError[] | undefined = undefined;
    if (response && isResponse(response)) {
        errors = response.errors;
    } else {
        errors = response;
    }

    if (errors) {
        errors.forEach((d) => {
            if (d.extensions.property) {
                helpers.setFieldError(d.extensions.property, d.message);
            }
        });
    }
}
