import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "@pedal/styleguide";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    container: function ({ state }: { state: VehicleDataBoxProps["state"] }) {
        return {
            borderWidth: state === "active" ? 2 : 1,
            borderStyle: "solid",
            borderColor: state === "active" ? theme.palette.primary.main : colors.platinumExtraDark,
            borderRadius: theme.shape.borderRadius * 4,
            padding: theme.spacing(2),
        };
    },
    title: {
        lineHeight: 1.1,
        textTransform: "capitalize",
    },
    children: {
        marginTop: theme.spacing(1),
    },
}));

type VehicleDataBoxProps = {
    vrm: string;
    display: string;
    state?: "active";
    action?: React.ReactNode;
    children?: React.ReactNode;
    className?: string;
    role?: string;
    onClick?: () => void;
};

function VehicleDataBox({
    display,
    vrm,
    action,
    children,
    state,
    className,
    role,
    onClick,
}: VehicleDataBoxProps) {
    const styles = useStyles({ state });

    return (
        <div className={clsx(styles.container, className)} role={role} onClick={onClick}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h6" component="h5" className={styles.title}>
                        {display}
                    </Typography>
                    <Typography variant="body2">{vrm}</Typography>
                </Grid>
                {action && <Grid item>{action}</Grid>}
            </Grid>
            {children && <div className={styles.children}>{children}</div>}
        </div>
    );
}

export { VehicleDataBox };
