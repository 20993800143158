import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import { Typography, TableRow, TableCell } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles({
    title: {
        width: "50%",
    },
    value: {
        width: "85%",
    },
    noBorder: {
        "& > *": {
            borderBottom: 0,
        },
    },
});

type MotHistoryRowShimmerProps = {
    denseMode: boolean;
};

function ShimmerCell() {
    const styles = useStyles();
    return (
        <TableCell>
            <Typography variant="caption">
                <Skeleton className={styles.title} />
            </Typography>
            <Typography variant="h6">
                <Skeleton className={styles.value} />
            </Typography>
        </TableCell>
    );
}

function MotHistoryRowShimmer({ denseMode }: MotHistoryRowShimmerProps) {
    const styles = useStyles();
    return !denseMode ? (
        <TableRow className="row">
            <ShimmerCell />
            <ShimmerCell />
            <ShimmerCell />
            <ShimmerCell />
        </TableRow>
    ) : (
        <>
            <TableRow className={clsx("row", styles.noBorder)}>
                <ShimmerCell />
                <ShimmerCell />
            </TableRow>
            <TableRow className="row">
                <ShimmerCell />
                <ShimmerCell />
            </TableRow>
        </>
    );
}

export { MotHistoryRowShimmer };
