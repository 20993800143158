import { listenEvent, logger } from "@pedal/infrastructure";
import {
    setLocation,
    asyncUpdateLocation,
    setLocationRequiresPermission,
    setLoading,
} from "store/location/locationSlice";
import { Store } from "store/types";
import { EventTypes } from "../eventTypes";
import { DeviceLocationData } from "../location";
import { noop } from "lodash";

// this should always be called
function setupDeviceLocationRequestedReduxInterop(store: Store) {
    listenEvent(EventTypes.deviceLocationRequested, () => {
        const locationState = store.getState().currentLocation;
        if (locationState.isConnected) {
            return;
        }

        store.dispatch(setLoading(true));
    });

    listenEvent<DeviceLocationData>(EventTypes.deviceLocationRequestComplete, (data) => {
        const locationState = store.getState().currentLocation;
        if (locationState.isConnected) {
            if (data.coordinates) {
                store.dispatch(
                    asyncUpdateLocation({
                        deviceType: "MOBILE",
                        latitude: data.coordinates.latitude,
                        longitude: data.coordinates.longitude,
                        saveOnly: true,
                    })
                );
            }

            return;
        }

        if (data.error) {
            store.dispatch(setLocationRequiresPermission());
            return;
        }

        const setLoadingDispatcher = data.isBackgroundUpdate ? noop : store.dispatch;

        if (data.coordinates) {
            if (
                !data.coordinates.latitude ||
                data.coordinates.latitude === 0 ||
                !data.coordinates.longitude ||
                data.coordinates.longitude === 0
            ) {
                logger.warn(
                    `Invalid location retrieved. lat: ${data.coordinates.latitude}, long: ${data.coordinates.longitude}`
                );

                setLoadingDispatcher(setLoading(false));

                return;
            }

            data.isBackgroundUpdate
                ? store.dispatch(
                      asyncUpdateLocation({
                          deviceType: "MOBILE",
                          latitude: data.coordinates.latitude,
                          longitude: data.coordinates.longitude,
                          saveOnly: false,
                      })
                  )
                : store.dispatch(
                      setLocation({
                          deviceType: "MOBILE",
                          latitude: data.coordinates.latitude,
                          longitude: data.coordinates.longitude,
                      })
                  );

            return;
        }

        setLoadingDispatcher(setLoading(false));
    });
}

export { setupDeviceLocationRequestedReduxInterop };
