import React from "react";
import { Grid, Typography } from "@material-ui/core";

type Props = {
    name: string;
    type: string;
    address?: string;
    currentDistanceFromGarage?: number;
};

const GarageDetails = ({ name, type, address, currentDistanceFromGarage }: Props) => {
    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <Typography variant="h6">{name}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="body2">
                    {type}
                    {currentDistanceFromGarage
                        ? ` | ${currentDistanceFromGarage.toFixed(2)} mi`
                        : ""}
                </Typography>
            </Grid>
            {address && (
                <Grid item>
                    {address.split(",").map((a, i) => (
                        <Typography key={i} variant="body1">
                            {a}
                        </Typography>
                    ))}
                </Grid>
            )}
        </Grid>
    );
};

export { GarageDetails };
