import { marked } from "marked";
import { NotificationCategory } from "@pedal/component-library";
import { toEnumMember } from "@pedal/utility";
import uk from "@pedal/icons/dist/logos/uk.png";
import { DefaultNotificationItemView, VehicleRecallNotification } from "../types";
import { toDateTimeString, getNotificationSource, NotificationSourceGetter } from "../utils";

async function toVehicleRecallNotificationItemView(
    notification: VehicleRecallNotification,
    currentUserId: string,
    source?: NotificationSourceGetter
): Promise<DefaultNotificationItemView> {
    return {
        id: notification.id,
        type: notification.__typename,
        vehicleId: notification.vehicleId,
        isLinkedNotification: currentUserId !== notification.user.id,
        user: notification.user,
        category: toEnumMember(NotificationCategory, notification.category),
        icon: uk,
        date: toDateTimeString(notification.recallDate),
        title: notification.title,
        message: marked.parseInline(notification.markdownMessage),
        source: source && (await getNotificationSource(source)),
    };
}

export { toVehicleRecallNotificationItemView };
