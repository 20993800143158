import { listenEvent } from "@pedal/infrastructure";
import { EventTypes } from "../../eventTypes";
import { sendMessageHandler } from "../messageHandlers";

function getNavigateToListener() {
    return listenEvent(EventTypes.navigateTo, (data: unknown) => {
        if (!data) {
            return;
        }

        sendMessageHandler(EventTypes.navigateTo, data);
    });
}

export { getNavigateToListener };
