import React, { ReactChild } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { podIconSize } from "../size";
import { OptionsFilledIcon } from "../../icons";
import { AnalyticsTrackProps, useAnalyticsProps } from "../../analytics";
import clsx from "clsx";

type PodTitleProps = {
    /**
     * Logo for the pod title.
     */
    logo?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    /**
     * Title for the pod title.
     */
    title: ReactChild;
    /**
     * Optional subtitle for the pod title. Will be displayed under the title.
     */
    subtitle?: ReactChild;
    /**
     * On pod title click.
     */
    onClick?: () => void;
    /**
     * Optional extension component for the pod title, will be displayed on the far-right side of the title
     */
    extension?: ReactChild;
    /**
     * Classes which can be applied to pod title components
     */
    classes?: Partial<{ root: string; extension: string }>;
} & AnalyticsTrackProps;

/**
 * Component used to render a title with a logo and a subtitle.
 *
 * Primarily used in conjuction with a pod, serving as the title component of the pod.
 *
 * @param {PodTitleProps} props The component props.
 * @returns {JSX.Element} The component element.
 */
const PodTitle = (props: PodTitleProps) => {
    const { logo: Logo, title, subtitle, onClick, extension, classes, ...analytics } = props;
    const styles = useStyles({ onClick });
    const analyticsProps = useAnalyticsProps(analytics);

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            className={clsx(styles.root, classes?.root)}
            onClick={onClick}
            data-testid="title"
            {...analyticsProps}
        >
            {Logo && (
                <Grid item className={styles.logo}>
                    <Logo display="block" width={podIconSize.width} height={podIconSize.width} />
                </Grid>
            )}
            <Grid item className={styles.titleContainer}>
                <Typography variant="h5" className={styles.title}>
                    {title}
                </Typography>
                {props.subtitle && (
                    <Typography variant="body1" className={styles.subtitle}>
                        {subtitle}
                    </Typography>
                )}
            </Grid>
            {extension && (
                <Grid item className={classes?.extension}>
                    {extension}
                </Grid>
            )}
            {onClick && (
                <Grid item className={styles.more}>
                    <OptionsFilledIcon aria-label="Click for more" />
                </Grid>
            )}
        </Grid>
    );
};

const useStyles = makeStyles((theme) => ({
    root: function (props: { onClick: PodTitleProps["onClick"] }) {
        return {
            flexWrap: "nowrap",
            cursor: props.onClick ? "pointer" : undefined,
        };
    },
    titleContainer: {
        overflow: "hidden",
        flex: 1,
    },
    title: {
        paddingBottom: theme.spacing(0.5),
    },
    subtitle: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    more: {
        position: "relative",
        width: "32px",
        height: "32px",
        "& > svg": {
            height: "100%",
            width: "100%",
            display: "block",
        },
    },
    logo: {
        marginRight: theme.spacing(2),
    },
}));

export { PodTitle };
export type { PodTitleProps };
