import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField, TextFieldProps } from "@material-ui/core";
//import { ImeiQRImage } from "./qr/ImeiQRImage";

const useStyles = makeStyles({
    inputContainer: {
        flex: 1,
    },
    qrContainer: {
        textAlign: "center",
    },
});

type ImeiFieldProps = Omit<TextFieldProps, "text">;

const ImeiField = (props: ImeiFieldProps) => {
    const styles = useStyles();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm="auto" className={styles.inputContainer}>
                <TextField type="text" {...props} />
            </Grid>
            <Grid item xs={12} sm="auto" className={styles.qrContainer}>
                {/* <ImeiQRImage /> */}
            </Grid>
        </Grid>
    );
};

export { ImeiField };
