import { NotificationCategory } from "@pedal/component-library";
import { toEnumMember } from "@pedal/utility";
import spannerIcon from "@pedal/icons/dist/general/spanner.svg";
import { DTCNotification, DefaultNotificationItemView } from "../types";
import { toDateTimeString, getNotificationSource, NotificationSourceGetter } from "../utils";

async function toDTCNotificationItemView(
    notification: DTCNotification,
    currentUserId: string,
    source?: NotificationSourceGetter
): Promise<DefaultNotificationItemView> {
    return {
        id: notification.id,
        type: notification.__typename,
        vehicleId: notification.vehicleId,
        isLinkedNotification: currentUserId !== notification.user.id,
        user: notification.user,
        category: toEnumMember(NotificationCategory, notification.category),
        icon: spannerIcon,
        date: toDateTimeString(notification.notificationDate),
        title: notification.dtcCode,
        message: notification.dtc?.text ?? "Unknown issue",
        source: source && (await getNotificationSource(source)),
    };
}

export { toDTCNotificationItemView };
