import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import turnIcon from "./turn.svg";
import clsx from "clsx";
import { constants } from "../constants";

const useStyles = makeStyles((theme) => ({
    container: {
        width: constants.defaultSize,
        height: constants.defaultSize,
        display: "block",
        border: `2px solid ${theme.palette.primary.main}`,
        padding: "3px",
        borderRadius: constants.defaultSize / 2,
    },
    icon: {
        display: "block",
        width: "100%",
    },
}));

type TurnProps = { className?: string; alt?: string };

function Turn({ className, alt }: TurnProps) {
    const styles = useStyles();
    return (
        <span className={clsx(styles.container, className)}>
            <img src={turnIcon} alt={alt} className={styles.icon} />
        </span>
    );
}

export { Turn };
