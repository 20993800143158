import { useEffect, useRef } from "react";
import { AsyncThunkAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import { AppDispatch, RootState } from "../types";

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useStore: TypedUseSelectorHook<RootState> = useSelector;

export function useLazyStore<TData, ThunkInput>(
    selector: (rootState: RootState) => TData | undefined,
    retriever: () => AsyncThunkAction<TData | undefined, ThunkInput, {}>
) {
    const dispatch = useAppDispatch();
    const retrieverRef = useRef(retriever);

    // if retriever changes, update the reference
    useEffect(() => {
        retrieverRef.current = retriever;
    }, [retriever]);

    const state = useStore(selector);

    useEffect(() => {
        if (state === undefined) {
            dispatch(retrieverRef.current());
        }
    }, [state, dispatch]);

    return state;
}
