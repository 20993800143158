import React from "react";
import { Typography } from "@material-ui/core";

const NoGarages = () => {
    return (
        <Typography variant="body1">There are no garages near your current location.</Typography>
    );
};

export { NoGarages };
