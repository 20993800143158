import React from "react";
import { List, ListItem, useControlled, ListProps } from "@material-ui/core";

type SelectListProps<Item> = {
    items: Array<Item>;
    renderer: (item: Item & { checked: boolean }) => React.ReactNode;
    value?: Array<string>;
    onChange?: (value: Array<string>) => void;
    defaultValue?: Array<string>;
    disabled: boolean;
} & Pick<ListProps, "className" | "disablePadding" | "dense">;

type ItemType = { id: string };

function SelectList<Item extends ItemType>({
    className,
    items,
    renderer,
    onChange,
    value,
    defaultValue,
    disabled,
    disablePadding,
    dense,
}: SelectListProps<Item>) {
    const [selectedValues, setSelectedValues] = useControlled({
        controlled: value,
        default: defaultValue,
        name: "SelectList",
    });
    const selectedValuesRef = React.useRef(selectedValues ?? []);
    const onChangeRef = React.useRef(onChange);

    React.useEffect(() => {
        onChangeRef.current = onChange;
    }, [onChange]);

    React.useEffect(() => {
        selectedValuesRef.current = selectedValues ?? [];
    }, [selectedValues]);

    const handleToggle = React.useCallback(
        function (item: Item) {
            return () => {
                const values = [...selectedValuesRef.current];
                const index = values.indexOf(item.id);

                if (index === -1) {
                    values.push(item.id);
                } else {
                    values.splice(index, 1);
                }

                selectedValuesRef.current = values;
                setSelectedValues(values);
                onChangeRef.current && onChangeRef.current(values);
            };
        },
        [setSelectedValues]
    );

    return (
        <List className={className} disablePadding={disablePadding} dense={dense}>
            {items.map((i) => {
                return (
                    <ListItem
                        key={i.id}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggle(i)}
                        disabled={disabled}
                    >
                        {renderer({ ...i, checked: selectedValuesRef.current.indexOf(i.id) >= 0 })}
                    </ListItem>
                );
            })}
        </List>
    );
}

export { SelectList };
export type { SelectListProps, ItemType };
