import { blobObjectUrlRetriever } from "@pedal/infrastructure";
import { getServiceProviderDetails, ServiceProvider } from "./queries";

async function getServiceProviderCore(serviceProviderId: string): Promise<ServiceProvider | null> {
    const provider = await getServiceProviderDetails(serviceProviderId);
    if (!provider) {
        return null;
    }

    const bannerBlobUrl = await blobObjectUrlRetriever(provider.bannerUrl);
    if (!bannerBlobUrl) {
        return null;
    }

    return {
        ...provider,
        bannerUrl: bannerBlobUrl,
    };
}

const getServiceProvider = (() => {
    const cache: Partial<Record<string, ServiceProvider | null>> = {};
    return async (serviceProviderId: string) => {
        let provider = cache[serviceProviderId];
        if (provider !== undefined) {
            return provider ?? undefined;
        }

        provider = await getServiceProviderCore(serviceProviderId);
        cache[serviceProviderId] = provider;

        return provider ?? undefined;
    };
})();

export { getServiceProvider };
