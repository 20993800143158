import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextButton, ActionButton } from "@pedal/component-library";
import { getAppOptions, raiseEvent } from "@pedal/infrastructure";
import { useStore } from "store";
import { EventTypes } from "../eventTypes";

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: theme.spacing(2),
    },
    openSettings: {
        marginTop: theme.spacing(1),
    },
    openSettingsButton: {
        textDecoration: "underline",
    },
    button: {
        marginTop: theme.spacing(3),
    },
}));

function PermissionRequiredPod() {
    const styles = useStyles();
    const loading = useStore((r) => r.currentLocation.loading);

    const retry = () => raiseEvent(EventTypes.deviceLocationRequested);
    const canOpenSettings = getAppOptions()?.versionIsAtleast(1, 1, 2);
    const openSettings = () => raiseEvent(EventTypes.openAppSettings);

    return (
        <div>
            <Typography variant="h6" align="center" className={styles.title}>
                Allow Pedal to use your location?
            </Typography>
            <Typography variant="body1" align="center" color="textPrimary">
                Turning on location services allows us to show the cheapest fuel stations, Roadside
                assistance and mobile services.
            </Typography>
            {canOpenSettings && (
                <Typography
                    variant="body1"
                    align="center"
                    color="textPrimary"
                    className={styles.openSettings}
                >
                    Click{" "}
                    <TextButton
                        variant="body1"
                        onClick={openSettings}
                        className={styles.openSettingsButton}
                    >
                        here to open settings
                    </TextButton>{" "}
                    and then allow Location Permission.
                </Typography>
            )}
            <ActionButton
                className={styles.button}
                text="Try again"
                loading={loading}
                disabled={loading}
                onClick={retry}
                fullWidth
            />
        </div>
    );
}

export { PermissionRequiredPod };
