export function toNumberDisplay(number: number, fractionDigits?: number) {
    return Number.isInteger(number) ? number.toString() : number.toFixed(fractionDigits);
}

export function toNumberLocaleDisplay(number: number) {
    return number.toLocaleString("en", {
        maximumSignificantDigits: 3,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
}

const currencyOpts: Intl.NumberFormatOptions = {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
};
export function toCurrencyDisplay(number: number) {
    return number.toLocaleString("en-GB", currencyOpts);
}
