import React from "react";
import { raiseEvent, listenEvent } from "@pedal/infrastructure";
import { EventTypes } from "../../eventTypes";
import { DeviceLocationPermissionRequestComplete } from "../types";

function useRequestPermission() {
    return React.useCallback(function () {
        return new Promise<DeviceLocationPermissionRequestComplete>((resolve) => {
            raiseEvent(EventTypes.deviceLocationPermissionRequested);

            const disposer = listenEvent<DeviceLocationPermissionRequestComplete>(
                EventTypes.deviceLocationPermissionRequestComplete,
                (data) => {
                    disposer();
                    resolve(data);
                }
            );
        });
    }, []);
}

export { useRequestPermission };
