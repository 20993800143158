import React from "react";
import { raiseEvent, listenEvent } from "@pedal/infrastructure";
import { EventTypes } from "../../eventTypes";
import { DeviceLocationData } from "../types";
import { ReturnType } from "./types";

type UseRequestCoordinates = Omit<ReturnType<"coordinates">, "loading">;

function useRequestCoordinates() {
    return React.useCallback(function () {
        return new Promise<UseRequestCoordinates>((resolve) => {
            raiseEvent(EventTypes.deviceLocationRequested);

            const disposer = listenEvent<DeviceLocationData>(
                EventTypes.deviceLocationRequestComplete,
                (data) => {
                    disposer();

                    resolve({
                        coordinates: data.coordinates,
                        requiresPermission: data.error !== undefined,
                    });
                }
            );
        });
    }, []);
}

export { useRequestCoordinates };
