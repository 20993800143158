import React, { ReactChild } from "react";
import { Paper, Box, Divider, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { hasNamedChildrenSlots } from "../../utils";
import clsx from "clsx";

const podContentPadding = 2;

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: 16,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflow: "hidden",
    },
    podSection: {
        padding: theme.spacing(podContentPadding),
    },
    podContent: {
        flex: 1,
    },
    loader: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
    },
}));

type NamedChildrenSlots = {
    title: ReactChild;
    content: ReactChild | ReactChild[];
    footer?: ReactChild;
};

type PodProps = {
    children: ReactChild | ReactChild[] | NamedChildrenSlots;
    className?: string;
    classes?: Partial<{ root: string; content: string }>;
    loading?: boolean;
};

/**
 * A low-level component which displays content in a material {@link Paper} component.
 *
 * Has options to display a simple child component or render the pod with a title, content
 * and a footer.
 *
 * @param {PodProps} props The component props.
 * @returns {JSX.Element} The component element.
 */
const Pod = (props: PodProps) => {
    const styles = useStyles();
    const { className, classes, loading, children } = props;

    const title = hasNamedChildrenSlots(children) ? children.title : undefined;
    const content = hasNamedChildrenSlots(children) ? children.content : children;
    const footer = hasNamedChildrenSlots(children) ? children.footer : undefined;

    return (
        <Paper className={clsx(styles.paper, className, classes?.root)}>
            {loading && <LinearProgress color="primary" className={styles.loader} />}
            {title && (
                <>
                    <Box className={styles.podSection}>{title}</Box>
                    <Divider />
                </>
            )}
            <Box
                className={clsx(styles.podSection, styles.podContent, classes?.content)}
                data-testid="content"
            >
                {content}
            </Box>
            {footer && (
                <>
                    <Divider />
                    <Box className={styles.podSection}>{footer}</Box>
                </>
            )}
        </Paper>
    );
};

export { Pod, podContentPadding };
export type { NamedChildrenSlots, PodProps };
