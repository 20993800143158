import { graphQL } from "@pedal/pedal-api";
import { VehicleValuation } from "api";

type VehicleValuationResponse = {
    valuation: VehicleValuation | null;
};

export async function getVehicleValuation(vehicleId: string) {
    const res = await graphQL.query<"vehicle", VehicleValuationResponse>({
        query: `
vehicle (id: "${vehicleId}") {
    valuation {
        otr
        dealerForecourt
        tradeRetail
        tradeAverage
        tradePoor
        privateClean
        privateAverage
        partExchange
        auction
    }
}`,
    });

    return res.data.vehicle;
}
