import { listenEvent } from "@pedal/infrastructure";
import { EventTypes } from "../eventTypes";
import * as vehiclesSlice from "store/vehicle/vehiclesSlice";
import * as userSlice from "store/user/userSlice";
import { Store } from "store/types";
import { VehicleItem } from "api";

function setupVehicleAddedListener({ dispatch, getState }: Store) {
    listenEvent(EventTypes.vehicleAdded, (vehicle?: VehicleItem) => {
        if (!vehicle) {
            return;
        }

        dispatch(vehiclesSlice.addVehicle(vehicle));

        // also add the vehicle to the users linked user vehicles
        dispatch(userSlice.addVehicle(vehicle));

        const userId = getState().user.id;
        if (userId === vehicle.userId) {
            dispatch(vehiclesSlice.setCurrentVehicle(vehicle.id));
        }
    });
}

export { setupVehicleAddedListener };
