import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { constants } from "../constants";

const useStyles = makeStyles((theme) => ({
    container: {
        width: constants.defaultSize,
        height: constants.defaultSize,
        display: "block",
    },
    icon: {
        width: "100%",
        height: "100%",
        fill: theme.palette.primary.main,
        transform: function ({ variant }: NavigateProps) {
            return variant === "forwards" ? "rotate(180deg)" : undefined;
        },
    },
}));

type NavigateProps = { className?: string; variant?: "backwards" | "forwards" };

function Navigate(props: NavigateProps) {
    const styles = useStyles(props);
    const { className } = props;

    return (
        <span className={clsx(styles.container, className)}>
            <svg
                width="100pt"
                height="100pt"
                version="1.1"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
                className={styles.icon}
            >
                <g>
                    <path d="m55.883 19.695-8.6445-8.6445-38.906 38.906 0.046875 0.042969-0.046875 0.042969 38.906 38.906 8.6445-8.6445-30.305-30.305z" />
                    <path d="m91.668 19.695-8.6484-8.6445-38.902 38.906 0.046874 0.042969-0.046874 0.042969 38.902 38.906 8.6484-8.6445-30.305-30.305z" />
                </g>
            </svg>
        </span>
    );
}

export { Navigate };
export type { NavigateProps };
