import { useEffect } from "react";
import { withAppVersionIsLess, listenEvent } from "@pedal/infrastructure";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { useStore } from "store";

const appId = process.env.REACT_APP_INTERCOM_APP_ID!;

const openChatEvent = "openChat";
const openNewChatEvent = "openNewChat";
const onSignOutEvent = "onSignOut";

function IntercomInitializer() {
    const user = useStore((r) => r.user);
    const { boot, shutdown, show, showNewMessages } = useIntercom();

    useEffect(() => {
        boot({
            userId: user.id,
            userHash: user.identifiers.intercomWeb,
            name: user.displayName, // Full name
            email: user.emailAddress, // Email address
            createdAt: (new Date(user.dateJoined).getTime() / 1000).toString(), // Signup date as a Unix timestamp
            hideDefaultLauncher: true,
        });

        return () => shutdown();
    }, [boot, shutdown, user]);

    // on signout
    useEffect(() => listenEvent(onSignOutEvent, () => shutdown()), [shutdown]);

    // message events
    useEffect(() => {
        const disposables = [
            listenEvent(openChatEvent, () => show()),
            listenEvent(openNewChatEvent, () => showNewMessages()),
        ];

        return () => disposables.forEach((f) => f());
    }, [show, showNewMessages]);

    return null;
}

const Intercom = withAppVersionIsLess([1, 2, 0], function () {
    return (
        <IntercomProvider appId={appId} autoBoot={false}>
            <IntercomInitializer />
        </IntercomProvider>
    );
});

export { Intercom };
