import React from "react";
import { Dialog, DialogContent, DialogActions, Divider } from "@material-ui/core";
import { DialogTitle, CommonDialogActions } from "../../dialog";
import { DialogData } from "../types";
import { EventTypes } from "../eventTypes";

function ConfirmDialog() {
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState<DialogData>({
        id: "",
        title: "",
        content: "",
    });

    React.useEffect(() => {
        const eventListener = ((e: CustomEvent<DialogData | undefined>) => {
            if (!e.detail) {
                throw new Error("No dialog data");
            }

            setData(e.detail);
            setOpen(true);
        }) as EventListener;

        window.addEventListener(EventTypes.openDialog, eventListener);

        return () => window.removeEventListener(EventTypes.openDialog, eventListener);
    }, []);

    const reset = React.useCallback(function () {
        setOpen(false);
        setData({
            id: "",
            title: "",
            content: "",
            okButton: undefined,
        });
    }, []);

    const dispatchEvent = React.useCallback(
        function (resolved: boolean) {
            const type = `${EventTypes.onDialogClose}_${data.id}`;
            window.dispatchEvent(new CustomEvent(type, { detail: resolved }));
        },
        [data.id]
    );

    const onClose = React.useCallback(
        function () {
            dispatchEvent(false);
            reset();
        },
        [dispatchEvent, reset]
    );

    const onConfirm = React.useCallback(
        function () {
            dispatchEvent(true);
            reset();
        },
        [dispatchEvent, reset]
    );

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="dialog-title"
            maxWidth={"xs"}
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle title={data.title} onClose={onClose} />
            <Divider />
            <DialogContent>{data.content}</DialogContent>
            <Divider />
            <DialogActions>
                <CommonDialogActions
                    okButtonMessage={data.okButton ?? "Ok"}
                    onOk={onConfirm}
                    onCancel={onClose}
                />
            </DialogActions>
        </Dialog>
    );
}

export { ConfirmDialog };
