import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import clsx from "clsx";

export type GaugeProps = {
    radius: number;
    strokeWidth: number;
    value: number;
    title: string;
    label?: string;
    className?: string;
};

const arcPercent = 0.55;
function segmentHeight(radius: number, percent: number) {
    // calculates the height of the segment.
    // the formula is d + R where R = radius and d = R * cos(angle / 2);
    // were the 'angle' for us is 360 degrees * arc percent amount
    const radians = 360 * percent * (Math.PI / 180);
    return Math.abs(Math.cos(radians / 2)) * radius + radius;
}

function calculateGaugeValues(radius: number, strokeWidth: number) {
    // calculates all values necessary to make the gauge
    const innerRadius = radius - strokeWidth;
    const circumference = innerRadius * 2 * Math.PI;
    const arc = circumference * arcPercent;

    const dashArray = `${arc} ${circumference}`;

    // this is the angle to make sure the gauge is always pointing downwards
    const rotationAngle = 90 + (360 * (1 - arcPercent)) / 2;
    const transform = `rotate(${rotationAngle}, ${radius}, ${radius})`;

    const height = segmentHeight(radius, arcPercent);
    return {
        innerRadius,
        arc,
        dashArray,
        transform,
        width: radius * 2,
        viewBox: `0 0 ${radius * 2} ${height}`,
    };
}

function Gauge({ radius, strokeWidth, title, value, label, className }: GaugeProps) {
    const { innerRadius, arc, dashArray, transform, width, viewBox } = React.useMemo(
        () => calculateGaugeValues(radius, strokeWidth),
        [radius, strokeWidth]
    );

    const offset = arc - (value / 100) * arc;
    const styles = useStyles();

    return (
        <div className={clsx(styles.container, className)}>
            <Typography variant="h6" align="center" gutterBottom>
                {title}
            </Typography>
            <div className={styles.innerContainer}>
                <Typography className={styles.text} variant="body1">
                    <span>{label}</span>
                    <br />
                    <span className={styles.value}>{value}</span>
                </Typography>
                <svg className={styles.svg} height="100%" width={width} viewBox={viewBox}>
                    <circle
                        className={styles.baseGauge}
                        cx={radius}
                        cy={radius}
                        fill="transparent"
                        r={innerRadius}
                        strokeWidth={strokeWidth}
                        strokeDasharray={dashArray}
                        transform={transform}
                    />
                    <circle
                        className={styles.valueGauge}
                        cx={radius}
                        cy={radius}
                        fill="transparent"
                        r={innerRadius}
                        strokeDasharray={dashArray}
                        strokeDashoffset={offset}
                        strokeWidth={strokeWidth * 1.2}
                        transform={transform}
                    />
                </svg>
                <div className={styles.rangeContainer}>
                    <Typography className={styles.minValue} variant="body1">
                        0
                    </Typography>
                    <Typography className={styles.maxValue} variant="body1">
                        100
                    </Typography>
                </div>
            </div>
        </div>
    );
}

const body1Offset = 24; // offset height of body1 for min and max so they appear at the bottom of the svg
const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    innerContainer: {
        position: "relative",
    },
    rangeContainer: {
        height: body1Offset,
        position: "relative",
    },
    text: {
        position: "absolute",
        top: "calc(50% + 4px)",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
    },
    value: {
        fontSize: "2rem",
        lineHeight: "1",
        color: theme.palette.primary.main,
    },
    svg: {
        display: "block",
    },
    baseGauge: {
        stroke: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    valueGauge: {
        stroke: theme.palette.primary.main,
        transition: "stroke-dashoffset 0.3s",
    },
    minValue: {
        position: "absolute",
        left: 2,
        bottom: 0,
    },
    maxValue: {
        position: "absolute",
        right: -4,
        bottom: 0,
    },
}));

export { Gauge };
