import { graphQL } from "@pedal/pedal-api";
import { VehicleStatus } from "../types";

type Vehicle = {
    status: VehicleStatus | null;
};
export async function getVehicleStatus(vehicleId: string) {
    const res = await graphQL.query<"vehicle", Vehicle>({
        query: `
vehicle (id: "${vehicleId}") {
    status {
        sampledSeconds
        batteryVoltage
        engineCoolantTemperature
        engineOilTemperature
        fuelPercentage
        fuelLevel
        mpg
        isMoving
        gsmSignalStrength
    }
}`,
    });

    return res;
}
