import { listenEvent } from "@pedal/infrastructure";
import { EventTypes } from "../../eventTypes";
import { AppOnlyEventTypes } from "../eventTypes";
import { sendMessageHandler } from "../messageHandlers";

function getLocationRequestedListener() {
    return listenEvent(EventTypes.deviceLocationRequested, () => {
        sendMessageHandler(AppOnlyEventTypes.locationRequested, null);
    });
}

function getLocationPermissionRequestedListener() {
    return listenEvent(EventTypes.deviceLocationPermissionRequested, () => {
        sendMessageHandler(AppOnlyEventTypes.locationPermissionRequested, null);
    });
}

export { getLocationRequestedListener, getLocationPermissionRequestedListener };
