import React from "react";
import { Typography, Grid, GridProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Garage } from "../types";
import clsx from "clsx";

const useStyles = makeStyles({
    contentContainer: {
        whiteSpace: "nowrap",
        "& > *": {
            textOverflow: "ellipsis",
            overflow: "hidden",
        },
    },
});

type Props = {
    garage: Garage;
    hidePhoneNumber?: boolean;
} & Pick<
    GridProps,
    "item" | "xs" | "sm" | "md" | "lg" | "xl" | "className" | "onClick" | "tabIndex"
>;

const GarageItemDetails = (props: Props) => {
    const { garage, hidePhoneNumber, className, ...rest } = props;
    const showPhoneNumber = hidePhoneNumber !== true && garage.phoneNumber;

    const styles = useStyles();

    const address = (
        <>
            {garage.address.split(",").map((a, i) => (
                <React.Fragment key={i}>
                    {a}
                    <br />
                </React.Fragment>
            ))}
        </>
    );

    return (
        <Grid className={clsx(styles.contentContainer, className)} {...rest}>
            <Typography variant="h6" title={garage.name}>
                {garage.name}
            </Typography>
            <Typography variant="body2" gutterBottom>
                {garage.type} | {garage.distanceFromCurrentLocation.toFixed(2)} mi
            </Typography>
            <Typography variant="body1" gutterBottom>
                {address}
            </Typography>
            {showPhoneNumber && (
                <>
                    <Typography variant="body2">Tel:</Typography>
                    <Typography variant="body1">{garage.phoneNumber}</Typography>
                </>
            )}
        </Grid>
    );
};

export { GarageItemDetails };
