const defaultMilesForCambeltInspection = 60000;

export function getCambeltNextInspectionMiles(mileage?: number): number {
    if (!mileage) {
        return defaultMilesForCambeltInspection;
    }

    return (
        Math.floor(mileage / defaultMilesForCambeltInspection + 1) *
        defaultMilesForCambeltInspection
    );
}
