import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, IconButtonProps, DialogTitle as MuiDialogTitle } from "@material-ui/core";
import { PodTitle } from "../../pod";
import { CloseFilledIcon, withSize } from "../../icons";

const Close = withSize(CloseFilledIcon, 32);

// styles
export type DialogTitleProps = {
    logo?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    title: React.ReactChild;
    subtitle?: string;
    onClose?: IconButtonProps["onClick"];
    disabled?: boolean;
};

const DialogTitle = (props: DialogTitleProps) => {
    const styles = useStyles();
    return (
        <MuiDialogTitle disableTypography>
            <PodTitle
                logo={props.logo}
                title={props.title}
                subtitle={props.subtitle}
                classes={{ extension: styles.extension }}
                extension={
                    <IconButton
                        aria-label="close"
                        onClick={props.onClose}
                        disabled={props.disabled}
                        className={styles.button}
                    >
                        <Close />
                    </IconButton>
                }
            />
        </MuiDialogTitle>
    );
};

const useStyles = makeStyles((theme) => ({
    extension: {
        position: "relative",
        width: theme.spacing(6),
        height: theme.spacing(6),
    },
    button: {
        position: "absolute",
        right: -theme.spacing(2),
        top: -theme.spacing(0.75),
    },
}));

export { DialogTitle };
