import React from "react";
import { Switch, SwitchProps } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { createSwitchStyles } from "./switchStyles";
import { SwitchVariant } from "./types";

const SwitchBaseInternal = withStyles(createSwitchStyles("switch"))(Switch);
const ToggleBaseInternal = withStyles(createSwitchStyles("toggle"))(Switch);

type SwitchBaseProps = SwitchProps & {
    variant?: SwitchVariant;
};

const SwitchBase = ({ variant, ...rest }: SwitchBaseProps) =>
    variant === "toggle" ? <ToggleBaseInternal {...rest} /> : <SwitchBaseInternal {...rest} />;

export { SwitchBase };
