import { NotificationCategory } from "@pedal/component-library";
import { NotificationCode, NotificationType, VehicleNotificationBase } from "./apiTypes";

export type DefaultNotificationItemView = {
    id: string;
    type: NotificationType;
    vehicleId: string;
    isLinkedNotification: boolean;
    user: VehicleNotificationBase["user"];
    date: string;
    category: NotificationCategory;
    code?: NotificationCode;
    title: string;
    message?: string;
    icon: string;
    data?: Record<string, unknown>;
    source?: NotificationSource;
};

export type NotificationSource = {
    icon: string;
    type: string;
    color?: string;
    style?: React.CSSProperties;
};

export type OfferNotificationItemView = {
    id: string;
    imageUrl: string;
};

export type NotificationItemView = DefaultNotificationItemView | OfferNotificationItemView;

export function isDefault(n: NotificationItemView): n is DefaultNotificationItemView {
    return (n as DefaultNotificationItemView).icon !== undefined;
}
