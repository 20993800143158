import { memoize } from "lodash";

const screenTransitionTime = () =>
    typeof window !== "undefined" &&
    window.getComputedStyle(document.documentElement).getPropertyValue("--screen-transition-time");

const transitions = {
    screenTransitionTime: memoize(
        function () {
            const time = screenTransitionTime();
            return time ? Number.parseInt(time.replace("ms", "")) : 0;
        },
        () => "screenTransitionTime"
    ),
};

export { transitions };
