import { listenEvent } from "@pedal/infrastructure";
import { EventTypes } from "../eventTypes";
import { updateUserSubscriptionPlan } from "store/user";
import { updateSubscriptions, updateVehicleSubscription } from "store/vehicle";
import { Store } from "store/types";
import type { MeSubscriptionsResponse } from "../queries";

function setupOnUserMembershipUpdated({ dispatch, getState }: Store) {
    listenEvent<MeSubscriptionsResponse>(EventTypes.onUserMembershipUpdated, (d) => {
        if (!d) {
            return;
        }

        // update user
        dispatch(updateUserSubscriptionPlan(d.subscription));

        // update vehicles
        dispatch(updateSubscriptions(d.vehicles));

        // update current vehicle
        const currentVehicle = getState().currentVehicle.vehicle;
        if (!currentVehicle) {
            return;
        }

        const vehicleSubscription = d.vehicles.find((v) => v.id === currentVehicle.id);
        if (!vehicleSubscription) {
            return;
        }

        dispatch(updateVehicleSubscription(vehicleSubscription.subscription));
    });
}

export { setupOnUserMembershipUpdated };
