const zoom = 14;
const minZoom = 11;
const maxZoom = 18;
const bboxRadius = 20;

export const mapConstants = {
    zoom,
    minZoom,
    maxZoom,
    bboxRadius,
};
