import React from "react";
import { LinearProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { TextButton } from "@pedal/component-library";
import { getAppOptions, raiseEvent } from "@pedal/infrastructure";
import { useStore } from "store";
import { EventTypes } from "../eventTypes";

const useStyles = makeStyles({
    root: {
        position: "relative",
    },
    loader: {
        position: "absolute",
        width: "100%",
        top: 0,
        left: 0,
        height: "2px",
    },
    container: {
        display: "flex",
        justifyContent: "space-between",
        flex: 1,
    },
    tryAgain: {
        whiteSpace: "nowrap",
    },
});

function PermissionRequired() {
    const styles = useStyles();
    const loading = useStore((r) => r.currentLocation.loading);

    const retry = () => raiseEvent(EventTypes.deviceLocationRequested);
    const canOpenSettings = getAppOptions()?.versionIsAtleast(1, 1, 2);
    const openSettings = () => raiseEvent(EventTypes.openAppSettings);

    return (
        <Alert severity="warning" classes={{ root: styles.root, message: styles.container }}>
            {loading && <LinearProgress color="primary" className={styles.loader} />}
            <div>
                <Typography variant="body2" component="span">
                    Location permission is required.
                </Typography>
                {canOpenSettings && (
                    <TextButton variant="body2" onClick={openSettings}>
                        Open settings
                    </TextButton>
                )}
            </div>
            <TextButton
                variant="body2"
                className={styles.tryAgain}
                disabled={loading}
                onClick={retry}
            >
                Try again
            </TextButton>
        </Alert>
    );
}

export { PermissionRequired };
