import React from "react";
import shortid from "shortid";
import { DialogData } from "./types";
import { EventTypes } from "./eventTypes";

export type Confirm = (title: string, content: string) => Promise<boolean>;

export const confirm: Confirm = (title, content) => {
    const id = shortid();
    window.dispatchEvent(
        new CustomEvent<DialogData>(EventTypes.openDialog, {
            detail: {
                id,
                title,
                content,
            },
        })
    );

    return new Promise<boolean>((resolve, _) => {
        const type = `${EventTypes.onDialogClose}_${id}`;

        const eventListener = ((e: CustomEvent<boolean | undefined>) => {
            try {
                resolve(e.detail ?? false);
            } finally {
                window.removeEventListener(type, eventListener);
            }
        }) as EventListener;

        window.addEventListener(type, eventListener);
    });
};

export function useConfirm(): Confirm {
    return React.useCallback(confirm, []);
}
