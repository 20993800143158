import { graphQL, GraphQLMutationResponse } from "@pedal/pedal-api";

export function resolveNotification(notificationId: string) {
    return graphQL.sendCore<GraphQLMutationResponse<"configureVehicle">>({
        query: `mutation ResolveNotification($notificationId: UUID!) {
    resolveNotification(notificationId: $notificationId) {
        messages
    }
}`,
        variables: {
            notificationId,
        },
    });
}
