import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getVehicleCarbonEmissions as getVehicleCarbonEmissionsApi } from "./queries";
import { CarbonEmissions, Project } from "./types";
import { VehicleDataState } from "../vehicleTypes";

const getCarbonEmissions = createAsyncThunk(
    "vehicleCarbonEmissions/getCarbonEmissions",
    async ({ vehicleId }: { vehicleId: string; asyncUpdate?: boolean }) => {
        const res = await getVehicleCarbonEmissionsApi(vehicleId);

        if (!res || !res.carbonEmissions || !res.trip || !res.trip.tripStartLocation) {
            return undefined;
        }

        const model: CarbonEmissions = {
            tripStart: res.trip.tripStart,
            tripStartLocation: res.trip.tripStartLocation.display,
            tripEnd: res.trip.tripEnd ?? undefined,
            tripEndLocation: res.trip.tripEndLocation?.display ?? undefined,
            currentTripEmissions: res.carbonEmissions.currentTripEmissions.toFixed(0),
            tripInProgress: res.status?.isMoving ?? false,
            project: res.carbonEmissions.project ?? undefined,
        };

        return model;
    }
);

const initialState: VehicleDataState<CarbonEmissions> = {
    loading: false,
};
const vehicleCarbonEmissionsSlice = createSlice({
    name: "vehicleCarbonEmissions",
    initialState: initialState,
    reducers: {
        setProject: (state, action: PayloadAction<{ project: Project }>) => {
            if (!state.data || !action.payload.project) {
                return;
            }

            state.data.project = action.payload.project;
        },
        removeProject: (state) => {
            if (!state.data) {
                return;
            }

            state.data.project = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCarbonEmissions.pending, (state, action) => {
            if (!action.meta.arg.asyncUpdate) {
                state.loading = true;
            }
        });
        builder.addCase(getCarbonEmissions.rejected, (state) => {
            state.loading = false;
            state.data = undefined;
        });
        builder.addCase(getCarbonEmissions.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
    },
});

export { getCarbonEmissions };

export const { setProject, removeProject } = vehicleCarbonEmissionsSlice.actions;

export default vehicleCarbonEmissionsSlice.reducer;
