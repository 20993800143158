import React from "react";
import { Grid, Divider, IconButton, IconButtonProps, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CloseFilledIcon, withSize } from "../../icons";
import clsx from "clsx";

const Close = withSize(CloseFilledIcon, 32);

type FullScreenProps = {
    title: string;
    children: React.ReactElement;
    isFullScreen: boolean;
    className?: string;
    onClose: IconButtonProps["onClick"];
};

function FullScreen({ title, children, isFullScreen, onClose, className }: FullScreenProps) {
    const styles = useStyles();

    return isFullScreen ? (
        <Grid
            container
            direction="column"
            className={clsx(styles.container, className)}
            wrap="nowrap"
        >
            <Grid item className={styles.titleContainer}>
                <Typography variant="h5">{title}</Typography>
                <IconButton edge="end" onClick={onClose}>
                    <Close />
                </IconButton>
            </Grid>
            <Divider />
            <Grid item className={styles.childrenContainer}>
                {children}
            </Grid>
        </Grid>
    ) : (
        children
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10000000,
        backgroundColor: theme.palette.common.white,
    },
    titleContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(1, 2),
    },
    childrenContainer: {
        flex: 1,
        overflow: "auto",
    },
}));

export { FullScreen };
