import { logger } from "@pedal/infrastructure";
import {
    VehicleNotification,
    OfferNotification,
    SystemNotification,
    NotificationItemView,
    OfferNotificationItemView,
    toDTCNotificationItemView,
    toVehicleRecallNotificationItemView,
    toSystemNotificationItemView,
    DefaultNotificationItemView,
    NotificationCode,
} from "@pedal/vehicle/notifications";
import { Partner } from "api";
import {
    ukGovSource,
    pedalSource,
    getSourceFromCode,
    getSourceFromPartner,
    getSourceFromServiceProvider,
    getOfferImageUrl,
} from "./mapping";

async function toOfferNotificationItemView(
    notification: OfferNotification
): Promise<OfferNotificationItemView | undefined> {
    const imageUrl = await getOfferImageUrl(notification.offerId);
    if (!imageUrl) {
        return;
    }

    const notificationView: OfferNotificationItemView = {
        id: notification.id,
        imageUrl: imageUrl,
    };

    return notificationView;
}

type NotificationSource = DefaultNotificationItemView["source"] | undefined;
async function handleError(promise: Promise<NotificationSource>) {
    try {
        return await promise;
    } catch (e) {
        logger.warn("Could not get notification source: ", e);
        return undefined;
    }
}

async function getNotificationSource(
    code: NotificationCode,
    notification: SystemNotification,
    partner: Partner
) {
    let notificationSource: NotificationSource;
    if (notification.context !== null) {
        switch (notification.context.type) {
            case "PARTNER":
                notificationSource = await handleError(getSourceFromPartner(partner));
                break;
            case "SERVICE":
                notificationSource = await handleError(
                    getSourceFromServiceProvider(notification.context)
                );
                break;
        }
    }

    return notificationSource ?? getSourceFromCode(code);
}

async function* toNotificationItemViews(
    notifications: VehicleNotification[],
    currentUserId: string,
    partner: Partner
): AsyncGenerator<NotificationItemView> {
    for (const n of notifications) {
        let view: NotificationItemView | undefined;
        switch (n.__typename) {
            case "SystemNotification":
                view = await toSystemNotificationItemView(n, currentUserId, (code, sn) =>
                    getNotificationSource(code, sn, partner)
                );
                break;
            case "VehicleRecallNotification":
                view = await toVehicleRecallNotificationItemView(n, currentUserId, ukGovSource);
                break;
            case "DTCNotification":
                view = await toDTCNotificationItemView(n, currentUserId, pedalSource);
                break;
            case "OfferNotification":
                view = await toOfferNotificationItemView(n);
                break;
        }

        if (view) {
            yield view;
        }
    }
}

export { toNotificationItemViews };
