import { getBlobObjectUrl } from "@pedal/infrastructure";
import { getOfferImageUrl } from "./queries";

function getOfferImageBlobUrl(offerId: string) {
    return getBlobObjectUrl(`offer_${offerId}`, async () => {
        const res = await getOfferImageUrl(offerId);
        return res ?? undefined;
    });
}

export { getOfferImageBlobUrl };
