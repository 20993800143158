import { RootState } from "store";
import { AppDispatch } from "store/types";
import * as listeners from "./event-forwarders";

function initializeEventForwarders() {
    const disposers = [...listeners.getGlobalListeners()];

    return () => disposers.forEach((e) => e());
}

function initializeStoreEventForwarders(getState: () => RootState, dispatch: AppDispatch) {
    const disposers = [
        listeners.getLocationRequestedListener(),
        listeners.getLocationPermissionRequestedListener(),
        listeners.getNotificationPermissionRequestedListener(),
        listeners.getOnStoreCreatedListener(getState),
        listeners.getVehicleLoadedListener(getState),
        listeners.getNavigateToListener(),
    ];

    return () => disposers.forEach((e) => e());
}

export { initializeEventForwarders, initializeStoreEventForwarders };
