import { getAppOptions } from "./getAppOptions";

function withAppVersionAtLeast<P extends {} = {}>(
    [major, minor, patch]: [number, number, number],
    Component: React.ComponentType<P>
) {
    return (props: P) => {
        if (getAppOptions()?.versionIsAtleast(major, minor, patch)) {
            return <Component {...props} />;
        }

        return null;
    };
}

function withAppVersionIsLess<P extends {} = {}>(
    [major, minor, patch]: [number, number, number],
    Component: React.ComponentType<P>
) {
    return (props: P) => {
        const options = getAppOptions();
        if (!options || options.versionIsLess(major, minor, patch)) {
            return <Component {...props} />;
        }

        return null;
    };
}

function withApp<P extends {} = {}>(Component: React.ComponentType<P>) {
    return (props: P) => {
        if (getAppOptions()?.isAppMode) {
            return <Component {...props} />;
        }

        return null;
    };
}

function withNonApp<P extends {} = {}>(Component: React.ComponentType<P>) {
    return (props: P) => {
        if (getAppOptions()?.isAppMode !== true) {
            return <Component {...props} />;
        }

        return null;
    };
}

export { withAppVersionAtLeast, withAppVersionIsLess, withApp, withNonApp };
