import React from "react";
import { chunkArray, toNumberDisplay, toNumberLocaleDisplay } from "@pedal/component-library";
import averageMpg from "@pedal/icons/dist/general/mpg.svg";
import cityMpg from "@pedal/icons/dist/general/city.svg";
import motorwayMpg from "@pedal/icons/dist/general/motorway.svg";
import fuelLevel from "@pedal/icons/dist/general/fuel-level.svg";
import temperature from "@pedal/icons/dist/general/car-temp.svg";
import battery from "@pedal/icons/dist/general/battery.svg";
import route from "@pedal/icons/dist/general/route.svg";
import oilTemp from "@pedal/icons/dist/general/oil-temp.svg";
import signalNoBar from "@pedal/icons/dist/general/signal_no-bar.svg";
import signal1Bar from "@pedal/icons/dist/general/signal_1-bar.svg";
import signal2Bar from "@pedal/icons/dist/general/signal_2-bar.svg";
import signal3Bar from "@pedal/icons/dist/general/signal_3-bar.svg";
import signal4Bar from "@pedal/icons/dist/general/signal_4-bar.svg";
import signal5Bar from "@pedal/icons/dist/general/signal_5-bar.svg";
import { VehicleStatus } from "../types";

type DataItem = {
    logo: string;
    title: string;
    value: string;
};

const getSignalStrengthIcon = (signalStrength: number | null) => {
    switch (signalStrength) {
        case 1:
            return signal1Bar;
        case 2:
            return signal2Bar;
        case 3:
            return signal3Bar;
        case 4:
            return signal4Bar;
        case 5:
            return signal5Bar;
        default:
            return signalNoBar;
    }
};

const valuePlaceholder: string = "--";
const grouping = 3;
const convertToCarDataItems = (data: VehicleStatus) =>
    chunkArray(
        [
            {
                logo: averageMpg,
                title: "Average",
                value: (!data.mpg ? valuePlaceholder : data.mpg.toFixed(1)) + " mpg",
            },
            {
                logo: cityMpg,
                title: "City",
                value: (!data.cityMpg ? valuePlaceholder : data.cityMpg.toString()) + " mpg",
            },
            {
                logo: motorwayMpg,
                title: "Motorway",
                value:
                    (!data.motorwayMpg ? valuePlaceholder : data.motorwayMpg.toString()) + " mpg",
            },
            {
                logo: route,
                title: "Journey status",
                value: data.isMoving ? "On-route" : "Parked",
            },
            {
                logo: getSignalStrengthIcon(data.gsmSignalStrength),
                title: "Signal strength",
                value:
                    data.gsmSignalStrength !== null && data.gsmSignalStrength > 0
                        ? `${data.gsmSignalStrength.toString()} bar`
                        : valuePlaceholder,
            },
            {
                logo: oilTemp,
                title: "Oil temp",
                value:
                    (!data.engineOilTemperature
                        ? valuePlaceholder
                        : toNumberDisplay(data.engineOilTemperature, 1)) + "\u00b0c",
            },
            {
                logo: fuelLevel,
                title: "Fuel level",
                value: data.fuelLevel
                    ? `${toNumberLocaleDisplay(data.fuelLevel)}L`
                    : data.fuelPercentage
                    ? `${data.fuelPercentage} %`
                    : valuePlaceholder,
            },
            {
                logo: temperature,
                title: "Engine temp",
                value:
                    (!data.engineCoolantTemperature
                        ? valuePlaceholder
                        : toNumberDisplay(data.engineCoolantTemperature, 1)) + "\u00b0c",
            },
            {
                logo: battery,
                title: "Battery",
                value:
                    (!data.batteryVoltage
                        ? valuePlaceholder
                        : toNumberLocaleDisplay(data.batteryVoltage)) + " v",
            },
        ],
        grouping
    ) as Array<Array<DataItem>>;

function useVehicleStatusData(vehicleStatus: VehicleStatus) {
    return React.useMemo(() => convertToCarDataItems(vehicleStatus), [vehicleStatus]);
}

export { useVehicleStatusData };
