import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { BoltIcon } from "../icons";
import { iconSizes } from "../sizes";
import { AnalyticsTrackProps, useAnalyticsProps } from "../../analytics";
import clsx from "clsx";

const useStyles = makeStyles({
    root: {
        justifyContent: "inherit",
    },
    iconContainer: {
        "& > svg": {
            display: "block",
            height: iconSizes.height,
            width: "auto",
        },
    },
    fuelType: {
        lineHeight: 1.2,
    },
});

export type EVDetailsProps = {
    actionText: string;
    onClick?: () => void;
    className?: string;
} & AnalyticsTrackProps;

const EVDetails = ({ actionText, onClick, className, ...analytics }: EVDetailsProps) => {
    const styles = useStyles();
    const analyticProps = useAnalyticsProps(analytics);

    return (
        <Grid
            container
            direction="row"
            className={clsx(styles.root, className)}
            onClick={onClick}
            alignItems="center"
            spacing={1}
            wrap="nowrap"
            {...analyticProps}
        >
            <Grid item className={styles.iconContainer}>
                <BoltIcon />
            </Grid>
            <Grid item>
                <Grid container direction="column">
                    <Typography variant="h6" className={styles.fuelType}>
                        Electric
                    </Typography>
                    <Typography variant="body2" component="span">
                        {actionText}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export { EVDetails };
