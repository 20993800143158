const configuration = {
    apiUri: process.env.REACT_APP_REST_BASEURI as string,
    auth: {
        azureB2C: {
            clientId: process.env.REACT_APP_AUTH_CLIENTID as string,
            authorityRoot: process.env.REACT_APP_AUTH_AUTHORITYROOT as string,
            appId: process.env.REACT_APP_AUTH_APPID as string,
        },
        redirectUri:
            window.location.origin +
            (window.location.pathname === "/" ? "" : window.location.pathname),
        authUri: `${window.location.origin}/auth.html`,
    },
};

export { configuration };
