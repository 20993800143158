import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { constants } from "../constants";

const useStyles = makeStyles((theme) => ({
    container: {
        width: constants.defaultSize,
        height: constants.defaultSize,
        display: "block",
        border: `2px solid ${theme.palette.primary.main}`,
        padding: "3px",
        borderRadius: constants.defaultSize / 2,
    },
    icon: {
        width: "100%",
        height: "100%",
    },
}));

type StarProps = { className?: string; classes?: Partial<{ root: string; icon: string }> };

function Star({ className, classes }: StarProps) {
    const styles = useStyles();
    return (
        <span className={clsx(styles.container, className, classes?.root)}>
            <svg
                width="100pt"
                height="100pt"
                version="1.1"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
                className={clsx(styles.icon, classes?.icon)}
            >
                <path d="m50 11 12.328 26.547 29.672 3.2461-22.051 19.652 6.0078 28.555-25.957-14.402-25.957 14.402 6.0078-28.555-22.051-19.652 29.672-3.2461z" />
            </svg>
        </span>
    );
}

export { Star };
