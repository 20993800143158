import React from "react";
import { styled } from "@material-ui/core/styles";
import { CircularProgress, Typography } from "@material-ui/core";

type ContentLoaderProps = {
    /**
     * The color of the loading spinner.
     */
    color?: "primary" | "secondary" | "inherit";
    /**
     * The size of the loading spinner, with or without dimensions.
     */
    size?: number | string;
    /**
     * The thickness of the loading spinner.
     */
    thickness?: number;
    /**
     * Text to explain the reason for the loading icon.
     */
    text?: string;
} & Record<string, unknown>;

const LoaderContainer = styled("div")({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
});

const LoaderContainerInner = styled("div")({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
});

/**
 * A (sub) component used to display a centered loading icon within a container.
 *
 * This component is absolutely positioned and thus requires a parent position relatively, in which this
 * loader will be centered.
 * @param {ContentLoaderProps} props The component props.
 * @returns {JSX.Element} The component element.
 */
function ContentLoader({ color, size, thickness, text, ...rest }: ContentLoaderProps) {
    return (
        <LoaderContainer {...rest}>
            <LoaderContainerInner>
                <CircularProgress color={color} size={size} thickness={thickness} />
                {text && <Typography variant="body1">{text}</Typography>}
            </LoaderContainerInner>
        </LoaderContainer>
    );
}

export { ContentLoader };
export type { ContentLoaderProps };
