import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "@pedal/styleguide";

const useStyles = makeStyles((theme) => ({
    container: {
        borderWidth: 1,
        borderStyle: "solid",
        padding: theme.spacing(2),
        borderColor: colors.platinumExtraDark,
        borderRadius: theme.shape.borderRadius * 4,
    },
    itemContainer: {
        width: "100%",
    },
    title: {
        lineHeight: 1.1,
        width: "50%",
    },
    subtitle: {
        width: "25%",
    },
}));

function VehicleDataBoxShimmer() {
    const styles = useStyles();
    return (
        <div className={styles.container}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item className={styles.itemContainer}>
                    <Typography variant="h6" component="h5" className={styles.title}>
                        <Skeleton />
                    </Typography>
                    <Typography variant="body2" className={styles.subtitle}>
                        <Skeleton />
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export { VehicleDataBoxShimmer };
