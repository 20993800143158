import React from "react";
import { IconButton, IconButtonProps } from "@material-ui/core";
import { Navigate, NavigateProps } from "../../icons";

/**
 * Props for {@link NavigateButton}.
 */
type NavigateButtonProps = {
    /**
     * Next or previous type for the button.
     */
    variant?: NavigateProps["variant"];
} & Pick<IconButtonProps, "className" | "disabled" | "size" | "edge" | "onClick">;

/**
 * Button component used for navigation throughout pedal.
 *
 * @param {NavigateButtonProps} props The component props.
 * @returns {JSX.Element} The component.
 */
function NavigateButton({
    disabled,
    variant,
    className,
    size = "small",
    edge,
    onClick,
}: NavigateButtonProps): JSX.Element {
    return (
        <IconButton
            className={className}
            size={size}
            aria-label={variant}
            disabled={disabled}
            edge={edge}
            onClick={onClick}
        >
            <Navigate variant={variant} />
        </IconButton>
    );
}

export { NavigateButton };
export type { NavigateButtonProps };
