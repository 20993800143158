import { marked } from "marked";
import { toEnumMember } from "@pedal/utility";
import { NotificationCategory } from "@pedal/component-library";
import alertIcon from "@pedal/icons/dist/general/alert.svg";
import {
    SystemNotification,
    DefaultNotificationItemView,
    NotificationCode,
    NotificationSource,
} from "../types";
import { toDateTimeString } from "../utils";
import { notificationCodeMap } from "./notificationCodeMap";

marked.use({
    tokenizer: {
        url() {
            // having nothing here disables gfm autolinks
        },
    } as any,
});

type NotificationSourceGetter = (
    code: NotificationCode,
    notification: SystemNotification
) => Promise<NotificationSource | undefined>;

async function toSystemNotificationItemView(
    notification: SystemNotification,
    currentUserId: string,
    source?: NotificationSourceGetter
): Promise<DefaultNotificationItemView | undefined> {
    const code = toEnumMember(NotificationCode, notification.code);
    const codeMap = notificationCodeMap[code];
    if (!codeMap) {
        return;
    }

    const category = toEnumMember(NotificationCategory, notification.category);
    const { icon, title } = codeMap;

    const notificationView: DefaultNotificationItemView = {
        id: notification.id,
        type: notification.__typename,
        vehicleId: notification.vehicleId,
        isLinkedNotification: currentUserId !== notification.user.id,
        user: notification.user,
        category: category,
        code: code,
        icon: icon || alertIcon,
        date: toDateTimeString(notification.actionDate || notification.notificationDate),
        data: notification.data ?? undefined,
        title: notification.title ?? title,
        source: source && (await source(code, notification)),
    };

    if (notification.markdownMessage) {
        notificationView.message = marked.parseInline(notification.markdownMessage);
    }

    return notificationView;
}

export { toSystemNotificationItemView };
