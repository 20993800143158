import { ThemeOptions } from "@material-ui/core/styles";
import { colors } from "./colors";

const theme = {
    typography: {
        fontFamily: ["'Titillium Web'"].join(","),
    },
    palette: {
        primary: {
            main: colors.primary,
        },
        text: {
            secondary: colors.pedalBlue,
        },
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                body: {
                    backgroundColor: undefined,
                },
            },
        },
        MuiButton: {
            root: {
                "text-transform": "inherit",
                borderRadius: 20,
                fontSize: "1rem",
            },
            label: {
                justifyContent: "space-between",
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 20,
            },
        },
        MuiDialog: {
            paper: {
                "overflow-y": undefined,
            },
            paperWidthSm: {
                maxWidth: 700,
            },
        },
        MuiDialogActions: {
            root: {
                justifyContent: "space-evenly",
            },
            spacing: {
                "&> :not(:first-child)": {
                    marginLeft: "16px",
                },
            },
        },
        MuiTypography: {
            body1: {
                color: colors.black,
            },
            subtitle2: {
                fontSize: "1rem", // global font size is not setup correctly yet
                "font-weight": "600",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "1.5",
                letterSpacing: "normal",
            },
        },
        // mui datatable
        MUIDataTable: {
            paper: {
                boxShadow: "none",
            },
        },
        MUIDataTableHeadRow: {
            root: {
                "& th": {
                    borderBottom: "none",
                },
            },
        },
        MuiTableCell: {
            root: {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        MUIDataTablePagination: {
            root: {
                padding: 0,
            },
        },
    },
} as ThemeOptions;

export { theme };
