import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { TripHistoryMonthView, getDrivingStatistics as getDrivingStatisticsApi } from "./queries";
import { DrivingStatistics, MileageChange } from "./types";
import { VehicleDataState } from "../vehicleTypes";

const getDrivingStatistics = createAsyncThunk(
    "vehicleDrivingStatistics/getDrivingStatistics",
    async ({ vehicleId }: { vehicleId: string; asyncUpdate?: boolean }) => {
        const res = await getDrivingStatisticsApi(vehicleId);
        if (!res.data.vehicle?.drivingStatistics) {
            return undefined;
        }

        const tripHistory = res.data.vehicle.tripHistory;

        function getMileageChange(
            from: TripHistoryMonthView,
            to: TripHistoryMonthView
        ): MileageChange | undefined {
            const changeInPercent =
                from.distanceInMiles === 0
                    ? undefined
                    : ((to.distanceInMiles - from.distanceInMiles) / from.distanceInMiles) * 100;

            if (!changeInPercent) {
                return undefined;
            }

            return {
                percent: changeInPercent.toFixed(0) + "%",
                isIncrease: changeInPercent > 0,
            };
        }

        const data: DrivingStatistics = {
            ...res.data.vehicle.drivingStatistics,
            tripHistory: tripHistory
                ? {
                      currentMonth: {
                          mileageChange: getMileageChange(
                              tripHistory.previousMonth,
                              tripHistory.currentMonth
                          ),
                          distanceInMiles: tripHistory.currentMonth.distanceInMiles.toFixed(1),
                      },
                      previousMonth: {
                          mileageChange: getMileageChange(
                              tripHistory.currentMonth,
                              tripHistory.previousMonth
                          ),
                          distanceInMiles: tripHistory.previousMonth.distanceInMiles.toFixed(1),
                      },
                  }
                : {
                      currentMonth: {
                          mileageChange: undefined,
                          distanceInMiles: "0",
                      },
                      previousMonth: {
                          mileageChange: undefined,
                          distanceInMiles: "0",
                      },
                  },
        };

        return data;
    }
);

const initialState: VehicleDataState<DrivingStatistics> = {
    loading: false,
};
const vehicleDrivingStatisticsSlice = createSlice({
    name: "vehicleDrivingStatistics",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDrivingStatistics.pending, (state, action) => {
            if (!action.meta.arg.asyncUpdate) {
                state.loading = true;
            }
        });
        builder.addCase(getDrivingStatistics.rejected, (state) => {
            state.loading = false;
            state.data = undefined;
        });
        builder.addCase(getDrivingStatistics.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
    },
});

export { getDrivingStatistics };

export default vehicleDrivingStatisticsSlice.reducer;
