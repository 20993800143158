import React from "react";
import { styled } from "@material-ui/core/styles";
import { CircularProgress, CircularProgressProps } from "@material-ui/core";

type HeightUnit = "px" | "vh" | "%";
type HeightType = `${number}${HeightUnit}`;

type LoaderProps = {
    containerHeight?: HeightType;
} & Pick<CircularProgressProps, "color" | "thickness" | "size">;

const LoaderContainer = styled("div")({
    display: "flex",
    width: "100%",
    height: ({ height }: { height: HeightType }) => height,
    "align-items": "center",
    "justify-content": "center",
});

const Loader = (props: LoaderProps) => {
    const { containerHeight = "50vh", ...rest } = props;
    return (
        <LoaderContainer height={containerHeight}>
            <CircularProgress {...rest} />
        </LoaderContainer>
    );
};

export { Loader };
