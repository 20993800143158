import React from "react";
import { ExitToAppRounded } from "@material-ui/icons";
import { TextIconButton } from "@pedal/component-library";
import * as userActions from "store/user/userSlice";
import { useAppDispatch } from "store";

const Button = TextIconButton(ExitToAppRounded);

type Props = {
    className?: string;
};

const SignOut = ({ className }: Props) => {
    const dispatch = useAppDispatch();
    const signOut = () => dispatch(userActions.signOut());
    return <Button className={className} text="Sign out" onClick={signOut} variant="subtitle2" />;
};

export { SignOut };
