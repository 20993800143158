import React from "react";
import { styled } from "@material-ui/core/styles";
import optionsSvg from "../../../icons/options/options_horizontal.svg";

const Img = styled("img")({
    margin: "0 auto",
    display: "block",
});

const detailColumn = {
    name: "Details",
    options: {
        sort: false,
        customBodyRender: () => {
            return <Img src={optionsSvg} alt="row" />;
        },
        setCellHeaderProps: () => {
            return {
                style: {
                    textAlign: "center",
                    maxWidth: "15px",
                },
            };
        },
    },
};

export { detailColumn };
