import { graphQL, GraphQLMutationResponse } from "@pedal/pedal-api";

export function skipNotification(notificationId: string) {
    return graphQL.sendCore<GraphQLMutationResponse<"configureVehicle">>({
        query: `mutation SkipNotification($notificationId: UUID!) {
    skipNotification(notificationId: $notificationId) {
        messages
    }
}`,
        variables: {
            notificationId,
        },
    });
}
