export enum NotificationCode {
    SensorUnpairedVehicleDetected,
    SensorUnplugged,
    MotIsExpiring,
    InsuranceIsExpiring,
    RoadTaxIsExpiring,
    ConfigurationPending,
    UpgradeTrialAccount,
    VehicleTowing,
    Collision,
    MileageAnomaly,
    SignalJammed,
    Pitstop,
    CambeltInspection,
    SensorConnectionIssue,
    LowBatteryVoltage,
    LocationRequest,
    SharingLocation,
    NewVehicle,
    MileageInputRequired,
    ConnectVehicle,
    AdvertisementPublished,
    AdvertisementOfferReceived,
    VehicleSold,
    VehicleCollectedByBuyer,
}

export type NotificationType =
    | "SystemNotification"
    | "DTCNotification"
    | "VehicleRecallNotification"
    | "OfferNotification";

export interface VehicleNotificationBase {
    __typename: NotificationType;
    id: string;
    vehicleId: string;
    user: {
        id: string;
        firstname: string;
        mobileNumber: string;
    };
    notificationDate: string;
    title: string;
    markdownMessage: string;
    category: string;
}

export interface DTCNotification extends VehicleNotificationBase {
    __typename: "DTCNotification";
    dtcCode: string;
    dtc: DiagnosticTroubleCode | null;
}

export interface SystemNotification extends VehicleNotificationBase {
    __typename: "SystemNotification";
    code: string;
    actionDate: string | null;
    data: Record<string, unknown> | null;
    context: SystemNotificationContext | null;
}

export interface SystemNotificationContext {
    type: "PARTNER" | "SERVICE";
    contextId: string | null;
    contextName: string | null;
}

export interface VehicleRecallNotification extends VehicleNotificationBase {
    __typename: "VehicleRecallNotification";
    recallDate: string;
}

export interface OfferNotification extends VehicleNotificationBase {
    __typename: "OfferNotification";
    offerId: string;
}

export type VehicleNotification =
    | DTCNotification
    | SystemNotification
    | VehicleRecallNotification
    | OfferNotification;

export interface DiagnosticTroubleCode {
    text: string;
}
