import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CloseIcon } from "./icons";
import { constants } from "../constants";

type CloseProps = {
    size?: number;
};

function Close(props: CloseProps) {
    const styles = useStyles(props);
    return (
        <span className={styles.container}>
            <CloseIcon aria-label="Close" className={styles.icon} />
        </span>
    );
}

const useStyles = makeStyles((theme) => ({
    container: function ({ size = constants.defaultSize }: CloseProps) {
        return {
            width: size,
            height: size,
            display: "block",
            border: `2px solid ${theme.palette.primary.main}`,
            padding: theme.spacing(0.5),
            borderRadius: size / 2,
        };
    },
    icon: {
        display: "block",
        width: "100%",
        height: "100%",
    },
}));

export { Close };
