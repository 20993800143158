import { Store } from "store/types";
import * as listeners from "./event-listeners";

type Input = {
    store: Store;
    isAppMode: boolean;
};

function initEventListeners({ store, isAppMode }: Input) {
    listeners.setupConnectServiceConsentListener(isAppMode, store);

    if (!isAppMode) {
        listeners.setupSubscriptionUpgradeListeners();
        listeners.setupNavigateToListener();
    }

    listeners.setupDeviceLocationRequestedReduxInterop(store);

    listeners.setupVehicleAddedListener(store);
    listeners.setupVehicleRemovedListener(store);
    listeners.setupSensorEventListeners(store);
    listeners.setupDriverAddedListener(store.dispatch);
    listeners.setupApiErrorsListener();
    listeners.setupRequestVehicle(store);
    listeners.setupOnUserMembershipUpdated(store);
    listeners.setupOnUserMembershipUpDowngraded();
    listeners.setupDeepLinkingHandler(store);
}

export { initEventListeners };
