import { TableBody, TableRow, TablePaginationProps } from "@material-ui/core";
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import { styled } from "@material-ui/core/styles";
import { PaginationButton } from "../../../button";
import { Pagination } from "./Pagination";

const PaginationActionsContainer = styled("div")(({ theme }) => ({
    flexShrink: 0,
    "& button:first-child": {
        marginRight: theme.spacing(2),
    },
}));

const PaginationActions = (props: TablePaginationActionsProps) => {
    const { count, page, rowsPerPage, onChangePage } = props;
    const totalPages = Math.ceil(count / rowsPerPage) - 1;

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page + 1);
    };

    return (
        <PaginationActionsContainer>
            <PaginationButton
                type="previous"
                disabled={page === 0}
                onClick={handleBackButtonClick}
            />
            <PaginationButton
                type="next"
                disabled={page >= totalPages}
                onClick={handleNextButtonClick}
            />
        </PaginationActionsContainer>
    );
};

type Props = Pick<TablePaginationProps, "count" | "page" | "rowsPerPage" | "onPageChange">;

const selectProps = {
    inputProps: { "aria-label": "rows per page" },
    native: true,
};
function Footer(props: Props) {
    return (
        <TableBody>
            <TableRow>
                <Pagination
                    {...props}
                    rowsPerPageOptions={[]}
                    SelectProps={selectProps}
                    ActionsComponent={PaginationActions}
                />
            </TableRow>
        </TableBody>
    );
}

export { Footer };
