import React, { MouseEventHandler } from "react";
import { Button as MuiButton } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { styled } from "@material-ui/core/styles";

const Button = styled(MuiButton)(({ theme }) => ({
    minWidth: "inherit",
    padding: theme.spacing(0.5),
}));

/**
 * Props for {@link PaginationButton}.
 */
type PaginationButtonProps = {
    /**
     * Whether the button is disabled or not.
     */
    disabled?: boolean;
    /**
     * Next or previous type for the button.
     */
    type: "previous" | "next";
    /**
     * Event handler for when the button is clicked.
     */
    onClick?: MouseEventHandler<HTMLButtonElement>;
    /**
     * Classname to apply to the root element.
     */
    className?: string;
};

/**
 * Button component used for pagination within pedal.
 *
 * @param {PaginationButtonProps} props The component props.
 * @returns {JSX.Element} The component.
 */
function PaginationButton(props: PaginationButtonProps): JSX.Element {
    return (
        <Button
            variant="contained"
            color="primary"
            aria-label={props.type}
            size="small"
            disabled={props.disabled}
            onClick={props.onClick}
            className={props.className}
        >
            {props.type === "previous" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </Button>
    );
}

export { PaginationButton };
export type { PaginationButtonProps };
