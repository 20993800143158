import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { VehicleDataState } from "../vehicleTypes";
import { getCambeltNextInspectionMiles } from "./utils";
import { getVehicleRenewalDates } from "./queries";
import { configureVehicle } from "./mutations";
import { VehicleRenewalDates } from "./types";

const updateInsuranceExpiry = createAsyncThunk(
    "vehicleRenewalDates/updateInsuranceExpiry",
    async ({
        vehicleId,
        insuranceExpiryDate,
    }: {
        vehicleId: string;
        insuranceExpiryDate: Date | null;
    }) => {
        const res = await configureVehicle(vehicleId, insuranceExpiryDate);
        return { insuranceExpiryDate, errors: res.errors };
    }
);

const getRenewalDates = createAsyncThunk(
    "vehicleRenewalDates/getRenewalDates",
    async ({ vehicleId }: { vehicleId: string }) => {
        const res = await getVehicleRenewalDates(vehicleId);

        if (res === null) {
            return undefined;
        }

        const result: VehicleRenewalDates = res.isElectricVehicle
            ? {
                  ...res.renewalDates,
                  isElectricVehicle: true,
              }
            : {
                  ...res.renewalDates,
                  isElectricVehicle: false,
                  camBeltNextMiles:
                      res.renewalDates.camBeltNextMiles ?? getCambeltNextInspectionMiles(),
              };

        return result;
    }
);

const initialState: VehicleDataState<VehicleRenewalDates> = {
    loading: false,
};
const vehicleRenewalDatesSlice = createSlice({
    name: "vehicleRenewalDates",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(getRenewalDates.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getRenewalDates.rejected, (state) => {
            state.loading = false;
            state.data = undefined;
        });
        builder.addCase(getRenewalDates.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload ?? undefined;
        });
        builder.addCase(updateInsuranceExpiry.fulfilled, (state, action) => {
            if (!state.data) {
                return;
            }

            const insuranceExpiryDate = action.payload.insuranceExpiryDate;
            if (insuranceExpiryDate) {
                if (insuranceExpiryDate instanceof Date) {
                    state.data.insuranceExpiryDate = insuranceExpiryDate.toISOString();
                } else {
                    state.data.insuranceExpiryDate = insuranceExpiryDate;
                }
            } else {
                state.data.insuranceExpiryDate = null;
            }
        });
    },
    reducers: {
        updateCambeltInspection: (
            state,
            action: PayloadAction<{ mileage: number | undefined }>
        ) => {
            if (!action.payload.mileage || !state.data) {
                return state;
            }

            if (!state.data.isElectricVehicle) {
                state.data.camBeltNextMiles = getCambeltNextInspectionMiles(action.payload.mileage);
            }
        },
    },
});

export { getRenewalDates, updateInsuranceExpiry };

export const { updateCambeltInspection } = vehicleRenewalDatesSlice.actions;

export default vehicleRenewalDatesSlice.reducer;
