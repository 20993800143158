import { Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/core/styles/withStyles";
import { SwitchVariant } from "./types";

const width = 50;
const height = 25;
const padding = 2;

function createSwitchStyles(switchType: SwitchVariant): Styles<Theme, {}, string> {
    return (theme) => ({
        root: {
            width,
            height,
            padding: 0,
            display: "flex",
        },
        switchBase: {
            top: 2,
            left: 3,
            padding: padding,
            color:
                switchType === "toggle" ? theme.palette.primary.main : theme.palette.common.black,
            "&$checked": {
                transform: `translateX(${width / 2 - 2}px)`,
                color:
                    switchType === "toggle"
                        ? theme.palette.primary.main
                        : theme.palette.common.white,
                "& + $track": {
                    opacity: 1,
                    backgroundColor:
                        switchType === "toggle"
                            ? theme.palette.common.white
                            : theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: height - padding * 2 - 4,
            height: height - padding * 2 - 4,
            boxShadow: "none",
        },
        track: {
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: height / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
            transition: theme.transitions.create("background-color"),
        },
        checked: {},
    });
}

export { createSwitchStyles };
