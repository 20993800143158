import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getBlobObjectUrl } from "@pedal/infrastructure";
import { PartnerState } from "./partnerTypes";
import { setLogo, setTheme } from "../global/globalSlice";
import { getPartner } from "./queries";

const getPartnerById = createAsyncThunk(
    "partner/getPartnerById",
    async (partnerId: string, { dispatch }) => {
        const partner = await getPartner(partnerId);

        if (!partner) {
            return undefined;
        }

        const { images, configuration, ...rest } = partner;

        // set global logo and theme
        let logoData: string | undefined;
        if (images.logoUrl !== null) {
            try {
                logoData = await getBlobObjectUrl(`partner_${partner.id}_logo`, images.logoUrl);
            } catch (e) {
                console.warn("Could not load partner logo", e);
            }
        }

        if (logoData) {
            dispatch(setLogo({ logo: logoData, name: rest.name }));
        } else {
            dispatch(setLogo(undefined));
        }

        dispatch(setTheme(configuration?.theme ?? undefined));

        if (!configuration) {
            return { ...rest, configuration: null };
        }

        return {
            ...rest,
            configuration: { theme: configuration.theme, ...configuration.data },
        };
    }
);

const initialState: PartnerState = {
    data: undefined,
};

const partnerSlice = createSlice({
    name: "partner",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getPartnerById.fulfilled, (state, action) => {
            if (!action.payload) {
                return state;
            }

            state.data = action.payload;
        });
    },
});

export { getPartnerById };

export default partnerSlice.reducer;
