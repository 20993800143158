import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { fuelIcon } from "../icons";
import { iconSizes } from "../sizes";
import { AnalyticsTrackProps, useAnalyticsProps } from "../../analytics";
import clsx from "clsx";

const useStyles = makeStyles({
    root: {
        justifyContent: "inherit",
    },
    img: {
        display: "block",
        height: iconSizes.height,
    },
    fuelType: {
        textTransform: "capitalize",
        lineHeight: 1.2,
        whiteSpace: "nowrap",
    },
});

export type NoFuelPriceProps = {
    fuelType: string;
    actionText: string;
    onClick?: () => void;
    className?: string;
} & AnalyticsTrackProps;

const NoFuelPrice = ({
    fuelType,
    actionText,
    onClick,
    className,
    ...analytics
}: NoFuelPriceProps) => {
    const styles = useStyles();
    const analyticProps = useAnalyticsProps(analytics);

    return (
        <Grid
            container
            direction="row"
            className={clsx(styles.root, className)}
            onClick={onClick}
            alignItems="center"
            spacing={1}
            wrap="nowrap"
            {...analyticProps}
        >
            <Grid item>
                <img src={fuelIcon} alt="fuel" className={styles.img} />
            </Grid>
            <Grid item>
                <Grid container direction="column">
                    <Typography variant="h6" className={styles.fuelType}>
                        {fuelType}
                    </Typography>
                    <Typography variant="body2" component="span">
                        {actionText}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export { NoFuelPrice };
