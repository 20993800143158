import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextButton } from "../TextButton/TextButton";
import { SvgIconComponent } from "@material-ui/icons";
import clsx from "clsx";

type Props = {
    text: string;
    variant?: "subtitle2" | "body2";
    onClick: () => void;
    className?: string;
};

function TextIconButton(C: SvgIconComponent) {
    const Component = ({ text, variant = "body2", onClick, className }: Props) => {
        const styles = useStyles();
        return (
            <Grid
                container
                direction="row"
                className={clsx(styles.container, className)}
                alignItems="center"
            >
                <Grid item>
                    <C className={styles.icon} />
                </Grid>
                <Grid item>
                    <TextButton
                        color="inherit"
                        variant={variant}
                        onClick={onClick}
                        className={styles.button}
                    >
                        {text}
                    </TextButton>
                </Grid>
            </Grid>
        );
    };

    return Component;
}

const useStyles = makeStyles((theme) => ({
    container: {
        color: "inherit",
        "& > :first-child": {
            marginRight: theme.spacing(0.5),
        },
    },
    icon: {
        display: "block",
    },
    button: {
        fontWeight: "normal",
    },
}));

export { TextIconButton };
