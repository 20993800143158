import { marked } from "marked";
import uk from "@pedal/icons/dist/logos/uk.png";
import mot from "@pedal/icons/dist/general/mot.svg";
import insurance from "@pedal/icons/dist/general/vehicle-insurance.svg";
import signalBlock from "@pedal/icons/dist/general/signal-block.svg";
import towing from "@pedal/icons/dist/general/towing.svg";
import mileageAnomaly from "@pedal/icons/dist/general/mileage-anomaly.svg";
import pitstop from "@pedal/icons/dist/general/pitstop.svg";
import cambelt from "@pedal/icons/dist/general/cambelt.svg";
import battery from "@pedal/icons/dist/general/battery.svg";
import shareLocation from "@pedal/icons/dist/general/share-location.svg";
import vehicleDetails from "@pedal/icons/dist/general/vehicle-details.svg";
import vehicleSignal from "@pedal/icons/dist/general/vehicle-signal.svg";
import alertWarningIcon from "@pedal/icons/dist/general/alert-warning.svg";
import advertIcon from "@pedal/icons/dist/general/advert.svg";
import smsIcon from "@pedal/icons/dist/general/sms.svg";
import soldIcon from "@pedal/icons/dist/general/sold.svg";
import pickUpIcon from "@pedal/icons/dist/general/pick-up.svg";
import { NotificationCode } from "@pedal/vehicle/notifications";

type NotificationCodeMapProperties = Partial<{
    title: string;
    icon: string;
    markedOptions?: marked.MarkedOptions;
}>;

const notificationCodeMap: Partial<Record<NotificationCode, NotificationCodeMapProperties>> = {
    [NotificationCode.SensorUnpairedVehicleDetected]: {
        icon: vehicleSignal,
    },
    [NotificationCode.SensorUnplugged]: {
        icon: vehicleSignal,
    },
    [NotificationCode.MotIsExpiring]: {
        icon: mot,
    },
    [NotificationCode.InsuranceIsExpiring]: {
        icon: insurance,
    },
    [NotificationCode.RoadTaxIsExpiring]: {
        icon: uk,
    },
    [NotificationCode.ConfigurationPending]: {},
    [NotificationCode.VehicleTowing]: {
        icon: towing,
    },
    [NotificationCode.Collision]: {
        icon: alertWarningIcon,
    },
    [NotificationCode.MileageAnomaly]: {
        icon: mileageAnomaly,
    },
    [NotificationCode.SignalJammed]: {
        icon: signalBlock,
    },
    [NotificationCode.Pitstop]: {
        icon: pitstop,
    },
    [NotificationCode.CambeltInspection]: {
        icon: cambelt,
    },
    [NotificationCode.SensorConnectionIssue]: {
        icon: vehicleSignal,
    },
    [NotificationCode.LowBatteryVoltage]: {
        icon: battery,
    },
    [NotificationCode.LocationRequest]: {
        icon: shareLocation,
    },
    [NotificationCode.SharingLocation]: {
        icon: shareLocation,
    },
    [NotificationCode.NewVehicle]: {
        icon: vehicleDetails,
    },
    [NotificationCode.ConnectVehicle]: {
        icon: vehicleDetails,
    },
    [NotificationCode.MileageInputRequired]: {
        title: "Enter your current mileage",
    },
    [NotificationCode.AdvertisementPublished]: {
        icon: advertIcon,
    },
    [NotificationCode.AdvertisementOfferReceived]: {
        icon: smsIcon,
    },
    [NotificationCode.VehicleSold]: {
        icon: soldIcon,
    },
    [NotificationCode.VehicleCollectedByBuyer]: {
        icon: pickUpIcon,
    },
};

export { notificationCodeMap };
