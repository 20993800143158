import React, { ReactChild } from "react";
import { Pod, NamedChildrenSlots, PodProps } from "../Pod/Pod";
import { PodTitle } from "../PodTitle/PodTitle";
import { hasNamedChildrenSlots } from "../../utils";
import { AnalyticsTrackProps } from "../../analytics";

type DefaultPodProps = {
    logo?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    title: string;
    subtitle?: ReactChild;
    children: ReactChild | ReactChild[] | Omit<NamedChildrenSlots, "title">;
    onTitleClick?: () => void;
    className?: string;
    classes?: PodProps["classes"];
    loading?: boolean;
    titleExtension?: ReactChild;
} & AnalyticsTrackProps;

/**
 * A wrapper around the {@link Pod} which simplifies the setting of the pod title.
 *
 * Has options to display a simple child component or render the pod with content
 * and a footer.
 *
 * @param {DefaultPodProps} props The component props.
 * @returns {JSX.Element} The component element.
 */
const DefaultPod = (props: DefaultPodProps): JSX.Element => {
    const {
        logo,
        title,
        subtitle,
        onTitleClick,
        className,
        classes,
        loading,
        children,
        titleExtension,
        ...analytics
    } = props;

    return (
        <Pod className={className} classes={classes} loading={loading}>
            {{
                title: (
                    <PodTitle
                        logo={logo}
                        title={title}
                        subtitle={subtitle}
                        onClick={onTitleClick}
                        extension={titleExtension}
                        {...analytics}
                    />
                ),
                content: hasNamedChildrenSlots(children) ? children.content : children,
                footer: hasNamedChildrenSlots(children) ? children.footer : undefined,
            }}
        </Pod>
    );
};

export { DefaultPod };
export type { DefaultPodProps };
