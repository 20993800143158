import { graphQL, GraphQLConnectionResponse } from "@pedal/pedal-api";
import { VehicleItem } from "api";

export const VehicleItemField = `id
vrm
manufacturer
range
userId
imageUrl
subscription {
    plan
}
data {
    isActivated
    isConfigured
    isSold
}`;

export async function getVehicleItems() {
    const res = await graphQL.query<"vehicles", GraphQLConnectionResponse<VehicleItem>>({
        query:
            `
vehicles {
    nodes {
        ` +
            VehicleItemField +
            `
    }
}`,
    });

    return res.data.vehicles!.nodes;
}

export async function getVehicleItem(vehicleId: string) {
    const res = await graphQL.query<"vehicle", VehicleItem>({
        query:
            `
vehicle (id: "${vehicleId}") {
    ` +
            VehicleItemField +
            `
}`,
    });

    return res.data.vehicle;
}
