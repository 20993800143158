import { graphQL } from "@pedal/pedal-api";
import { SubscriptionOption } from "../types";

type Response = {
    subscriptionOptions: Array<SubscriptionOption>;
};

export async function getSubscriptionOptions() {
    const res = await graphQL.query<"me", Response>({
        query: `
me {
    subscriptionOptions {
        id
        plan
        prices {
            id
            amount
            currency
            interval
        }
    }
}`,
    });

    return res;
}
