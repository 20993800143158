import { graphQL, GraphQLResponse } from "@pedal/pedal-api";

export async function updateUserMobileNumber(
    userId: string,
    mobileNumber: string
): Promise<GraphQLResponse<"updateUser", { id: string }>> {
    const res = await graphQL.sendCore<GraphQLResponse<"updateUser", { id: string }>>({
        query: `mutation Mutation($userId: String!, $userInput: UpdateUserInput) {
    updateUser(userId: $userId, input: $userInput) {
        id
    }
}`,
        variables: {
            userId: userId,
            userInput: {
                mobileNumber,
            },
        },
    });

    return res;
}
