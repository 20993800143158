export function getDataSampledString(sampledSeconds: number) {
    if (!sampledSeconds) {
        return "Initial sample";
    }

    const seconds = sampledSeconds;
    const mins = seconds / 60;
    if (mins < 1) {
        return seconds.toString() + " secs sampled";
    }

    const hours = mins / 60;
    if (hours < 1) {
        return mins.toFixed(0) + " mins sampled";
    }

    return hours.toFixed(1) + " hrs sampled";
}

export function getIntervalStringInHours(durationInHours: number) {
    if (durationInHours <= 0) {
        return undefined;
    }

    switch (durationInHours) {
        case 1:
            return "1 hour";
        default:
            return `${durationInHours - 1}-${durationInHours} hours`;
    }
}
