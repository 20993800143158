import { graphQL } from "@pedal/pedal-api";
import { Garage } from "../types";

type VehicleGaragePartner = {
    garagePartner: Garage | null;
};
export async function getGaragePartner(vehicleId: string) {
    const res = await graphQL.query<"vehicle", VehicleGaragePartner>({
        query: `
vehicle (id: "${vehicleId}") {
    garagePartner {
        ${GarageFields}
    }
}`,
    });

    if (res.data.vehicle !== null && res.data.vehicle.garagePartner !== null) {
        return res.data.vehicle.garagePartner;
    }

    return null;
}

export const GarageFields = `id
name
type
address
longitude
latitude
phoneNumber
website`;
