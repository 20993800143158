import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

type StaticMapProps = {
    width: number;
    height: number;
    hiRes?: boolean;
    longitude: number;
    latitude: number;
    className?: string;
    alt?: string;
};

const mapsBaseUrl = "https://api.mapbox.com/styles/v1/pedalglobal/cjhz53qah2pdp2slim0exvppw/static";
const mapboxApiKey = process.env.REACT_APP_MAPBOX_API_KEY || "";

function generateMapUrl(
    width: number,
    height: number,
    hiRes: boolean,
    longitude: number,
    latitude: number
) {
    const marker = `https://static.pedalapp.com/images/pin/black_${hiRes ? "64x64" : "32x32"}.png`;
    const marker_p = `url-${encodeURIComponent(marker)}(${longitude},${latitude})`;

    const map_p = `${longitude},${latitude},14,0`;
    const dimensions_p = `${width}x${height}${hiRes ? "@2x" : ""}`;
    const url = `${mapsBaseUrl}/${marker_p}/${map_p}/${dimensions_p}?access_token=${mapboxApiKey}&logo=false`;

    return url;
}

const StaticMap = ({
    width,
    height,
    hiRes = false,
    latitude,
    longitude,
    className,
    alt,
}: StaticMapProps) => {
    const styles = useStyles();
    const src = React.useMemo(
        () => generateMapUrl(width, height, hiRes, longitude, latitude),
        [width, height, hiRes, longitude, latitude]
    );
    return <img className={clsx(styles.map, className)} src={src} alt={alt} />;
};

const useStyles = makeStyles({
    map: {
        display: "block",
        objectFit: "none",
        objectPosition: "50% 50%",
        width: "100%",
        height: "100%",
    },
});

export { StaticMap };
export type { StaticMapProps };
