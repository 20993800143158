import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, TableRow, TableCell } from "@material-ui/core";
import { VehicleMotHistoryItem, FailedVehicleMotHistory } from "./types";
import clsx from "clsx";

type Props = {
    item: VehicleMotHistoryItem;
    denseMode: boolean;
};

const useRowStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            padding: theme.spacing(3, 0),
        },
        "&.top > *": {
            paddingBottom: theme.spacing(1),
            borderBottom: 0,
        },
        "&.mid > *": {
            padding: theme.spacing(1, 0),
            borderBottom: 0,
        },
        "&.bottom > *": {
            paddingTop: theme.spacing(1),
        },
    },
    resultCell: {
        [theme.breakpoints.up("sm")]: {
            width: "15%",
            maxWidth: "140px",
        },
    },
    result: function ({ item }: Props) {
        return {
            textTransform: "uppercase",
            fontWeight: "bold",
            color:
                item.testResult === "PASS"
                    ? item.hasAdvisories
                        ? theme.palette.warning.dark
                        : undefined
                    : theme.palette.error.dark,
        };
    },
}));

function FailCell({ item, colSpan }: { item: FailedVehicleMotHistory; colSpan: number }) {
    return (
        <TableCell colSpan={colSpan}>
            {item.failureReasons.map((r, index) => (
                <Typography key={index} variant="body2">
                    {r}
                </Typography>
            ))}
        </TableCell>
    );
}

function TestResultCell({
    item,
    classes,
}: {
    item: VehicleMotHistoryItem;
    classes: ReturnType<typeof useRowStyles>;
}) {
    return (
        <TableCell className={classes.resultCell}>
            <Typography variant="caption">{item.testDate}</Typography>
            <Typography variant="h6" className={classes.result}>
                {item.testResult}
            </Typography>
        </TableCell>
    );
}

function AdvisoriesRow({
    item,
    colSpan,
    className,
}: {
    item: VehicleMotHistoryItem;
    colSpan: number;
    className?: string;
}) {
    if (!item.hasAdvisories) {
        return null;
    }

    return (
        <TableRow className={className}>
            <TableCell colSpan={colSpan}>
                <Typography variant="caption">Advisories</Typography>
                {item.advisoryAnnotationItems
                    .filter((a) => a.isFailure !== true)
                    .map((r, index) => (
                        <Typography key={index} variant="body2">
                            {r.text}
                        </Typography>
                    ))}
            </TableCell>
        </TableRow>
    );
}

function MotHistoryRow(props: Props) {
    const classes = useRowStyles(props);
    const { item, denseMode } = props;
    const hasAdvisories = item.hasAdvisories;

    return !denseMode ? (
        <>
            <TableRow className={clsx(classes.root, "row", hasAdvisories ? "top" : undefined)}>
                <TestResultCell item={item} classes={classes} />
                {item.testResult === "PASS" ? (
                    <>
                        <TableCell>
                            <Typography variant="caption">Mileage</Typography>
                            <Typography variant="h6">{item.odometerReading} miles</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="caption">MOT test number</Typography>
                            <Typography variant="h6">{item.testNumber}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="caption">Expiry date</Typography>
                            <Typography variant="h6">{item.expiryDate}</Typography>
                        </TableCell>
                    </>
                ) : (
                    <FailCell item={item} colSpan={3} />
                )}
            </TableRow>
            <AdvisoriesRow
                item={item}
                colSpan={4}
                className={clsx(classes.root, "row", "bottom")}
            />
        </>
    ) : (
        <>
            <TableRow className={clsx(classes.root, "row", "top")}>
                <TestResultCell item={item} classes={classes} />
                {item.testResult === "PASS" && (
                    <TableCell>
                        <Typography variant="caption">Mileage</Typography>
                        <Typography variant="h6">{item.odometerReading} miles</Typography>
                    </TableCell>
                )}
            </TableRow>
            <TableRow className={clsx(classes.root, "row", hasAdvisories ? "mid" : "bottom")}>
                {item.testResult === "PASS" ? (
                    <>
                        <TableCell>
                            <Typography variant="caption">MOT test number</Typography>
                            <Typography variant="h6">{item.testNumber}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="caption">Expiry date</Typography>
                            <Typography variant="h6">{item.expiryDate}</Typography>
                        </TableCell>
                    </>
                ) : (
                    <FailCell item={item} colSpan={2} />
                )}
            </TableRow>
            <AdvisoriesRow
                item={item}
                colSpan={2}
                className={clsx(classes.root, "row", "bottom")}
            />
        </>
    );
}

export { MotHistoryRow };
