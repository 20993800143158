import React from "react";
import { TextField, TextFieldProps, useControlled } from "@material-ui/core";

type UpperCaseTextFieldProps = Omit<TextFieldProps, "onChange"> & {
    onChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        value?: string
    ) => void;
};

/**
 * Wrapper around material {@link TextField} which upper-cases the input.
 *
 * @param {UpperCaseTextFieldProps} props The component props.
 * @returns {JSX.Element} The component element.
 */
function UpperCaseTextField(props: UpperCaseTextFieldProps) {
    const {
        value: valueProps,
        defaultValue: defaultValueProps,
        onChange: onChangeProps,
        ...rest
    } = props;
    const [value, setValue] = useControlled({
        controlled: valueProps,
        default: defaultValueProps,
        name: rest.name ?? "UpperCaseTextField",
    });

    const onChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { value } = event.target;
            const upperCaseValue = value?.toUpperCase();

            event.target.value = upperCaseValue;

            setValue(upperCaseValue);
            onChangeProps && onChangeProps(event, upperCaseValue);
        },
        [onChangeProps, setValue]
    );
    return <TextField value={value} onChange={onChange} {...rest} />;
}

export { UpperCaseTextField };
export type { UpperCaseTextFieldProps };
