import { combineReducers } from "redux";
import globalReducer from "./global/globalSlice";
import userReducer from "./user/userSlice";
import userSubscriptionReducer from "./user/subscription/subscriptionsSlice";
import partnerReducer from "./partner/partnerSlice";
import vehiclesReducer from "./vehicle/vehiclesSlice";
import vehicleReducer from "./vehicle/vehicleSlice";
import locationReducer from "./location/locationSlice";
import vehicleNotificationsReducer from "./vehicle/notifications/slice";
import vehicleDetailsReducer from "./vehicle/details/slice";
import vehicleStatusReducer from "./vehicle/vehicle-status/slice";
import vehicleRenewalDatesReducer from "./vehicle/renewal-dates/slice";
import vehicleLocationWeatherReducer from "./vehicle/weather/slice";
import vehicleDrivingStatisticsReducer from "./vehicle/driving-statistics/slice";
import vehicleGaragePartnerReducer from "./vehicle/garage-partner/slice";
import vehicleValuationReducer from "./vehicle/valuation/slice";
import vehicleCarbonEmissionsReducer from "./vehicle/carbon-emissions/slice";

// TODO
// in future we would want to decouple this into a module based approach. Were each pod is a module
// and it can register into the store if it has been intialised.
const reducers = {
    global: globalReducer,
    user: userReducer,
    userSubscription: userSubscriptionReducer,
    partner: partnerReducer,
    allVehicles: vehiclesReducer,
    currentVehicle: vehicleReducer,
    currentLocation: locationReducer,
    currentVehicleDetails: vehicleDetailsReducer,
    currentVehicleStatus: vehicleStatusReducer,
    currentVehicleRenewalDates: vehicleRenewalDatesReducer,
    currentVehicleLocationWeather: vehicleLocationWeatherReducer,
    currentVehicleDrivingStatistics: vehicleDrivingStatisticsReducer,
    currentVehicleNotifications: vehicleNotificationsReducer,
    currentVehicleGaragePartner: vehicleGaragePartnerReducer,
    currentVehicleValuation: vehicleValuationReducer,
    currentVehicleCarbonEmissions: vehicleCarbonEmissionsReducer,
};

const rootReducer = combineReducers(reducers);

export { rootReducer };
