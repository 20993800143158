import React from "react";
import { Grid, SwitchProps as MuiSwitchProps, Typography, useControlled } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SwitchBase } from "../SwitchBase";
import clsx from "clsx";

type ToggleProps<Value1, Value2> = {
    leftLabel?: string;
    rightLabel?: string;
    name?: string;
    ariaLabel?: string;
    values: [Value1, Value2];
    value?: Value1 | Value2;
    onChange?: (event: React.ChangeEvent<any>, value: Value1 | Value2) => void;
    defaultValue?: Value1 | Value2;
};

const useStyles = makeStyles((theme) => ({
    textLeft: {
        marginRight: theme.spacing(1),
    },
    textRight: {
        marginLeft: theme.spacing(1),
    },
    textDisabled: {
        color: theme.palette.text.disabled,
    },
}));

function Toggle<Value1, Value2>(props: ToggleProps<Value1, Value2>) {
    const styles = useStyles();
    const { leftLabel, rightLabel, ariaLabel, name, values, onChange, ...rest } = props;
    let { defaultValue, value } = rest;

    if (defaultValue) {
        if (!values.includes(defaultValue)) {
            console.warn("invalid defaultValue supplied");
            defaultValue = undefined;
        }
    }

    if (value) {
        if (!values.includes(value)) {
            console.warn("invalid value supplied");
            value = undefined;
        }
    }

    if (!defaultValue && !value) {
        throw new Error("Either defaultValue or value is required");
    }

    const [toggleValue, setToggleValue] = useControlled({
        controlled: value,
        default: defaultValue,
        name: name ?? "Toggle",
    });

    let inputProps: React.InputHTMLAttributes<HTMLInputElement> | undefined;
    if (ariaLabel) {
        inputProps = { "aria-label": ariaLabel };
    }

    const onChangeSwitch = React.useCallback<NonNullable<MuiSwitchProps["onChange"]>>(
        (e, checked) => {
            const newToggleValue = checked ? values[1] : values[0];

            setToggleValue(newToggleValue);
            onChange && onChange(e, newToggleValue);
        },
        [onChange, setToggleValue, values]
    );

    const checked = values.indexOf(toggleValue) === 1;
    return (
        <Grid container direction="row" alignItems="center">
            {leftLabel && (
                <Typography
                    variant="body2"
                    className={clsx(styles.textLeft, checked ? styles.textDisabled : undefined)}
                >
                    {leftLabel}
                </Typography>
            )}
            <SwitchBase
                checked={checked}
                onChange={onChangeSwitch}
                name={name}
                color="default"
                variant="toggle"
                inputProps={inputProps}
            />
            {rightLabel && (
                <Typography
                    variant="body2"
                    className={clsx(styles.textRight, !checked ? styles.textDisabled : undefined)}
                >
                    {rightLabel}
                </Typography>
            )}
        </Grid>
    );
}

export { Toggle };
export type { ToggleProps };
