import React, { MouseEventHandler, ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        backgroundColor: "transparent",
        border: "none",
        margin: "0",
        padding: "0",
        cursor: "pointer",
        outline: "none",
        "text-align": "inherit",
    },
});

type TransparentButtonProps = {
    disabled?: boolean;
    className?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    children: ReactNode;
} & Pick<
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    "aria-label" | "aria-labelledby"
>;

/**
 * Transparent button is an internal component used to wrap content in a button. With option for
 * disabling the content.
 *
 * @param props
 * @returns
 */
const TransparentButton = ({
    className: classNameProps,
    disabled,
    onClick,
    children,
    ...rest
}: TransparentButtonProps) => {
    const classes = useStyles();
    const className = classNameProps ? `${classNameProps} ${classes.root}` : classes.root;
    return (
        <button className={className} disabled={disabled} onClick={onClick} {...rest}>
            {children}
        </button>
    );
};

export { TransparentButton };
export type { TransparentButtonProps };
