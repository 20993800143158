import { getBlobObjectUrl } from "@pedal/infrastructure";
import { getPartnerBannerImageUrl } from "./queries";

function getPartnerBannerBlobUrl(partnerId: string) {
    return getBlobObjectUrl(`partner_${partnerId}_banner`, async () => {
        const res = await getPartnerBannerImageUrl(partnerId);
        return res?.images.bannerUrl ?? undefined;
    });
}

export { getPartnerBannerBlobUrl };
