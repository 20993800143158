import { logger } from "@pedal/infrastructure";
import { AppOnlyEventTypes } from "./eventTypes";
import { EventTypes } from "../eventTypes";

function sendMessageHandler(eventType: EventTypes | AppOnlyEventTypes, data: unknown) {
    const webView = window.ReactNativeWebView;
    if (!webView) {
        logger.warn("Webview not available");
        return;
    }

    webView.postMessage(
        JSON.stringify({
            eventType: eventType.toString(),
            data,
        })
    );
}

function createSendMessageHandler(eventType: EventTypes | AppOnlyEventTypes) {
    return sendMessageHandler.bind(window, eventType);
}

export { sendMessageHandler, createSendMessageHandler };
