import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

const GarageDetailsShimmer = () => {
    const styles = useStyles();
    return (
        <Grid container direction="column">
            <Grid item>
                <Typography variant="h6">
                    <Skeleton className={styles.name} />
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="body2">
                    <Skeleton />
                </Typography>
            </Grid>
            <Grid item>
                <Skeleton className={styles.address} />
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles({
    name: {
        width: "60%",
    },
    address: {
        height: "80px",
    },
});

export { GarageDetailsShimmer };
