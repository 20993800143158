import { graphQL } from "@pedal/pedal-api";
import { Nullable } from "@pedal/utility";
import { VehicleRenewalDates } from "api";

type VehicleRenewalDatesResponse = Omit<VehicleRenewalDates, "camBeltNextMiles"> &
    Nullable<Pick<VehicleRenewalDates, "camBeltNextMiles">>;

type Response = {
    isElectricVehicle: boolean;
    renewalDates: VehicleRenewalDatesResponse;
};

export async function getVehicleRenewalDates(vehicleId: string) {
    const res = await graphQL.query<"vehicle", Response>({
        query: `
vehicle (id: "${vehicleId}") {
    isElectricVehicle
    renewalDates {
        vedCurrentlyValid
        vedExpiryDate
        motSornInPlace
        motExpiryDate
        insuranceExpiryDate
        camBeltNextMiles
    }
}`,
    });

    return res.data.vehicle;
}
