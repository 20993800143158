import { createSlice, createAsyncThunk, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import { Draft } from "immer";
import { raiseEvent, logger } from "@pedal/infrastructure";
import { VehicleCapabilities, VehicleState } from "./vehicleTypes";
import { Vehicle, VehicleSensor, VehicleSubscription } from "api";
import { EventTypes } from "common";
import { getPartnerById } from "../partner/partnerSlice";
import {
    setLocationFromVehicle,
    setConnectedDeviceType,
    resetDeviceType,
} from "../location/locationSlice";
import { getCurrentVehicleNotifications } from "./notifications/slice";
import { getVehicle } from "./queries";
import { removeSensor as removeSensorApi } from "./mutations";
import { Action } from "../types";

const subscribe =
    (vehicleId: string): Action =>
    (_, __, invoke) => {
        invoke("subscribe", vehicleId).catch((err) => logger.error(err));
    };

const unsubscribe =
    (vehicleId: string): Action =>
    (_, __, invoke) => {
        invoke("unsubscribe", vehicleId).catch((err) => logger.error(err));
    };

const getVehicleAndPartnerByVehicleId = createAsyncThunk(
    "vehicle/getVehicleAndPartnerByVehicleId",
    async function (id: string, thunkApi) {
        const vehicle = await getVehicle(id);

        if (!vehicle) {
            return { vehicle: null };
        }

        const { location, ...rest } = vehicle;

        await thunkApi.dispatch(getPartnerById(vehicle.partnerId));

        thunkApi.dispatch(setLocationFromVehicle(location));

        return processVehicle(rest, thunkApi.dispatch);
    }
);

const getVehicleByVehicleId = createAsyncThunk(
    "vehicle/getVehicleByVehicleId",
    async function (id: string, thunkApi) {
        const vehicle = await getVehicle(id);
        if (!vehicle) {
            return { vehicle: null };
        }

        const { location, ...rest } = vehicle;

        thunkApi.dispatch(setLocationFromVehicle(location));

        return processVehicle(rest, thunkApi.dispatch);
    }
);

const setLinkedVehicle = createAsyncThunk(
    "vehicle/setLinkedVehicle",
    function (id: string, thunkApi) {
        // get the notifications again for the current vehicle
        thunkApi.dispatch(getCurrentVehicleNotifications());
    }
);

const removeSensor = createAsyncThunk("vehicle/removeSensor", function (id: string, thunkApi) {
    return removeSensorApi(id);
});

function processVehicle(vehicle: Vehicle, dispatch: Dispatch) {
    if (!vehicle.pedalConnect?.type) {
        vehicle.pedalConnect = null;
    }

    if (vehicle.pedalConnect?.type) {
        dispatch(setConnectedDeviceType({ deviceType: vehicle.pedalConnect.type }));
    } else {
        dispatch(resetDeviceType());
    }

    return { vehicle };
}

function calculateCapabilities(vehicle: Vehicle): VehicleCapabilities {
    const isNotLite = vehicle.subscription?.plan !== "PEDAL_LITE";
    return {
        supportsLocation: isNotLite,
        supportsWeather: isNotLite,
        supportsGarageAssociation: isNotLite,
        supportsValuations: isNotLite,
    };
}

function onVehicleLoad(
    state: Draft<VehicleState>,
    { payload: { vehicle } }: PayloadAction<{ vehicle: Vehicle | null }>
) {
    state.loading = false;

    // if we have no vehicle, no state change should occur
    if (!vehicle) {
        return state;
    }

    state.vehicle = {
        ...vehicle,
        capabilities: calculateCapabilities(vehicle),
    };
}

const initialState: VehicleState = {
    loading: false,
};
const vehicleSlice = createSlice({
    name: "vehicle",
    initialState: initialState,
    extraReducers: function (builder) {
        builder.addCase(getVehicleAndPartnerByVehicleId.pending, function (state) {
            state.loading = true;
        });
        builder.addCase(getVehicleAndPartnerByVehicleId.rejected, function (state) {
            state.loading = false;
        });
        builder.addCase(getVehicleAndPartnerByVehicleId.fulfilled, onVehicleLoad);
        builder.addCase(getVehicleByVehicleId.pending, function (state) {
            state.loading = true;
        });
        builder.addCase(getVehicleByVehicleId.rejected, function (state) {
            state.loading = false;
        });
        builder.addCase(getVehicleByVehicleId.fulfilled, onVehicleLoad);
        builder.addCase(removeSensor.fulfilled, (state) => {
            if (!state.vehicle) {
                return state;
            }

            state.vehicle.pedalConnect = null;
        });
    },
    reducers: {
        setVehicle: onVehicleLoad,
        resetVehicle: function () {
            return initialState;
        },
        updateVehicleSubscription: function (
            state,
            action: PayloadAction<VehicleSubscription | null>
        ) {
            if (!state.vehicle || !action.payload || state.vehicle.subscription.plan === "CUSTOM") {
                return state;
            }

            state.vehicle.subscription = action.payload;
            state.vehicle.capabilities = calculateCapabilities(state.vehicle);
        },
        updateVehicleSensor: function (state, action: PayloadAction<VehicleSensor | null>) {
            if (!state.vehicle || !action.payload) {
                return state;
            }

            state.vehicle.pedalConnect = {
                type: "SENSOR",
                sensor: action.payload,
            };
        },
        updateVehicleUser: function (
            state,
            action: PayloadAction<{
                safeWord?: string | null;
            }>
        ) {
            if (!state.vehicle) {
                return state;
            }

            if (action.payload.safeWord) {
                state.vehicle.user.safeWord = action.payload.safeWord;
            } else {
                state.vehicle.user.safeWord = null;
            }
        },
        vehicleConfigured: function (state) {
            if (!state.vehicle) {
                return state;
            }

            state.vehicle.data.isConfigured = true;
        },
    },
});

const eventHandlers = {
    [getVehicleAndPartnerByVehicleId.fulfilled.type]: function ({
        vehicle,
    }: {
        vehicle: Vehicle | null;
    }) {
        if (vehicle !== null) {
            raiseEvent(EventTypes.vehicleLoaded, {
                vehicleId: vehicle.id,
                imageUrl: vehicle.imageUrl,
            });
        }
    },
    [getVehicleByVehicleId.fulfilled.type]: function ({ vehicle }: { vehicle: Vehicle | null }) {
        if (vehicle !== null) {
            raiseEvent(EventTypes.vehicleLoaded, {
                vehicleId: vehicle.id,
                imageUrl: vehicle.imageUrl,
            });
        }
    },
};

export {
    getVehicleAndPartnerByVehicleId,
    getVehicleByVehicleId,
    setLinkedVehicle,
    subscribe,
    unsubscribe,
    removeSensor,
};
export const {
    setVehicle,
    resetVehicle,
    updateVehicleSubscription,
    updateVehicleSensor,
    updateVehicleUser,
    vehicleConfigured,
} = vehicleSlice.actions;
export { eventHandlers };

export default vehicleSlice.reducer;
