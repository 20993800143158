import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { VehicleDataState } from "../vehicleTypes";
import { getVehicleValuation as query } from "./queries/getVehicleValuation";
import { VehicleValuation } from "api";

type ValuationState = VehicleDataState<VehicleValuation>;

const initialState: ValuationState = {
    data: undefined,
    loading: false,
};

const getVehicleValuation = createAsyncThunk(
    "valuation/getVehicleValuation",
    async ({ vehicleId }: { vehicleId: string; asyncUpdate?: boolean }) => {
        const response = await query(vehicleId);
        if (!response?.valuation) {
            return undefined;
        }

        return response.valuation;
    }
);

const valuationSlice = createSlice({
    name: "valuation",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(getVehicleValuation.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getVehicleValuation.rejected, (state) => {
            state.data = undefined;
            state.loading = false;
        });
        builder.addCase(getVehicleValuation.fulfilled, (state, action) => {
            state.loading = false;

            if (!action.payload) {
                state.data = undefined;
                return;
            }

            state.data = action.payload;
        });
    },
    reducers: {},
});

export { getVehicleValuation };

export default valuationSlice.reducer;
