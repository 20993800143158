import { graphQL } from "@pedal/pedal-api";
import { Vehicle, DeviceLocation } from "api";

const VehicleFields = `id
vrm
manufacturer
range
imageUrl
dateFirstRegistered
userId
partnerId
pedalConnect {
    type
    sensor {
        id
        imei
        serialNumber
    }
}
location {
    longitude
    latitude
    formattedAddress
    display
    locationLastUpdated
    device {
        type
    }
}
data {
    isActivated
    isConfigured
    isSold
}
user {
    id
    displayName
    emailAddress
    mobileNumber
    dateJoined
    safeWord
}
subscription {
    plan
}
userPreferences`;

type VehicleResponse = Vehicle & { location: DeviceLocation | null };

export async function getVehicle(vehicleId: string) {
    const res = await graphQL.query<"vehicle", VehicleResponse>({
        query:
            `
vehicle (id: "${vehicleId}") {
    ` +
            VehicleFields +
            `
}`,
    });

    return res.data.vehicle;
}
