import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createInitialGlobalState, AppLogo } from "./globalTypes";
import { isHexLightColor } from "@pedal/styleguide";

// just give any default value, it will be set on app init
const initialState = createInitialGlobalState(false, "1.0.0");

const globalSlice = createSlice({
    name: "global",
    initialState: initialState,
    reducers: {
        setLogo(state, action: PayloadAction<AppLogo | undefined>) {
            if (!state) {
                return state;
            }

            state.appLogo = action.payload;
        },
        setTheme(state, action: PayloadAction<{ color: string } | undefined>) {
            if (!state) {
                return state;
            }

            if (!action.payload) {
                state.theme = {
                    color: undefined,
                    isLight: true,
                };
            } else {
                state.theme = {
                    color: action.payload.color,
                    isLight: isHexLightColor(action.payload.color) ?? true,
                };
            }
        },
    },
});

export const { setLogo, setTheme } = globalSlice.actions;

export default globalSlice.reducer;
