import { listenEvent, raiseEvent } from "@pedal/infrastructure";
import { EventTypes } from "../eventTypes";
import { Store } from "store";

function setupRequestVehicle({ getState }: Store) {
    listenEvent(EventTypes.requestVehicleDetails, () => {
        const vehicle = getState().currentVehicle.vehicle;
        if (!vehicle) {
            return;
        }

        raiseEvent(EventTypes.vehicleLoaded, {
            vehicleId: vehicle.id,
            userId: vehicle.userId,
            imageUrl: vehicle.imageUrl,
            data: vehicle.data,
            subscription: vehicle.subscription,
            capabilities: vehicle.capabilities,
        });
    });
}

export { setupRequestVehicle };
