import { useEffect, useRef } from "react";
import { AsyncThunkAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { useAppDispatch } from "./common";
import { RootState } from "../types";

type ThunkInput = { vehicleId: string } & Partial<Record<string, unknown>>;

export function useVehicleStore<TData, TDataOut>(
    selector: (rootState: RootState) => TDataOut,
    retriever: (input: ThunkInput) => AsyncThunkAction<TData | undefined, ThunkInput, {}>,
    vehicleId: string
) {
    const dispatch = useAppDispatch();
    const retrieverRef = useRef(retriever);

    // if retriever changes, update the reference
    useEffect(() => {
        retrieverRef.current = retriever;
    }, [retriever]);

    useEffect(() => {
        dispatch(retrieverRef.current({ vehicleId }));
    }, [vehicleId, dispatch]);

    const state = useSelector(selector);
    return state;
}
