import React, { ReactChild } from "react";
import { Grid, Collapse } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { ArrowUpIcon, ArrowDownIcon } from "../../icons";

const IconGridItem = styled(Grid)({
    "& > svg": {
        display: "block",
        cursor: "pointer",
    },
});

type Props = {
    disabled?: boolean;
    collapsed?: boolean;
    className?: string;
    children: {
        title: ReactChild;
        content: ReactChild;
    };
};

const CollapsableContent = (props: Props) => {
    const [collapsed, setCollapsed] = React.useState(props.collapsed || false);
    const onClick = () => {
        if (props.disabled !== true) {
            setCollapsed((prev) => !prev);
        }
    };

    return (
        <>
            <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
                className={props.className}
                onClick={onClick}
            >
                <Grid item>{props.children.title}</Grid>
                {props.disabled ? null : (
                    <IconGridItem item>
                        {collapsed ? <ArrowDownIcon /> : <ArrowUpIcon />}
                    </IconGridItem>
                )}
            </Grid>
            <Collapse in={!collapsed}>{props.children.content}</Collapse>
        </>
    );
};

export { CollapsableContent };
