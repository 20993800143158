import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { OptionsIcon } from "./icons";
import clsx from "clsx";
import { constants } from "../constants";

const useStyles = makeStyles((theme) => ({
    container: {
        width: constants.defaultSize,
        height: constants.defaultSize,
        display: "block",
        border: `2px solid ${theme.palette.primary.main}`,
        padding: "3px",
        borderRadius: constants.defaultSize / 2,
    },
    icon: {
        display: "block",
        width: "100%",
        height: "100%",
    },
}));

type OptionsProps = { className?: string; label?: string };

function Options(props: OptionsProps) {
    const styles = useStyles(props);
    const { className, label } = props;
    return (
        <span className={clsx(styles.container, className)}>
            <OptionsIcon aria-label={label} className={styles.icon} />
        </span>
    );
}

export { Options };
