import React from "react";
import { Map as MapGL, MapRef as MapGLRef } from "react-map-gl";
import * as turf from "@turf/turf";
import { LngLatBoundsLike } from "mapbox-gl";
import { mapConstants } from "./constants";
import "mapbox-gl/dist/mapbox-gl.css";
import "./map.css";

const mapboxApiKey = process.env.REACT_APP_MAPBOX_API_KEY || "";

type MapProps = {
    id?: string;
    longitude: number;
    latitude: number;
    mapRadius?: number;
    interactive?: boolean;
    reuseMaps?: boolean;
    children?: React.ReactNode;
};

type MapRef = Pick<MapGLRef, "resize" | "flyTo" | "getBounds" | "getZoom" | "once" | "on" | "off">;

function reforwardRef<T>(ref: React.ForwardedRef<T>, value: T) {
    if (typeof ref === "function") {
        ref(value);
    } else if (ref) {
        ref.current = value;
    }
}

/**
 * Map component. Displays the passed-in coordinates on a map.
 *
 * Also see guide/docs: http://alex3165.github.io/react-mapbox-gl/
 * @param {LocationProps} props The component props.
 * @returns The location component.
 */
const Map = React.forwardRef<MapRef, MapProps>((props, ref) => {
    const {
        id,
        longitude,
        latitude,
        mapRadius = mapConstants.bboxRadius,
        interactive = false,
        reuseMaps = false,
        children,
    } = props;

    const [viewState, setViewState] = React.useState({
        longitude,
        latitude,
        zoom: mapConstants.zoom,
    });

    const maxBounds = React.useMemo(() => {
        const point = turf.point([longitude, latitude]);
        const buffered = turf.buffer(point, mapRadius, { units: "kilometers" });
        const bbox = turf.bbox(buffered);
        return bbox as LngLatBoundsLike;
    }, [longitude, latitude, mapRadius]);

    const mapRef = React.useRef<MapGLRef | null>(null);

    React.useEffect(() => {
        reforwardRef(ref, mapRef.current);
    });

    React.useEffect(() => {
        return () => reforwardRef(ref, null);
    }, [ref]);

    const onMapLoad = React.useCallback(() => {
        if (mapRef.current === null) {
            return;
        }

        mapRef.current.resize();
    }, []);

    return (
        <MapGL
            id={id}
            mapboxAccessToken={mapboxApiKey}
            mapStyle="mapbox://styles/pedalglobal/cjhz53qah2pdp2slim0exvppw"
            interactive={interactive}
            reuseMaps={reuseMaps}
            {...viewState}
            onMove={(evt) => setViewState(evt.viewState)}
            style={{ height: "100%", width: "100%" }}
            ref={mapRef}
            onLoad={onMapLoad}
            minZoom={mapConstants.minZoom}
            maxZoom={mapConstants.maxZoom}
            maxBounds={maxBounds}
        >
            {children}
        </MapGL>
    );
});

export { Map };
export type { MapProps, MapRef };
