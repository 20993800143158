import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "@pedal/styleguide";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    container: function ({ state }: { state: DataBoxProps["state"] }) {
        return {
            borderWidth: state === "active" ? 2 : 1,
            borderStyle: "solid",
            borderColor: state === "active" ? theme.palette.primary.main : colors.platinumExtraDark,
            borderRadius: theme.shape.borderRadius * 4,
            padding: theme.spacing(2),
        };
    },
}));

type DataBoxProps = {
    state?: "active";
    children?: React.ReactNode;
    className?: string;
    role?: string;
    onClick?: () => void;
};

function DataBox({ children, state, className, role, onClick }: DataBoxProps) {
    const styles = useStyles({ state });

    return (
        <div className={clsx(styles.container, className)} role={role} onClick={onClick}>
            {children}
        </div>
    );
}

export { DataBox };
