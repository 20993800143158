import React from "react";
import { Typography, Grid, IconButton, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CloseFilledIcon, OptionsFilledIcon } from "../../icons";
import { colors } from "@pedal/styleguide";

type VehicleDataToggleBoxProps = {
    vrm: string;
    display: string;
    expanded?: boolean;
    children: React.ReactNode;
    actions?: React.ReactNode;
    onToggle?: (show: boolean) => void;
};

function VehicleDataToggleBox({
    display,
    vrm,
    expanded = false,
    children,
    actions,
    onToggle,
}: VehicleDataToggleBoxProps) {
    const [showExtended, setShowExtended] = React.useState(expanded);
    const styles = useStyles({ selected: showExtended });

    const toggleExtension = () => {
        setShowExtended((s) => !s);
        if (onToggle) {
            onToggle(!showExtended);
        }
    };

    return (
        <div className={styles.container}>
            <div>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h6" component="h5" className={styles.title}>
                            {display}
                        </Typography>
                        <Typography variant="body2">{vrm}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={toggleExtension}
                            size="small"
                            className={styles.button}
                        >
                            {showExtended ? <CloseFilledIcon /> : <OptionsFilledIcon />}
                        </IconButton>
                    </Grid>
                </Grid>
                {showExtended && (
                    <>
                        <div className={styles.extension}>{children}</div>
                        {actions && (
                            <>
                                <Divider className={styles.divider} />
                                {actions}
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: function ({ selected }: { selected: boolean }) {
            return selected ? theme.palette.primary.main : colors.platinumExtraDark;
        },
        borderRadius: theme.shape.borderRadius * 4,
        "&>div": {
            padding: theme.spacing(2),
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: theme.shape.borderRadius * 4 - 2, // double border widths
            borderColor: function ({ selected }: { selected: boolean }) {
                return selected ? theme.palette.primary.main : "transparent";
            },
        },
    },
    title: {
        lineHeight: 1.1,
        textTransform: "capitalize",
    },
    button: {
        "& svg": {
            width: "28px",
            height: "auto",
            display: "block",
        },
    },
    extension: {
        marginTop: theme.spacing(1),
    },
    divider: {
        margin: theme.spacing(2, -2),
    },
}));

export { VehicleDataToggleBox };
