import { memoize } from "lodash";

type AppOptions = {
    mode: "app";
    version: string;
} & Record<string, unknown>;

const parseAppOptions: () => AppOptions | undefined =
    typeof window === "undefined"
        ? () => {
              return undefined;
          }
        : () => {
              let optionsString: string | undefined;

              // first, check for app options string injected in
              if (window.appOptions) {
                  optionsString = window.appOptions;

                  // add it to the cookie too for redirects (e.g. login)
                  document.cookie = `pedal=${encodeURIComponent(optionsString)};`;
              } else {
                  // else check the cookie
                  const match = /pedal=(?<value>[^;]+)/g.exec(document.cookie);
                  if (match && match.groups) {
                      optionsString = decodeURIComponent(match.groups["value"].toString());
                  }
              }

              if (!optionsString) {
                  return;
              }

              return Object.fromEntries(optionsString.split("&").map((s) => s.split("=")));
          };

const getAppOptions = memoize(
    () => {
        const parsedOptions = parseAppOptions();
        if (!parsedOptions) {
            return;
        }

        let versionString: string | undefined = parsedOptions.version;
        let versionParts = versionString?.split(".");
        if (!versionParts || versionParts.length < 3) {
            versionString = "1.1.1"; // default version
            versionParts = ["1", "1", "1"];
        }

        const options = {
            mode: parsedOptions.mode,
            isAppMode: true,
            user: window.user,
            device: window.device,
            startupArgs: window.startupArgs,
            insets: window.insets,
            version: {
                string: versionString,
                major: ~~versionParts[0],
                minor: ~~versionParts[1],
                patch: ~~versionParts[2],
            },
            versionIsAtleast: function (major: number, minor: number, patch: number) {
                if (options.version.major !== major) {
                    return options.version.major > major;
                }

                if (options.version.minor !== minor) {
                    return options.version.minor > minor;
                }

                return options.version.patch >= patch;
            },
            versionIsLess: function (major: number, minor: number, patch: number) {
                return !this.versionIsAtleast(major, minor, patch);
            },
        };

        return options;
    },
    () => "appOptions"
);

export { getAppOptions };
