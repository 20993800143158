import { getAppOptions } from "./getAppOptions";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    styles: function ({ variant, type }: { variant: Variant; type: Type }) {
        const insets = getAppOptions()?.insets;
        if (!insets) {
            return {};
        }

        const obj = {} as any;
        if (variant === "top" || variant === "topBottom") {
            obj[type + "Top"] = `${insets.top}px !important`;
        }
        if (variant === "bottom" || variant === "topBottom") {
            obj[type + "Bottom"] = `${insets.bottom}px !important`;
        }

        return obj;
    },
});

const usePositioningStyles = makeStyles({
    styles: function ({ variant, base }: { variant: Variant; base: number }) {
        const insets = getAppOptions()?.insets;
        if (!insets) {
            return {};
        }

        const obj = {} as any;
        if (variant === "top" || variant === "topBottom") {
            obj.top = `${insets.top + base}px !important`;
        }
        if (variant === "bottom" || variant === "topBottom") {
            obj.bottom = `${insets.bottom + base}px !important`;
        }

        return obj;
    },
});

type Variant = "top" | "bottom" | "topBottom";
type Type = "padding" | "margin";

function useInsetStyles(variant: Variant, type: Type = "padding") {
    const styles = useStyles({ variant, type });
    return styles.styles;
}

function useInsetPositioningStyles(variant: Variant, base = 0) {
    const styles = usePositioningStyles({ variant, base });
    return styles.styles;
}

export { useInsetStyles, useInsetPositioningStyles };
