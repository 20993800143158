import React from "react";
import { Grid } from "@material-ui/core";
import { TextButton } from "../../button";

type Action = () => Promise<unknown> | void;

type NotificationAction = {
    title: string;
    action: Action;
    executing?: boolean;
};

type Props = {
    actions: Array<NotificationAction | undefined>;
};

function NotificationActions({ actions }: Props) {
    return (
        <Grid container direction="row" justify="space-between" wrap="nowrap">
            {actions.map((a, i) => (
                <Grid item key={i}>
                    {a && (
                        <TextButton variant="subtitle2" onClick={a.action} loading={a.executing}>
                            {a.title}
                        </TextButton>
                    )}
                </Grid>
            ))}
        </Grid>
    );
}

export { NotificationActions };
export type { NotificationAction };
