import React from "react";
import { IconButton, IconButtonProps } from "@material-ui/core";
import { AnalyticsTrackProps, useAnalyticsProps } from "@pedal/component-library";
import { raiseEvent } from "@pedal/infrastructure";

type Props = {
    ariaLabel?: string;
    icon: React.ReactElement;
    edge?: IconButtonProps["edge"];
} & (WithType | WithOnClick) &
    AnalyticsTrackProps;

type WithType = {
    type: string;
};

type WithOnClick = {
    onClick: () => void;
};

function hasType(props: WithType | WithOnClick): props is WithType {
    return (props as WithType).type !== undefined;
}

const HeaderMenuButton = (props: Props) => {
    const { ariaLabel, icon, edge, ...analytics } = props;
    const analyticsProps = useAnalyticsProps(analytics);
    const onClick = hasType(props) ? () => raiseEvent(props.type) : props.onClick;

    return (
        <IconButton aria-label={ariaLabel} onClick={onClick} edge={edge} {...analyticsProps}>
            {icon}
        </IconButton>
    );
};

export { HeaderMenuButton };
