import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Icon } from "./menu.svg";
import { constants } from "../constants";

const useStyles = makeStyles({
    icon: {
        width: constants.defaultSize,
        height: constants.defaultSize,
        display: "block",
    },
});

function Menu() {
    const style = useStyles();
    return <Icon className={style.icon} />;
}

export { Menu };
