import { TablePagination } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const Pagination = styled(TablePagination)((obj) => {
    return {
        backgroundColor: "#f1f1f1",
        border: "none",
        "& > .MuiToolbar-root": {
            paddingTop: obj.theme.spacing(2),
            paddingBottom: obj.theme.spacing(2),
            paddingLeft: 0,
            paddingRight: 0,
        },
        "& .MuiTablePagination-spacer": {
            display: "none",
        },
        "& .MuiTablePagination-caption": {
            flex: "1 1 100%",
        },
    };
});

export { Pagination };
