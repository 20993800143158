import React from "react";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

type MapPanelProps = {
    children: React.ReactNode;
    className?: string;
};

const useStyles = makeStyles((theme) => ({
    container: {
        borderRadius: theme.spacing(2),
        boxSizing: "border-box",
        padding: theme.spacing(1, 2),
    },
}));

function MapPanel(props: MapPanelProps) {
    const styles = useStyles(props);
    const { children, className } = props;

    return <Paper className={clsx(styles.container, className)}>{children}</Paper>;
}

export { MapPanel };
export type { MapPanelProps };
