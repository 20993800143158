import { useEffect, useState, useRef, Dispatch, SetStateAction, useCallback } from "react";
//import { logger } from "utils";

type StateData<TData> = {
    data?: TData;
    isLoading: boolean;
};

type Retriever<TReturn, TArg extends unknown[]> = (
    ...args: TArg
) => Promise<TReturn | null | undefined>;

async function stateSetter<TReturn, TArg extends unknown[]>(
    retriever: Retriever<TReturn, TArg>,
    setState: Dispatch<SetStateAction<StateData<TReturn>>>,
    isMounted: (() => boolean) | undefined,
    args: TArg
) {
    setState((s) => ({
        data: s.data,
        isLoading: true,
    }));

    let data: TReturn | null | undefined;
    try {
        data = await retriever(...args);
    } catch (e) {
        //logger.error(e);
        data = null;
    }

    // if the component unmounted while retrieving data, then exit
    if (isMounted && !isMounted()) {
        return;
    }

    if (data === null) {
        setState({
            isLoading: false,
        });
        return;
    }

    setState({
        isLoading: false,
        data: data,
    });
}

function useData<TReturn, TArg extends unknown[]>(
    retriever: Retriever<TReturn, TArg>,
    ...args: TArg
) {
    const [state, setState] = useState<StateData<TReturn>>({
        isLoading: true,
    });

    const retrieverRef = useRef(retriever);

    // if retriever changes, update the reference
    useEffect(() => {
        retrieverRef.current = retriever;
    }, [retriever]);

    useEffect(() => {
        let isMounted = true;
        stateSetter(retrieverRef.current, setState, () => isMounted, args);

        return () => {
            isMounted = false;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, args);

    return state;
}

function useUpdatableData<TReturn, TArg extends unknown[]>(retriever: Retriever<TReturn, TArg>) {
    const [state, setState] = useState<StateData<TReturn>>({
        isLoading: true,
    });

    const retrieverRef = useRef(retriever);

    // if retriever changes, update the reference
    useEffect(() => {
        retrieverRef.current = retriever;
    }, [retriever]);

    const update = useCallback(function (...args: TArg) {
        return stateSetter(retrieverRef.current, setState, undefined, args);
    }, []);

    return {
        ...state,
        update,
    };
}

export { useData, useUpdatableData };
