import React from "react";
import { Button, ButtonProps, CircularProgress } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const StyledButton = styled(Button)({
    "& .label": {
        flex: 1,
    },
    "& .loader": {
        width: "28px",
        height: "28px",
        position: "absolute",
        margin: "0 auto",
        left: 0,
        right: 0,
    },
});

type ActionButtonProps = { text: string; loading?: boolean } & Omit<ButtonProps, "children">;

const ActionButton = (props: ActionButtonProps) => {
    const { text, loading, variant = "contained", color = "primary", ...rest } = props;
    return (
        <StyledButton variant={variant} color={color} {...rest}>
            <span className="label">{text}</span>
            {props.loading && <CircularProgress color="primary" size={28} className="loader" />}
        </StyledButton>
    );
};

export { ActionButton };
export type { ActionButtonProps };
