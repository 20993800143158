import { graphQL } from "@pedal/pedal-api";

type ServiceProvider = {
    name: string;
    primaryColor: string | null;
    borderColor: string | null;
    bannerUrl: string;
};

async function getServiceProviderDetails(serviceProviderId: string) {
    const res = await graphQL.query<"serviceProvider", ServiceProvider | null>({
        query: `
serviceProvider (id: "${serviceProviderId}") {
    name
    primaryColor
    borderColor
    bannerUrl
}`,
    });

    return res.data.serviceProvider;
}

export { getServiceProviderDetails };
export type { ServiceProvider };
