import { GraphQLResponse, GraphQLRequest } from "../types";
import { postJson } from "./restApiService";

export async function query<T extends string, D>(request: GraphQLRequest | string) {
    let { query, variables } = isGraphQLRequest(request)
        ? request
        : { query: request, variables: undefined };
    query =
        `query {
    ` +
        query +
        `
}`;

    return await sendCore<GraphQLResponse<T, D>>({
        query,
        variables,
    });
}

export async function sendCore<T>(request: GraphQLRequest) {
    const res = await postJson<T>(`/graphql`, request);
    return res;
}

function isGraphQLRequest(r: GraphQLRequest | string): r is GraphQLRequest {
    return (r as GraphQLRequest).query !== undefined;
}
