import { graphQL } from "@pedal/pedal-api";
import { DrivingStatistics, TripHistory } from "./apiTypes";

type VehicleDrivingStatistics = {
    drivingStatistics: DrivingStatistics | null;
    tripHistory: TripHistory | null;
};
export async function getDrivingStatistics(vehicleId: string) {
    const res = await graphQL.query<"vehicle", VehicleDrivingStatistics>({
        query: `
vehicle (id: "${vehicleId}") {
    drivingStatistics {
        ecoScore
        distanceInMiles
        dateStart
        drivingScores {
            accelerationScore
            brakingScore
            corneringScore
        }
    }
    tripHistory {
        currentMonth {
            distanceInMiles
        }
        previousMonth {
            distanceInMiles
        }
    }
}`,
    });

    return res;
}
