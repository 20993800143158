import {
    withCallbacks,
    signalMiddleware,
    LogLevel,
    HttpTransportType,
    HubConnectionBuilder,
    HubConnection,
} from "redux-signalr";
import { logger } from "@pedal/infrastructure";
import { auth, apiUri } from "@pedal/pedal-api";
import notificationCallbacks from "./vehicle/notifications/signalR";
import locationCallbacks from "./location/signalR";
import weatherCallbacks from "./vehicle/weather/signalR";
import drivingStatisticsCallbacks from "./vehicle/driving-statistics/signalR";
import carbonEmissionsCallbacks from "./vehicle/carbon-emissions/signalR";
import valuationCallbacks from "./vehicle/valuation/signalR";

const callbacks = withCallbacks();

// TODO
// Much like the combine reducers in index.ts, in future we would want to decouple this into
// a module based approach. Which can add to the callbacks as the modules are registered.
[
    notificationCallbacks,
    locationCallbacks,
    weatherCallbacks,
    drivingStatisticsCallbacks,
    carbonEmissionsCallbacks,
    valuationCallbacks,
].forEach((c) => {
    Object.entries(c).forEach(([key, value]) => {
        callbacks.add(key, value);
    });
});

const connectionBuilder = new HubConnectionBuilder()
    .configureLogging(LogLevel.Debug)
    .withAutomaticReconnect()
    .withUrl(`${apiUri}/_hubs/events`, {
        accessTokenFactory: () => auth.acquireToken(),
        skipNegotiation: false, // for signalr service this must be false
        transport: HttpTransportType.WebSockets,
    });

async function initConnection(connection: HubConnection): Promise<void> {
    try {
        await connection.start();
        logger.debug("SignalR connection started");
    } catch (e) {
        const error = e as { statusCode: number };
        if (error.statusCode === 401) {
            logger.debug("SignalR connection 401. Refreshing token and retrying");

            try {
                await connection.stop();
            } catch (e) {
                logger.debug("Failed to stop connection");
            }

            await auth.refreshToken();

            await initConnection(connection);

            return;
        }

        throw e;
    }
}

async function createSignalRMiddlware() {
    const connection = connectionBuilder.build();
    try {
        await initConnection(connection);
    } catch (e) {
        logger.error("SignalR connection error:", e);
    }

    return signalMiddleware({
        callbacks,
        connection,
        shouldConnectionStartImmediately: false,
    });
}

export default createSignalRMiddlware;
