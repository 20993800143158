import React from "react";
import { mapKeys, isEmpty } from "lodash";
import { AnalyticsTrackProps } from "./props";

const regex = /[A-Z]+(?![a-z])|[A-Z]/g;

const kebabize = (s: string) => s.replace(regex, ($, ofs) => (ofs ? "-" : "") + $.toLowerCase());

function convertToCustomProps(props: AnalyticsTrackProps) {
    if (isEmpty(props)) {
        return props;
    }

    return mapKeys(props, (_, k) => kebabize(k));
}

function useAnalyticsProps(props: AnalyticsTrackProps) {
    return React.useMemo(() => convertToCustomProps(props), [props]);
}

export { convertToCustomProps, useAnalyticsProps };
