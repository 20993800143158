import { listenEvent, raiseEvent } from "@pedal/infrastructure";
import { EventTypes } from "../eventTypes";
import * as vehiclesSlice from "store/vehicle/vehiclesSlice";
import * as vehicleSlice from "store/vehicle/vehicleSlice";
import { Store } from "store/types";

function setupVehicleRemovedListener({ dispatch, getState }: Store) {
    listenEvent(EventTypes.vehicleRemoved, ({ vehicleId }: { vehicleId: string }) => {
        if (!vehicleId) {
            return;
        }

        // close the menu if it's open
        raiseEvent("closeMenu");

        // remove the vehicle
        dispatch(vehiclesSlice.removeVehicle(vehicleId));

        // update the current vehicle if it was removed
        const currentVehicle = getState().currentVehicle;
        if (currentVehicle.vehicle?.id !== vehicleId) {
            return;
        }

        const currentUser = getState().user;
        const vehicles = getState().allVehicles.vehicles;
        const newCarId =
            vehicles.find((v) => v.id !== vehicleId && v.userId === currentUser.id)?.id ??
            vehicles[0]?.id;

        if (!newCarId) {
            return;
        }

        dispatch(vehiclesSlice.setCurrentVehicle(newCarId));
        dispatch(vehicleSlice.getVehicleAndPartnerByVehicleId(newCarId));
    });
}

export { setupVehicleRemovedListener };
