import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";

type ScoreBarProps = {
    score: number;
    className?: string;
};

function ScoreBar({ score, className }: ScoreBarProps) {
    const coloredParts = React.useMemo(() => Math.floor(score / 25), [score]);
    const styles = useStyles();

    return (
        <Grid className={className} container direction="row" spacing={1} wrap="nowrap">
            {[1, 2, 3, 4].map((part) => (
                <Grid item key={part} xs={3}>
                    <div
                        className={clsx(
                            styles.bar,
                            coloredParts >= part ? styles.filled : undefined
                        )}
                    ></div>
                </Grid>
            ))}
        </Grid>
    );
}

const useStyles = makeStyles((theme) =>
    createStyles({
        bar: {
            height: "4px",
            backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
            borderRadius: 8,
        },
        filled: {
            backgroundColor: theme.palette.primary.main,
        },
    })
);

export { ScoreBar };
