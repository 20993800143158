const wait = (ms: number) => new Promise<void>((r) => setTimeout(r, ms));

function retry<T>(
    delay: number,
    retries: number,
    fn: () => Promise<T>,
    predicate: (res: T) => boolean
): Promise<T> {
    return new Promise<T>((resolve, reject) =>
        fn()
            .then((res) => {
                if (predicate(res)) {
                    return resolve(res);
                }

                if (retries > 0) {
                    return wait(delay)
                        .then(() => retry(delay, retries - 1, fn, predicate))
                        .then(resolve)
                        .catch(reject);
                }

                return reject("Predicate failed");
            })
            .catch((e: any) => {
                if (retries > 0) {
                    return wait(delay)
                        .then(() => retry(delay, retries - 1, fn, predicate))
                        .then(resolve)
                        .catch(reject);
                }

                return reject(e);
            })
    );
}

const notConcurrent = <T, TArg extends unknown[]>(proc: (...args: TArg) => PromiseLike<T>) => {
    let inFlight: Promise<T> | false = false;

    return (...args: TArg) => {
        if (!inFlight) {
            inFlight = (async () => {
                try {
                    return await proc(...args);
                } finally {
                    inFlight = false;
                }
            })();
        }

        return inFlight;
    };
};

export { retry, notConcurrent };
