import React from "react";
import { Grid, SwitchProps as MuiSwitchProps, Typography, useControlled } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SwitchBase, SwitchVariant } from "../SwitchBase";
import clsx from "clsx";

type SwitchProps = {
    leftLabel?: string;
    rightLabel?: string;
    name?: string;
    ariaLabel?: string;
    value?: boolean;
    onChange?: (event: React.ChangeEvent<any>, value: boolean) => void;
    defaultValue?: boolean;
    variant?: SwitchVariant;
};

const useStyles = makeStyles((theme) => ({
    textLeft: {
        marginRight: theme.spacing(1),
        whiteSpace: "nowrap",
    },
    textRight: {
        marginLeft: theme.spacing(1),
        whiteSpace: "nowrap",
    },
    textDisabled: {
        color: theme.palette.text.disabled,
    },
}));

function Switch(props: SwitchProps) {
    const styles = useStyles();
    const {
        leftLabel,
        rightLabel,
        ariaLabel,
        name,
        onChange,
        defaultValue = false,
        value,
        variant,
    } = props;

    const [switchValue, setSwitchValue] = useControlled({
        controlled: value,
        default: defaultValue,
        name: name ?? "Switch",
    });

    let inputProps: React.InputHTMLAttributes<HTMLInputElement> | undefined;
    if (ariaLabel) {
        inputProps = { "aria-label": ariaLabel };
    }

    const onChangeSwitch = React.useCallback<NonNullable<MuiSwitchProps["onChange"]>>(
        (e, checked) => {
            setSwitchValue(checked);
            onChange && onChange(e, checked);
        },
        [onChange, setSwitchValue]
    );

    return (
        <Grid container direction="row" alignItems="center" wrap="nowrap">
            {leftLabel && (
                <Typography
                    variant="body2"
                    className={clsx(styles.textLeft, switchValue ? styles.textDisabled : undefined)}
                >
                    {leftLabel}
                </Typography>
            )}
            <SwitchBase
                checked={switchValue}
                onChange={onChangeSwitch}
                name={name}
                color="default"
                variant={variant}
                inputProps={inputProps}
            />
            {rightLabel && (
                <Typography
                    variant="body2"
                    className={clsx(
                        styles.textRight,
                        !switchValue ? styles.textDisabled : undefined
                    )}
                >
                    {rightLabel}
                </Typography>
            )}
        </Grid>
    );
}

export { Switch };
export type { SwitchProps };
