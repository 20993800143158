import { graphQL } from "@pedal/pedal-api";
import { UserSubscription, VehicleSubscription } from "api";

export type MeSubscriptionsResponse = {
    subscription: UserSubscription;
    vehicles: Array<{ id: string; subscription: VehicleSubscription }>;
};

export async function getMeSubscriptions() {
    const res = await graphQL.query<"me", MeSubscriptionsResponse>({
        query: `
me {
    subscription {
        subscriptionId
        plan
        subscriptionEnds
    }
    vehicles {
        id
        subscription {
            plan
        }
    }
}`,
    });

    return res;
}
