import React from "react";
import { Button, ButtonProps } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: function (props: AddButtonProps) {
        return {
            width: props.size ? undefined : "32px",
            height: props.size ? undefined : "32px",
            borderRadius: "50%",
            minWidth: 0,
            padding: 0,
        };
    },
    label: {
        width: "auto",
    },
});

type AddButtonProps = Pick<
    ButtonProps,
    "onClick" | "className" | "aria-label" | "disabled" | "size"
>;

function AddButton(props: AddButtonProps) {
    const styles = useStyles(props);
    return (
        <Button
            variant="contained"
            color="primary"
            classes={{ root: styles.root, label: styles.label }}
            {...props}
        >
            <Add />
        </Button>
    );
}

export { AddButton };
export type { AddButtonProps };
