import { listenEvent } from "@pedal/infrastructure";
import { EventTypes } from "../eventTypes";
import { setCurrentVehicle } from "store/vehicle";
import { AppDispatch } from "store/types";

function setupDriverAddedListener(dispatch: AppDispatch) {
    listenEvent(EventTypes.driverAdded, ({ vehicleId }: { vehicleId: string }) => {
        dispatch(setCurrentVehicle(vehicleId));
    });
}

export { setupDriverAddedListener };
