import { graphQL } from "@pedal/pedal-api";
import { Partner, PartnerConfiguration, PartnerTheme } from "api";

type ResponseType = Omit<Partner, "configuration"> & {
    configuration:
        | ({
              theme: PartnerTheme | null;
          } & { data: Omit<PartnerConfiguration, "theme"> })
        | null;
    images: {
        logoUrl: string | null;
    };
};

export async function getPartner(partnerId: string) {
    const res = await graphQL.query<"partner", ResponseType>({
        query: `
partner (id: "${partnerId}") {
    id
    name
    address
    phoneNumber
    dateJoined
    emailAddress
    website
    isDefaultPartner
    defaultCountryCode
    images {
        logoUrl
    }
    configuration {
        theme {
            color
        }
        data
    }
}`,
    });

    return res.data.partner;
}
