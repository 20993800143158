import { graphQL } from "@pedal/pedal-api";
import { VehicleNotification } from "../types";

type VehicleAndNotifications = {
    notifications: Array<VehicleNotification> | null;
};
export async function getNotifications(vehicleId: string) {
    const res = await graphQL.query<"vehicle", VehicleAndNotifications>({
        query: `
vehicle (id: "${vehicleId}") {
    notifications(includeLinkedUserVehicles: true) {
        __typename
        id
        vehicleId
        user {
            id
            firstname
            mobileNumber
        }
        notificationDate
        category
        title
        markdownMessage
        ... on SystemNotification {
            code
            actionDate
            data
            context {
                type
                contextId
                contextName
            }
        }
        ... on DTCNotification {
            dtcCode
            dtc {
                text
            }
        }
        ... on VehicleRecallNotification {
            recallDate
        }
        ... on OfferNotification {
            offerId
        }
    }
}`,
    });

    return res;
}
