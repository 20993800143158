import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { VehicleStatus, getVehicleStatus as getVehicleStatusApi } from "@pedal/vehicle/status";
import { VehicleDataState } from "../vehicleTypes";

export const vehicleSelect = "vehicleDiagnostics($select=)";

const getVehicleStatus = createAsyncThunk(
    "vehicleStatus/getVehicleStatus",
    async ({ vehicleId }: { vehicleId: string; asyncUpdate?: boolean }) => {
        const res = await getVehicleStatusApi(vehicleId);
        if (!res.data.vehicle?.status) {
            return undefined;
        }

        return res.data.vehicle.status;
    }
);

const initialState: VehicleDataState<VehicleStatus> = {
    loading: false,
};
const vehicleStatusSlice = createSlice({
    name: "vehicleStatus",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getVehicleStatus.pending, (state, action) => {
            if (!action.meta.arg.asyncUpdate) {
                state.loading = true;
            }
        });
        builder.addCase(getVehicleStatus.rejected, (state) => {
            state.loading = false;
            state.data = undefined;
        });
        builder.addCase(getVehicleStatus.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
    },
});

export { getVehicleStatus };

export default vehicleStatusSlice.reducer;
