import { graphQL, GraphQLMutationResponse } from "@pedal/pedal-api";

const removeSensor = async (vehicleId: string) => {
    const res = await graphQL.sendCore<GraphQLMutationResponse<"removeSensor">>({
        query: `mutation Mutation($vehicleId: UUID!) {
    removeSensor(vehicleId: $vehicleId) {
        id
    }
}`,
        variables: {
            vehicleId: vehicleId,
        },
    });

    return res;
};

export { removeSensor };
