import React from "react";
import { Footer } from "@pedal/layout";
import { useStore } from "store";

const FooterContainer = () => {
    const { global } = useStore((s) => ({
        global: s.global,
    }));

    return <Footer theme={global.theme} />;
};

export { FooterContainer };
