import React from "react";
import Image, { ImageProps } from "material-ui-image";

const maxImageSize = 196;

const imageStyle = {
    width: "100%",
    height: "inherit",
    minHeight: "100px",
    maxHeight: `${maxImageSize}px`,
    position: "relative",
    objectFit: "contain",
    display: "block",
};

const style = { padding: 0, flex: 1, display: "flex", overflow: "hidden" };

type VehicleImageProps = Pick<ImageProps, "src" | "alt" | "onError">;

const VehicleImage = (props: VehicleImageProps) => {
    return <Image style={style} imageStyle={imageStyle} {...props} />;
};

export { VehicleImage };
export type { VehicleImageProps };
