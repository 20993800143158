function log(...args: unknown[]) {
    console.log(...args);
}

function info(...args: unknown[]) {
    console.info(...args);
}

function warn(...args: unknown[]) {
    console.warn(...args);
}

function error(...args: unknown[]) {
    console.error(...args);
}

function debug(...args: unknown[]) {
    console.debug(...args);
}

type LoggerFunc = (...args: unknown[]) => void;
type LogTypes = "log" | "info" | "warn" | "error" | "debug";

const logger: Record<LogTypes, LoggerFunc> = {
    log,
    info,
    warn,
    error,
    debug,
};

export { logger };
