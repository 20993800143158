import { NotificationCode } from "@pedal/vehicle/notifications";
import { AppDispatch } from "store/types";
import { ConfigureVehicleInput } from "../types";
import { vehicleConfigured, setInitialConfigurationRequired } from "store/vehicle";
import { updateMileage } from "store/vehicle/details/slice";
import { removeByCode } from "store/vehicle/notifications/slice";
import { raiseEvent } from "@pedal/infrastructure";
import { EventTypes } from "common";

function onVehicleConfigured(
    vehicleId: string,
    dispatch: AppDispatch,
    input: ConfigureVehicleInput
) {
    // remove configure notification
    dispatch(vehicleConfigured());

    // raise vehicle configured event
    raiseEvent(EventTypes.vehicleConfigured, { vehicleId });

    // set initial configuration required to false
    dispatch(setInitialConfigurationRequired(false));

    // remove mileage input notification if we have one
    dispatch(removeByCode(NotificationCode.MileageInputRequired));

    // update the mileage
    if (input.mileage) {
        dispatch(updateMileage({ mileage: input.mileage }));
    }
}

export { onVehicleConfigured };
