import { Callback } from "redux-signalr";
import { getVehicleValuation } from "./slice";
import { RootState } from "store";

const callbacks: { [key: string]: Callback } = {
    onValuationUpdate: () => (dispatch, getState) => {
        const state = getState() as RootState;
        const vehicleId = state.currentVehicle.vehicle!.id;

        dispatch(getVehicleValuation({ vehicleId, asyncUpdate: true }));
    },
};

export default callbacks;
