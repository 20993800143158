import { toArray } from "@pedal/utility";
import { getNotifications } from "@pedal/vehicle/notifications";
import { Partner } from "api";
import { toNotificationItemViews } from "./mapper";

async function getNotificationsQuery(
    currentVehicleId: string,
    currentUserId: string,
    partner: Partner
) {
    const res = await getNotifications(currentVehicleId);
    if (!res.data.vehicle?.notifications || res.data.vehicle.notifications.length === 0) {
        return [];
    }

    return await toArray(
        toNotificationItemViews(res.data.vehicle.notifications, currentUserId, partner)
    );
}

export { getNotificationsQuery };
