import { raiseEvent, logger } from "@pedal/infrastructure";
import { LocationResult } from "./types";
import { EventTypes } from "../../eventTypes";
import { DeviceLocationData, DeviceLocationPermissionRequestComplete } from "../../location";

type SetLocation = (result: LocationResult) => void;

// this is called when the location is requested from the app explicitly
function setLocation(): SetLocation {
    return function (result) {
        if (result.coords) {
            raiseEvent<DeviceLocationData>(EventTypes.deviceLocationRequestComplete, {
                coordinates: result.coords,
            });

            raiseEvent<DeviceLocationPermissionRequestComplete>(
                EventTypes.deviceLocationPermissionRequestComplete,
                {
                    success: true,
                }
            );
        } else {
            logger.warn(`Location permission status: ${result.status}`);
            raiseEvent<DeviceLocationData>(EventTypes.deviceLocationRequestComplete, {
                error: {
                    status: result.status,
                },
            });

            raiseEvent<DeviceLocationPermissionRequestComplete>(
                EventTypes.deviceLocationPermissionRequestComplete,
                {
                    success: false,
                }
            );
        }
    };
}

export { setLocation };
export type { SetLocation };
