import { AppOptions, getAppOptions, logger } from "@pedal/infrastructure";
import { auth, Error } from "@pedal/pedal-api";
import { getMe } from "common/queries";
import { initializeAppInterop } from "./common";
import reportWebVitals from "./reportWebVitals";

window.addEventListener("load", async () => {
    // check the mode, if it's app, then init the interop
    // this needs to be done very early on to setup all the interceptors
    const appOptions = getAppOptions() ?? {
        isAppMode: false,
        version: { string: "1.0.0" },
    };

    try {
        if (appOptions.isAppMode) {
            const appOptionsHasUser = appOptions.user !== undefined;
            initializeAppInterop(appOptionsHasUser);

            // newer version of the app. Initial state is passed in through the app
            // also auth is handled via the app too
            if (appOptionsHasUser) {
                const app = await import("./index.app.mobile");
                await app.default();
                return;
            }
        }

        // first load the auth service
        await auth.load();

        // then login
        const account = await auth.login();

        // init app
        if (account) {
            await initApp(account.name, appOptions);
        }
    } catch (e) {
        await handleError(e, appOptions);
    }
});

async function initApp(loginUser: string | undefined, options: AppOptions) {
    // user was not logged in
    if (!loginUser) {
        return;
    }

    // get the current user
    try {
        const response = await getMe();
        if (response.data.me === null) {
            await handleUnknownError(undefined, options);
            return;
        }

        // initReact
        const app = await import("./index.app");
        await app.default(response.data.me, options);
    } catch (e) {
        await handleError(e, options);
    }
}

async function handleError(e: any, options: AppOptions) {
    const error = e as Error;
    switch (error.code) {
        case "user.subscription.has.expired":
        case "SocialUserDoesNotExist":
            await handleAuthError(error, options);
            break;
        default:
            await handleUnknownError(error, options);
            break;
    }
}

async function handleUnknownError(error: any, options: AppOptions) {
    // TODO: Handle/log error
    if (error) {
        logger.error(error);
    }

    const app = await import("./index.error");
    await app.default({ error, options });
}

async function handleAuthError(error: Error, options: AppOptions) {
    const app = await import("./index.error");
    await app.default({ error, options });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(logger.log);
