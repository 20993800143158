import React from "react";
import { useAppDispatch } from "store";
import { ConfigureVehicleInput } from "./types";
import { configureVehicle } from "./mutations";
import { onVehicleConfigured, onSkipVehicleConfiguration } from "./services";

function useConfigureVehicle() {
    const dispatch = useAppDispatch();
    return React.useCallback(
        async function (vehicleId: string, input: ConfigureVehicleInput) {
            const res = await configureVehicle(vehicleId, input);

            if (!res.errors) {
                onVehicleConfigured(vehicleId, dispatch, input);
            }

            return res;
        },
        [dispatch]
    );
}

function useSkipConfigureVehicle() {
    const dispatch = useAppDispatch();
    return React.useCallback(
        async function (vehicleId: string, input: ConfigureVehicleInput) {
            const res = await configureVehicle(vehicleId, input);

            if (!res.errors) {
                onSkipVehicleConfiguration(vehicleId, dispatch);
            }

            return res;
        },
        [dispatch]
    );
}

export { useConfigureVehicle, useSkipConfigureVehicle };
