import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { raiseEvent } from "@pedal/infrastructure";
import { auth } from "@pedal/pedal-api";
import { User, UserSubscription, VehicleItem } from "api";
import { EventTypes } from "common";
import { configuration } from "config";
import * as mutations from "./mutations";
import { getCurrentVehicleNotifications } from "../vehicle/notifications/slice";

const resendSignupInvite = createAsyncThunk(
    "user/resendSignupInvite",
    async ({ userId }: { userId: string }) => {
        await mutations.resendSignupInvite(userId);
    }
);

const configureNotifications = createAsyncThunk(
    "user/configureNotifications",
    async (
        {
            linkedUserVehicles,
        }: { linkedUserVehicles: Array<{ vehicleId: string; receiveNotification: boolean }> },
        thunkApi
    ) => {
        const res = await mutations.configureNotifications(linkedUserVehicles);
        if (res.errors || !res.data.configureNotifications) {
            return { linkedUserVehicles: undefined, errors: res.errors ?? [] };
        }

        // refresh the notifications
        thunkApi.dispatch(getCurrentVehicleNotifications());

        return { linkedUserVehicles, errors: undefined };
    }
);

const signOut = createAsyncThunk("user/signOut", async () => {
    raiseEvent(EventTypes.onSignOut);

    // add in a slight delay for the mobile app to close the browser session
    await new Promise((resolve) => setTimeout(resolve, 500));

    // signout
    auth.logout(configuration.logoutLink);
});

const deleteAccount = createAsyncThunk("user/deleteAccount", async (_, thunkApi) => {
    const userId = (thunkApi.getState() as { user: { id: string } }).user.id;

    // delete user
    const res = await mutations.deleteUser(userId);
    if (res.errors) {
        return { errors: res.errors };
    }

    // raise event
    raiseEvent(EventTypes.accountDeleted);

    // signout
    auth.logout(configuration.logoutLink);

    return { errors: undefined };
});

const updateUserMobileNumber = createAsyncThunk(
    "user/updateUserMobileNumber",
    async ({ userId, mobileNumber }: { userId: string; mobileNumber: string }) => {
        // update user mobile number
        const res = await mutations.updateUserMobileNumber(userId, mobileNumber);
        if (res.errors || !res.data.updateUser?.id) {
            return { errors: res.errors, mobileNumber: mobileNumber };
        }

        return { errors: undefined, mobileNumber: mobileNumber };
    }
);

const initialState: User = {
    id: "",
    linkedUserId: null,
    subscription: null,
    partnerId: "",
    displayName: "",
    emailAddress: "",
    mobileNumber: "",
    firstname: "",
    surname: "",
    dateJoined: "",
    roles: [],
    linkedUsers: [],
    userDevices: [],
    identifiers: {
        intercomWeb: "",
    },
};

const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    extraReducers: (builder) =>
        builder
            .addCase(signOut.fulfilled, (_) => {
                return initialState;
            })
            .addCase(deleteAccount.fulfilled, (_) => {
                return initialState;
            })
            .addCase(configureNotifications.fulfilled, (state, action) => {
                if (!action.payload.linkedUserVehicles) {
                    return state;
                }

                action.payload.linkedUserVehicles.forEach((v) => {
                    const ls = state.linkedUsers.find((i) => i.vehicleId === v.vehicleId);
                    if (ls) {
                        ls.receiveNotification = v.receiveNotification;
                    }
                });
            })
            .addCase(updateUserMobileNumber.fulfilled, (state, action) => {
                if (!action.payload.mobileNumber) {
                    return state;
                }

                state.mobileNumber = action.payload.mobileNumber;
            }),
    reducers: {
        updateUserSubscriptionPlan: (state, action: PayloadAction<UserSubscription | null>) => {
            if (!state.subscription || !action.payload) {
                return state;
            }

            state.subscription = action.payload;
        },
        addVehicle: (state, action: PayloadAction<VehicleItem>) => {
            const vehicle = action.payload;
            if (!vehicle) {
                return state;
            }

            // check if it is a linked vehicle
            if (state.id === vehicle.userId) {
                return state;
            }

            state.linkedUsers.push({
                userId: vehicle.userId,
                vehicleId: vehicle.id,
                receiveNotification: true,
            });
        },
    },
});

export const { updateUserSubscriptionPlan, addVehicle } = userSlice.actions;
export {
    signOut,
    deleteAccount,
    resendSignupInvite,
    configureNotifications,
    updateUserMobileNumber,
};

export default userSlice.reducer;
