import React, { ReactChild } from "react";
import { IconButton } from "@material-ui/core";
import { Pod, NamedChildrenSlots, PodProps } from "../Pod/Pod";
import { PodTitle } from "../PodTitle/PodTitle";
import { hasNamedChildrenSlots } from "../../utils";
import { CloseFilledIcon, withSize } from "../../icons";

const Close = withSize(CloseFilledIcon, 32);

type SecondaryPodProps = {
    logo?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    title: string;
    subtitle?: ReactChild;
    children: ReactChild | ReactChild[] | Omit<NamedChildrenSlots, "title">;
    onClose?: () => void;
    className?: string;
    classes?: PodProps["classes"];
    loading?: boolean;
};

/**
 * A pod which functions as a secondary pod, usually activated via a transition.
 *
 *
 * @param {SecondaryPodProps} props The component props.
 * @returns {JSX.Element} The component element.
 */
const SecondaryPod = (props: SecondaryPodProps): JSX.Element => {
    const { logo, title, subtitle, onClose, className, classes, loading, children } = props;

    return (
        <Pod className={className} classes={classes} loading={loading}>
            {{
                title: (
                    <PodTitle
                        logo={logo}
                        title={title}
                        subtitle={subtitle}
                        extension={
                            <IconButton aria-label="close" onClick={onClose} edge="end">
                                <Close />
                            </IconButton>
                        }
                    />
                ),
                content: hasNamedChildrenSlots(children) ? children.content : children,
                footer: hasNamedChildrenSlots(children) ? children.footer : undefined,
            }}
        </Pod>
    );
};

export { SecondaryPod };
export type { SecondaryPodProps };
