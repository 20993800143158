import {
    PublicClientApplication,
    SilentRequest,
    AuthenticationResult,
    Configuration,
    LogLevel,
    AccountInfo,
    InteractionRequiredAuthError,
    RedirectRequest,
    PopupRequest,
    EndSessionRequest,
    AuthError,
} from "@azure/msal-browser";
import { logger } from "@pedal/infrastructure";
import { SocialUserDoesNotExist } from "./errors";
import { configuration } from "../config";

/**
 * The mode type for acquiring tokens.
 */
export type Mode = "Redirect" | "Popup";

/*
 * Request type
 */
export type Request = {
    scopes: Array<string>;
};

/**
 * AuthModule for application - handles authentication in app.
 */
export default class AuthModule {
    private client: PublicClientApplication; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/classes/_src_app_publicclientapplication_.publicclientapplication.html
    private account: AccountInfo | null; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-common/modules/_src_account_accountinfo_.html
    private loginRedirectRequest: RedirectRequest; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_redirectrequest_.html
    private pedalAppRedirectRequest: RedirectRequest;
    private pedalAppPopupRequest: PopupRequest;
    private silentPedalAppRequest: SilentRequest;
    private accountPromiseResolver?: (value: AccountInfo | null) => void;
    private accountPromiseRejecter?: (reason?: any) => void;
    private tokenPromiseResolver?: (value: string) => void;
    private tokenPromiseRejecter?: (reason?: any) => void;
    private changingPassword: boolean = false;

    constructor(
        config: Configuration,
        loginRequest: Request,
        tokenRequest: Request,
        private mode: Mode = "Redirect"
    ) {
        this.client = new PublicClientApplication(config);
        this.account = null;

        this.loginRedirectRequest = {
            ...loginRequest,
            extraScopesToConsent: tokenRequest.scopes,
            redirectStartPage: configuration.auth.redirectUri,
        };
        this.pedalAppRedirectRequest = {
            ...loginRequest,
            extraScopesToConsent: tokenRequest.scopes,
            redirectStartPage: configuration.auth.redirectUri,
        };
        this.pedalAppPopupRequest = {
            ...loginRequest,
            extraScopesToConsent: tokenRequest.scopes,
        };
        this.silentPedalAppRequest = {
            ...tokenRequest,
            redirectUri: configuration.auth.authUri,
            forceRefresh: false,
        };
    }

    private get isRedirectMode() {
        return this.mode === "Redirect";
    }

    getCurrentAccount() {
        return this.account;
    }

    /**
     * Checks whether we are in the middle of a redirect and handles state accordingly.
     *
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#redirect-apis
     */
    loadAuthModule() {
        if (!this.isRedirectMode) {
            // init the account
            this.handleResponse(null);

            return Promise.resolve();
        }

        return this.client
            .handleRedirectPromise()
            .then((resp) => this.handleResponse(resp))
            .catch((reason) => this.handleError(reason));
    }

    /**
     * Handles the response from a popup or redirect. If response is null, will check if we have any accounts and attempt to sign in.
     * @param response
     */
    private handleResponse(response: AuthenticationResult | null) {
        if (response !== null) {
            this.account = response.account;

            if (this.tokenPromiseResolver) {
                this.tokenPromiseResolver(response.accessToken);
            }
        } else {
            this.account = this.getAccount();
        }

        if (this.accountPromiseResolver) {
            this.accountPromiseResolver(this.account);
        }
    }

    private handleError(reason: any) {
        if (reason instanceof AuthError) {
            // this happens during changepassword
            if (reason.errorCode === "invalid_grant") {
                return;
            } else if (reason.errorMessage.indexOf("AADB2C90118") > -1) {
                // password reset
                this.changePassword();
                return;
            } else if (reason.errorMessage.indexOf("AADB2C90091") > -1) {
                logger.warn(reason.errorMessage);
                return;
            } else if (reason.errorMessage.indexOf("AADB2C99002") > -1) {
                // social signin account doesn't exist, this is handled by a level above
                throw new SocialUserDoesNotExist(reason.errorMessage);
            }
        }

        logger.error(reason);
        if (this.accountPromiseRejecter) {
            this.accountPromiseRejecter(reason);
        }
        if (this.tokenPromiseRejecter) {
            this.tokenPromiseRejecter(reason);
        }
    }

    /**
     * Logs the current user in.
     */
    login() {
        if (this.isRedirectMode) {
            return this.loginRedirect();
        } else {
            return this.loginPopup();
        }
    }

    private loginRedirect() {
        if (this.changingPassword) {
            return Promise.reject("Changing password");
        }

        const promise = new Promise<AccountInfo | null>((resolve, reject) => {
            this.accountPromiseResolver = resolve;
            this.accountPromiseRejecter = reject;
        });

        this.client.loginRedirect(this.loginRedirectRequest);

        return promise;
    }

    private async loginPopup() {
        if (this.changingPassword) {
            return Promise.reject("Changing password");
        }

        const res = await this.client.loginPopup(this.loginRedirectRequest);
        this.handleResponse(res);

        return res.account;
    }

    /**
     * Logs out of current account.
     */
    logout(postLogoutRedirectUri: string | undefined) {
        if (!this.account) {
            const msg = "No current account to logout from";
            logger.warn(msg);
            return Promise.reject(msg);
        }

        const logOutRequest: EndSessionRequest = {
            account: this.account,
            postLogoutRedirectUri,
        };

        return this.client.logout(logOutRequest);
    }

    /**
     * Gets the token for the pedalapp rest api silently, or falls back to interactive redirect.
     */
    async getPedalAppToken() {
        if (this.changingPassword) {
            return Promise.reject("Changing password");
        }

        if (this.account) {
            this.silentPedalAppRequest.account = this.account;
        }

        const req: RedirectRequest | PopupRequest = this.isRedirectMode
            ? this.pedalAppRedirectRequest
            : this.pedalAppPopupRequest;

        return this.acquireTokenSilent(this.silentPedalAppRequest, req);
    }

    changePassword(authority?: string) {
        this.changingPassword = true;

        const changePasswordRequest: RedirectRequest = {
            ...this.loginRedirectRequest,
            authority: authority,
            account: this.account !== null ? this.account : undefined,
        };

        this.client.loginRedirect(changePasswordRequest);
    }

    /**
     * Calls getAllAccounts and determines the correct account to sign into, currently defaults to first account found in cache.
     * TODO: Add account chooser code
     *
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    private getAccount() {
        const activeAccount = this.client.getActiveAccount();
        if (activeAccount !== null) {
            return activeAccount;
        }

        const currentAccounts = this.client.getAllAccounts();
        if (currentAccounts === null) {
            logger.warn("No accounts detected");
            return null;
        }

        let account: AccountInfo | null = null;
        if (currentAccounts.length > 1) {
            // Add choose account code here
            const names = currentAccounts.map((a) => `${a.username} (${a.localAccountId})`);
            logger.warn(
                `Multiple accounts detected: ${names.join(", ")}, setting first one to active.`
            );

            account = currentAccounts[0];
        } else if (currentAccounts.length === 1) {
            account = currentAccounts[0];
        }

        this.client.setActiveAccount(account);

        return account;
    }

    /**
     * Gets a token silently, or falls back to interactive redirect.
     */
    private async acquireTokenSilent(
        silentRequest: SilentRequest,
        interactiveRequest: RedirectRequest | PopupRequest
    ) {
        try {
            const response = await this.client.acquireTokenSilent(silentRequest);
            return response.accessToken;
        } catch (e) {
            logger.log("silent token acquisition fails.");
            if (e instanceof InteractionRequiredAuthError) {
                return this.isRedirectMode
                    ? this.getTokenRedirect(interactiveRequest as RedirectRequest)
                    : this.getTokenPopup(interactiveRequest as PopupRequest);
            }

            throw e;
        }
    }

    private getTokenRedirect(interactiveRequest: RedirectRequest) {
        logger.log("acquiring token using redirect");

        const promise = new Promise<string>((resolve, reject) => {
            this.tokenPromiseResolver = resolve;
            this.tokenPromiseRejecter = reject;
        });

        this.client.acquireTokenRedirect(interactiveRequest).catch(logger.error);

        return promise;
    }

    private async getTokenPopup(interactiveRequest: PopupRequest) {
        logger.log("acquiring token using popup");

        const res = await this.client.acquireTokenPopup(interactiveRequest);

        return res.accessToken;
    }
}
