import React, { ReactChild } from "react";
import { Grid, Typography, Paper } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { ContentLoader } from "../../loading";
import { AnalyticsTrackProps, useAnalyticsProps } from "../../analytics";
import { podIconSize } from "../size";
import clsx from "clsx";

type ZeroStatePodProps = {
    /**
     * Logo for the pod title.
     */
    logo?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    /**
     * The title of the pod.
     */
    title: string;
    /**
     * The description of the pod.
     */
    description: ReactChild;
    /**
     * Renders the pod in a loading state. With a centered loading icon.
     */
    loading?: boolean;
    /**
     * Callback to invoke if the pod is clicked.
     */
    onClick?: () => void;
    /**
     * Classname to apply to container component.
     */
    className?: string;
} & AnalyticsTrackProps;

const useStyles = makeStyles((theme) =>
    createStyles({
        icon: {
            width: podIconSize.width,
            height: podIconSize.height,
            display: "inline-block",
            margin: theme.spacing(4, "auto", 1),
            [theme.breakpoints.between("xs", "sm")]: {
                margin: theme.spacing(1, "auto", 0),
            },
        },
        title: {
            color: "#000000",
            padding: theme.spacing(1, 1, 1),
        },
        description: {
            color: "#000000",
            letterSpacing: "0.15px",
            padding: theme.spacing(2, 7, 8),
            [theme.breakpoints.between("xs", "sm")]: {
                padding: theme.spacing(0, 2, 2),
            },
        },
        paper: ({ hasOnClick }: { hasOnClick: boolean }) => ({
            textAlign: "center",
            borderRadius: 16,
            height: "100%",
            position: "relative",
            cursor: hasOnClick ? "pointer" : undefined,
            display: "flex",
        }),
        gridContainer: {
            [theme.breakpoints.between("xs", "sm")]: {
                height: "100%",
                justifyContent: "center",
            },
        },
    })
);

/**
 * Zero-state pod is used to display static information regarding a pod.
 *
 * Primarily use-case for the zero-state pod is when enough information isn't available for a data pod.
 *
 * @param {ZeroStatePodProps} props The component props.
 * @returns {JSX.Element} The component element.
 */
const ZeroStatePod = (props: ZeroStatePodProps) => {
    const {
        logo: Logo,
        title,
        description,
        loading = false,
        onClick,
        className,
        ...analytics
    } = props;
    const styles = useStyles({ hasOnClick: onClick !== undefined });
    const analyticsProps = useAnalyticsProps(analytics);

    return (
        <Paper className={clsx(styles.paper, className)} onClick={onClick} {...analyticsProps}>
            {loading ? <ContentLoader /> : null}
            <Grid
                container
                direction="column"
                style={loading ? { opacity: 0.15 } : undefined}
                className={styles.gridContainer}
            >
                {Logo && (
                    <Grid item>
                        <Logo className={styles.icon} />
                    </Grid>
                )}
                <Grid item>
                    <Typography variant="h5" className={styles.title}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1" className={styles.description}>
                        {description}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

export { ZeroStatePod };
export type { ZeroStatePodProps };
