import { Configuration, LogLevel } from "@azure/msal-browser";
import { logger } from "@pedal/infrastructure";
import { configuration } from "../../config";

// the msalApp
const b2cPolicies = (() => {
    const policyNames = {
        signIn: "B2C_1_signin",
        forgotPassword: "B2C_1_passwordreset",
    };

    return {
        names: policyNames,
        authorities: {
            signIn: {
                authority: `${configuration.auth.azureB2C.authorityRoot}/${policyNames.signIn}`,
            },
            forgotPassword: {
                authority: `${configuration.auth.azureB2C.authorityRoot}/${policyNames.forgotPassword}`,
            },
        },
    };
})();

const loginRequest = {
    scopes: ["openid", "profile"],
};

const tokenRequest = {
    scopes: ["user_impersonation"].map((s) => `${configuration.auth.azureB2C.appId}/${s}`),
};

/**
 * Configuration class for @azure/msal-browser:
 * https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_config_configuration_.html
 */
const AZURE_B2C_CONFIG: Configuration = {
    auth: {
        clientId: configuration.auth.azureB2C.clientId,
        authority: b2cPolicies.authorities.signIn.authority,
        redirectUri: configuration.auth.redirectUri,
        postLogoutRedirectUri: configuration.auth.redirectUri,
        knownAuthorities: [
            b2cPolicies.authorities.signIn.authority,
            b2cPolicies.authorities.forgotPassword.authority,
        ],
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        logger.error(message);
                        return;
                    case LogLevel.Info:
                        logger.info(message);
                        return;
                    case LogLevel.Verbose:
                        logger.debug(message);
                        return;
                    case LogLevel.Warning:
                        logger.warn(message);
                        return;
                }
            },
        },
    },
};

export { AZURE_B2C_CONFIG, b2cPolicies, loginRequest, tokenRequest };
