import React from "react";
import { raiseEvent } from "@pedal/infrastructure";

function walkUpDomChainWithElementValidation(
    el: Element,
    validationMethod: (el: Element) => boolean
): Element | null {
    let element = el;
    if (element) {
        while (!validationMethod(element)) {
            element = element.parentNode as Element;
            if (!element || !element.getAttribute) {
                return null;
            }
        }
        return element;
    }

    return null;
}

const dataRegex = /analytics-track-data-(.*)/g;

function handleClick(event: MouseEvent) {
    const element = walkUpDomChainWithElementValidation(
        event.target as Element,
        (e) => e.getAttribute("analytics-track") !== null
    );

    if (!element) {
        return;
    }

    const trackName = element.getAttribute("analytics-track")!;
    const data = element.getAttributeNames().reduce((o: any, k) => {
        const matches = dataRegex.exec(k);
        if (matches && matches[1]) {
            o[matches[1]] = element.getAttribute(k);
        }

        return o;
    }, {});

    raiseEvent("appAnalytics", {
        type: "ClickEvent",
        data: {
            name: trackName,
            ...data,
        },
    });
}

function ClickTracker() {
    React.useEffect(() => {
        window.addEventListener("click", handleClick);
        return () => {
            window.removeEventListener("click", handleClick);
        };
    }, []);

    return null;
}

export { ClickTracker };
