import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "@pedal/styleguide";

type StyleProps = {
    showMessage: boolean;
};

const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        backgroundColor: theme.palette.common.white,
        position: "relative",
    },
    messageContainer: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        maxWidth: "400px",
        display({ showMessage }: StyleProps) {
            return showMessage ? "block" : "none";
        },
    },
    message: {
        backgroundColor: colors.platinumExtraDark,
        color: theme.palette.common.white,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(1.5),
        margin: theme.spacing(0, 1),
        cursor: "pointer",
        width: "auto",
    },
    backdrop: {
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        borderRadius: theme.spacing(2),
        "& > *": {
            opacity: 0.5,
        },
    },
}));

type LockedPodContainerProps = {
    children: React.ReactNode;
    message: React.ReactNode;
    onMessageClick: () => void;
};

function LockedPodContainer({ children, message, onMessageClick }: LockedPodContainerProps) {
    const [showMessage, setShowMessage] = React.useState(false);
    const styles = useStyles({ showMessage });

    const onClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
        e.stopPropagation();
        onMessageClick();
    };

    const onToggle = () => setShowMessage((r) => !r);

    return (
        <div className={styles.container}>
            <div className={styles.backdrop} onClick={onToggle} role="button">
                {children}
            </div>
            <div className={styles.messageContainer}>
                <Box flex="1" onClick={onClick} role="button" className={styles.message}>
                    {message}
                </Box>
            </div>
        </div>
    );
}

export { LockedPodContainer };
export type { LockedPodContainerProps };
