import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SubscriptionOptionsState } from "./types";
import { getSubscriptionOptions as getSubscriptionOptionsApi } from "./queries";

const getSubscriptionsOptions = createAsyncThunk(
    "subscriptions/getSubscriptionsOptions",
    async (_) => {
        const res = await getSubscriptionOptionsApi();
        return res.data.me?.subscriptionOptions;
    }
);

const initialState: SubscriptionOptionsState = {
    subscriptionOptions: undefined,
};

const subscriptionsSlice = createSlice({
    name: "subscriptions",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getSubscriptionsOptions.fulfilled, (state, action) => {
            if (!action.payload) {
                return state;
            }

            state.subscriptionOptions = action.payload;
        });
    },
});

export { getSubscriptionsOptions };

export default subscriptionsSlice.reducer;
