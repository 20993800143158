import React from "react";
import { AnalyticsTrackProps } from "./props";
import { convertToCustomProps } from "./common";

function withAnalytics<TProps>(
    Component: React.ComponentType<TProps>,
    trackProps: AnalyticsTrackProps
) {
    const customProps = convertToCustomProps(trackProps);
    return (props: TProps) => <Component {...props} {...customProps} />;
}

export { withAnalytics };
