import React, { ReactChild } from "react";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NotificationCategory } from "../types";
import { style } from "../style";
import clsx from "clsx";

type NotificationPaperProps = {
    category?: NotificationCategory;
    children: ReactChild;
    className?: string;
    classes?: Partial<Record<"root" | "paper", string>>;
};

const NotificationPaper = (props: NotificationPaperProps) => {
    const styles = useStyles(props);
    const { children, className, classes } = props;

    return (
        <div className={clsx(styles.container, className, classes?.root)}>
            <Paper className={clsx(styles.paper, classes?.paper)}>{children}</Paper>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        padding: "2px",
        boxSizing: "border-box",
    },
    paper: {
        borderRadius: theme.spacing(style.borderRadius),
        height: "100%",
        position: "relative",
        overflow: "hidden",
        boxSizing: "border-box",
        border: function ({ category }: NotificationPaperProps) {
            switch (category) {
                case NotificationCategory.Notification:
                    return `${style.borderWidthPixels}px solid ${theme.palette.info.main}`;
                case NotificationCategory.Reminder:
                case NotificationCategory.Alert:
                    return `${style.borderWidthPixels}px solid ${theme.palette.text.secondary}`;
                case NotificationCategory.Warning:
                case NotificationCategory.Error:
                    return `${style.borderWidthPixels}px solid ${theme.palette.warning.main}`;
                default:
                    return undefined;
            }
        },
    },
}));

export { NotificationPaper };
export type { NotificationPaperProps };
