import React, { ReactChild } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { hasNamedChildrenSlots } from "../../utils";
import { NotificationCategory } from "../types";
import { style } from "../style";
import { NotificationPaper } from "../NotificationPaper";

type NamedChildrenSlots = {
    content: ReactChild | null;
    actions?: ReactChild;
};

type NotificationItemBaseProps = {
    category: NotificationCategory;
    children: ReactChild | NamedChildrenSlots;
    flexActionsToEnd?: boolean;
};

const NotificationItemBase = (props: NotificationItemBaseProps) => {
    const styles = useStyles();
    const { category, children, flexActionsToEnd } = props;

    const { content, actions } = hasNamedChildrenSlots(children)
        ? { content: children.content, actions: children.actions }
        : { content: children, actions: undefined };

    return (
        <NotificationPaper classes={{ paper: styles.paper }} category={category}>
            <Grid container direction="column" className={styles.container}>
                <Grid item className={styles.content}>
                    {content}
                </Grid>
                {actions && (
                    <Grid item style={{ alignSelf: flexActionsToEnd ? "flex-end" : "inherit" }}>
                        {actions}
                    </Grid>
                )}
            </Grid>
        </NotificationPaper>
    );
};

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(style.padding),
    },
    container: {
        height: "100%",
    },
    content: {
        flex: 1,
        minHeight: "80px",
    },
}));

export { NotificationItemBase };
export type { NotificationItemBaseProps };
