import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { VehicleItem, VehicleSubscription } from "api";
import { VehicleCapabilities, VehiclesState } from "./vehicleTypes";
import { getVehicleItems, getVehicleItem } from "./queries";

const getVehiclesByUserId = createAsyncThunk(
    "vehicles/getVehiclesByUserId",
    async (userId: string) => {
        const vehicles = (await getVehicleItems()) || [];
        return toVehiclesState(userId, undefined, vehicles);
    }
);

const refreshVehicleItem = createAsyncThunk(
    "vehicles/refreshVehicleItem",
    async (vehicleId: string) => {
        const vehicle = await getVehicleItem(vehicleId);
        return { vehicle };
    }
);

function toVehiclesState(userId: string, vehicleId: string | undefined, vehicles: VehicleItem[]) {
    vehicles.sort((a) => (a.userId === userId ? -1 : 1));

    let defaultVehicleId = vehicleId ?? window.localStorage.getItem("defaultVehicleId");
    if (vehicles.findIndex((v) => v.id === defaultVehicleId) === -1) {
        defaultVehicleId = null;
    }

    const firstVehicleId = vehicles.length > 0 ? vehicles[0].id : undefined;

    return {
        defaultVehicleId: defaultVehicleId ?? firstVehicleId,
        vehicles: vehicles.map(mapVehicleToState),
    };
}

function createCapabilities(vehicle: VehicleItem): VehicleCapabilities {
    const isNotLite = vehicle.subscription?.plan !== "PEDAL_LITE";
    return {
        supportsLocation: isNotLite,
        supportsWeather: isNotLite,
        supportsGarageAssociation: isNotLite,
        supportsValuations: isNotLite,
    };
}

function mapVehicleToState(vehicle: VehicleItem) {
    return {
        ...vehicle,
        capabilities: createCapabilities(vehicle),
    };
}

const vehicleInitialState: VehiclesState = {
    vehicles: [],
    initialConfigurationRequired: false,
    loading: false,
};
const vehiclesSlice = createSlice({
    name: "vehicles",
    initialState: vehicleInitialState,
    extraReducers: (builder) => {
        builder.addCase(getVehiclesByUserId.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getVehiclesByUserId.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getVehiclesByUserId.fulfilled, (state, action) => {
            state.loading = false;
            state.vehicles = action.payload.vehicles.map(mapVehicleToState);
            state.defaultVehicleId = action.payload.defaultVehicleId;
        });
        // builder.addCase(refreshVehicleItem.pending, (state) => {
        //     state.loading = true;
        // });
        // builder.addCase(refreshVehicleItem.rejected, (state) => {
        //     state.loading = false;
        // });
        builder.addCase(refreshVehicleItem.fulfilled, (state, action) => {
            if (!state || !action.payload.vehicle) {
                return state;
            }

            const newVehicle = action.payload.vehicle;

            const index = state.vehicles.findIndex((v) => v.id === newVehicle.id);
            state.vehicles[index] = mapVehicleToState(newVehicle);
        });
    },
    reducers: {
        setCurrentVehicle(state, action: PayloadAction<string>) {
            window.localStorage.setItem("defaultVehicleId", action.payload);

            if (!state) {
                return state;
            }

            state.defaultVehicleId = action.payload;
        },
        addVehicle(state, action: PayloadAction<VehicleItem>) {
            if (!state) {
                return state;
            }

            const vehicle = action.payload;
            state.vehicles.push(mapVehicleToState(vehicle));
        },
        setInitialConfigurationRequired(state, action: PayloadAction<boolean>) {
            if (!state) {
                return state;
            }

            state.initialConfigurationRequired = action.payload;
        },
        removeVehicle(state, action: PayloadAction<string>) {
            if (!state) {
                return state;
            }

            const index = state.vehicles.findIndex((v) => v.id === action.payload);
            if (index < 0) {
                return state;
            }

            state.vehicles.splice(index, 1);
        },
        updateSubscriptions(
            state,
            action: PayloadAction<Array<{ id: string; subscription: VehicleSubscription }>>
        ) {
            if (!state) {
                return state;
            }

            action.payload.forEach((i) => {
                const vehicle = state.vehicles.find((v) => v.id === i.id);
                if (vehicle) {
                    vehicle.subscription = i.subscription;
                    vehicle.capabilities = createCapabilities(vehicle);
                }
            });
        },
    },
});

export { getVehiclesByUserId, toVehiclesState, refreshVehicleItem };

export const {
    addVehicle,
    setInitialConfigurationRequired,
    removeVehicle,
    setCurrentVehicle,
    updateSubscriptions,
} = vehiclesSlice.actions;

export default vehiclesSlice.reducer;
