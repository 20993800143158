import React, { forwardRef } from "react";
import Input, { Value } from "react-phone-number-input/input";
import { TextField as MuiTextField, TextFieldProps, useControlled } from "@material-ui/core";
import { defaultCountry } from "./constants";

const TextField = forwardRef(function (props: TextFieldProps, ref: any) {
    return <MuiTextField inputRef={ref} {...props} />;
});

type PhoneNumberFieldProps = {
    value?: string;
    defaultValue?: string;
    onChange?: (value: string | undefined) => void;
} & Omit<TextFieldProps, "value" | "defaultValue" | "onChange">;

/**
 * Adds phone number support via react-phone-number-input to material {@link MuiTextField}.
 *
 * @param {PhoneNumberFieldProps} props The component props.
 * @returns {JSX.Element} The component element.
 */
function PhoneNumberField({ name, value, defaultValue, onChange, ...rest }: PhoneNumberFieldProps) {
    const [phoneValue, setPhoneValue] = useControlled({
        controlled: value,
        default: defaultValue,
        name: name ?? "PhoneNumberField",
    });

    const phoneValueChange = React.useCallback(
        function (value?: Value) {
            if (value) {
                const stringValue = value.toString();
                setPhoneValue(stringValue);

                onChange && onChange(stringValue);
            } else {
                onChange && onChange("");
            }
        },
        [onChange, setPhoneValue]
    );

    return (
        <Input
            defaultCountry={defaultCountry}
            name={name}
            value={phoneValue}
            onChange={phoneValueChange}
            inputComponent={TextField as any}
            {...rest}
        />
    );
}

export { PhoneNumberField };
export type { PhoneNumberFieldProps };
