import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Swiper, SwiperSlide, SwiperProps } from "swiper/react";
import { Pagination } from "swiper";
import clsx from "clsx";
import { useSwiperProps } from "./hooks";
import { ContentLoader } from "../../loading";

import "./NotificationsGrid.tsx.css";

type NotificationsGridProps = {
    activeItemIndex?: number;
    loading?: boolean;
    children: ReactNode;
} & Pick<SwiperProps, "nested">;

const NotificationsGrid = ({
    activeItemIndex,
    loading,
    children,
    ...swiperProps
}: NotificationsGridProps) => {
    const styles = useStyles();
    const props = useSwiperProps();

    const notifications = React.Children.toArray(children);

    return (
        <Swiper
            {...swiperProps}
            {...props}
            className={clsx(styles.container, "notifications-grid")}
            initialSlide={activeItemIndex}
            simulateTouch
            pagination={{
                clickable: false,
                bulletActiveClass: styles.bulletActiveClass,
            }}
            modules={[Pagination]}
            navigation={false}
        >
            {loading ? <ContentLoader slot="container-start" /> : null}
            {notifications.map((n, i) => (
                <SwiperSlide key={i}>{n}</SwiperSlide>
            ))}
        </Swiper>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        position: "relative",
        minHeight: "80px",
        "& .swiper-slide": {
            height: "auto",
        },
        "& .swiper-pagination": {
            position: "static",
            marginTop: theme.spacing(1),
        },
    },
    bulletActiveClass: {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
    },
}));

export { NotificationsGrid };
export type { NotificationsGridProps };
