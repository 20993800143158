import { graphQL } from "@pedal/pedal-api";
import { DeviceLocation } from "api";

type ResponseType = { location: DeviceLocation | null };
export async function getDeviceLocation(vehicleId: string) {
    const res = await graphQL.query<"vehicle", ResponseType>({
        query: `
vehicle (id: "${vehicleId}") {
    location {
        longitude
        latitude
        formattedAddress
        display
        locationLastUpdated
        device {
            type
        }
    }
}`,
    });

    return res.data.vehicle?.location ?? null;
}
