import { graphQL } from "@pedal/pedal-api";

type ResponseType = {
    images: {
        bannerUrl: string | null;
    };
};

export async function getPartnerBannerImageUrl(partnerId: string) {
    const res = await graphQL.query<"partner", ResponseType>({
        query: `
partner (id: "${partnerId}") {
    images {
        bannerUrl
    }
}`,
    });

    return res.data.partner;
}
