import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "@pedal/styleguide";
import {
    config,
    FacebookIcon,
    TictokIcon,
    YoutubeIcon,
    InstagramIcon,
    PinterestIcon,
    TwitterIcon,
} from "../common";
import { Theme } from "../types";

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: ({ theme }: Props) => theme.color ?? colors.footer,
    },
    container: {
        padding: theme.spacing(3),
        "& > :first-child": {
            marginBottom: theme.spacing(3),
        },
    },
    text: {
        color: "#ffffff",
        display: "inline-block",
    },
    icon: {
        width: "25px",
        height: "25px",
        display: "block",
        "& > svg": {
            width: "100%",
            height: "100%",
            display: "block",
        },
    },
}));

const social = [
    {
        name: "facebook",
        Icon: <FacebookIcon />,
        url: "https://www.facebook.com/thisispedal",
    },
    {
        name: "tiktok",
        Icon: <TictokIcon />,
        url: "https://www.tiktok.com/@thisispedal",
    },
    {
        name: "youtube",
        Icon: <YoutubeIcon />,
        url: "https://www.youtube.com/channel/UC4o7Fvi14fkTIgy7yzfgOzg",
    },
    {
        name: "instagram",
        Icon: <InstagramIcon />,
        url: "https://www.instagram.com/thisispedal",
    },
    {
        name: "pinterest",
        Icon: <PinterestIcon />,
        url: "https://www.pinterest.co.uk/thisispedal",
    },
    {
        name: "twitter",
        Icon: <TwitterIcon />,
        url: "https://twitter.com/thisispedal",
    },
];

type Props = {
    theme: Theme;
};

const Footer = (props: Props) => {
    const classes = useStyles(props);
    return (
        <footer className={classes.footer}>
            <Box className={classes.container}>
                <Box>
                    <Grid container direction="row" spacing={2} justify="center">
                        {social.map((o, i) => (
                            <Grid item key={i}>
                                <a
                                    className={classes.icon}
                                    href={o.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {o.Icon}
                                </a>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Box>
                    <Grid container direction="row" spacing={1} justify="center">
                        <Grid item>
                            <Typography variant="body2" className={classes.text}>
                                Copyright © 2021 Pedal® - All Rights Rerserved
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" className={classes.text}>
                                <a
                                    href={config.privacyPolicyLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classes.text}
                                >
                                    Privacy Policy
                                </a>
                                &nbsp;|&nbsp;
                                <a
                                    href={config.termsConditionsLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classes.text}
                                >
                                    Terms & Conditions
                                </a>
                                &nbsp;|&nbsp;
                                <a
                                    href={config.cookiePolicyLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classes.text}
                                >
                                    Cookies
                                </a>
                                &nbsp;|&nbsp;
                                <a
                                    href={config.eulaAgreementLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classes.text}
                                >
                                    EULA
                                </a>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </footer>
    );
};

export { Footer };
