import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { VehicleDataState } from "../vehicleTypes";
import { getGaragePartner as getGaragePartnerApi } from "./queries";
import { setVehicleGaragePartner } from "./mutations";
import { Garage } from "./types";

const setGaragePartner = createAsyncThunk(
    "vehicleGaragePartner/setGaragePartner",
    async ({ vehicleId, garageId }: { vehicleId: string; garageId: string }) => {
        const res = await setVehicleGaragePartner(vehicleId, garageId);
        if (res.data.setVehicleGaragePartner !== null) {
            return res.data.setVehicleGaragePartner;
        }

        throw new Error("Could not set garage for vehicle");
    }
);

const getGaragePartner = createAsyncThunk(
    "vehicleGaragePartner/getGaragePartner",
    async ({ vehicleId, asyncUpdate }: { vehicleId: string; asyncUpdate?: boolean }) => {
        const garage = await getGaragePartnerApi(vehicleId);
        if (garage === null) {
            return undefined;
        }

        return garage;
    }
);

const initialState: VehicleDataState<Garage> = {
    loading: false,
};
const vehicleGaragePartnerSlice = createSlice({
    name: "vehicleGaragePartner",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setGaragePartner.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(setGaragePartner.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(setGaragePartner.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(getGaragePartner.pending, (state, action) => {
            if (!action.meta.arg.asyncUpdate) {
                state.loading = true;
            }
        });
        builder.addCase(getGaragePartner.rejected, (state) => {
            state.loading = false;
            state.data = undefined;
        });
        builder.addCase(getGaragePartner.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
    },
});

export { setGaragePartner, getGaragePartner };

export default vehicleGaragePartnerSlice.reducer;
