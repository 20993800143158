import { Callback } from "redux-signalr";
import { getCurrentVehicleNotifications } from "./slice";

const callbacks: { [key: string]: Callback } = {
    OnNotificationsUpdate: () => (dispatch, getState) => {
        dispatch(getCurrentVehicleNotifications());
    },
};

export default callbacks;
