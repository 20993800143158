import { graphQL, GraphQLMutationResponse } from "@pedal/pedal-api";
import { ConfigureVehicleInput } from "../types";

export function configureVehicle(vehicleId: string, input: ConfigureVehicleInput) {
    return graphQL.sendCore<GraphQLMutationResponse<"configureVehicle">>({
        query: `mutation ConfigureVehicle($vehicleId: UUID!, $input: ConfigureVehicleInput!) {
    configureVehicle(vehicleId: $vehicleId, input: $input) {
        messages
    }
}`,
        variables: {
            vehicleId,
            input,
        },
    });
}
