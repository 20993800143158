import { listenEvent } from "@pedal/infrastructure";
import { EventTypes } from "../eventTypes";
import { setConnectedDeviceType } from "store/location/locationSlice";
import { updateVehicleSubscription } from "store/vehicle";
import { Store } from "store";

function setupConnectServiceConsentListener(isAppMode: boolean, { dispatch }: Store) {
    // service connected
    listenEvent(EventTypes.sensorAdded, ({ granted }: { granted: boolean }) => {
        if (!granted) {
            return;
        }

        dispatch(updateVehicleSubscription({ plan: "PEDAL_CONNECT" }));

        // set location device type
        dispatch(setConnectedDeviceType({ deviceType: "SERVICE", resetLocation: true }));
    });

    if (isAppMode) {
        return;
    }

    listenEvent(
        EventTypes.pedalConnectServiceConsent,
        (data: { vehicleId: string; connectUrl: string }) => {
            if (!data) {
                return;
            }

            const { vehicleId, connectUrl } = data;

            const redirect_uri =
                window.location.href +
                `?vehicleId=${vehicleId}&view=/add-pedal-connect/service-granted`;

            const fullConnectUrl = connectUrl + `?redirect_uri=${encodeURIComponent(redirect_uri)}`;

            window.location.href = fullConnectUrl;
        }
    );
}

export { setupConnectServiceConsentListener };
