import { GeoCoordinate } from "@pedal/component-library";
import { listenEvent } from "@pedal/infrastructure";
import { EventTypes } from "../eventTypes";

type Data = {
    origin: GeoCoordinate;
    destination: GeoCoordinate;
};

// https://developers.google.com/maps/documentation/urls/get-started
const googleMaps = "https://www.google.com/maps/dir/?api=1";

function setupNavigateToListener() {
    listenEvent(EventTypes.navigateTo, (data: Data) => {
        if (!data) {
            return;
        }

        const { origin, destination } = data;

        window.open(
            `${googleMaps}&origin=${origin.latitude},${origin.longitude}&destination=${destination.latitude},${destination.longitude}&travelmode=driving`,
            "_blank"
        );
    });
}

export { setupNavigateToListener };
