import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AnalyticsTrackProps, useAnalyticsProps } from "../../analytics";
import clsx from "clsx";

type Vehicle = {
    vrm: string;
    manufacturer: string;
    range: string;
};

type VehicleDetailsProps<T> = {
    vehicle: T;
    onClick?: () => void;
    className?: string;
} & AnalyticsTrackProps;

const useStyles = makeStyles({
    container: {
        overflow: "hidden",
    },
    range: {
        textTransform: "capitalize",
    },
    vrm: {
        lineHeight: 1,
    },
});

function VehicleDetails<T extends Vehicle = Vehicle>({
    vehicle,
    onClick,
    className,
    ...analytics
}: VehicleDetailsProps<T>) {
    const styles = useStyles();
    const analyticsProps = useAnalyticsProps(analytics);

    return (
        <div className={clsx(styles.container, className)} onClick={onClick} {...analyticsProps}>
            <Typography variant="h6" className={styles.vrm}>
                {vehicle.vrm}
            </Typography>
            <Typography variant="body2" className={styles.range} noWrap>
                {vehicle.manufacturer}, {vehicle.range}
            </Typography>
        </div>
    );
}

export { VehicleDetails };
export type { Vehicle };
