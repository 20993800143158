import React from "react";
import { Map, MapRef, MapProps } from "../Map";
import { Marker } from "react-map-gl";

type LocationMarker = {
    longitude: number;
    latitude: number;
    element: JSX.Element;
};

type DefaultMapProps = MapProps & {
    markers?: LocationMarker[];
};

const DefaultMap = React.forwardRef<MapRef, DefaultMapProps>((props, ref) => {
    const { markers = [], ...rest } = props;
    return (
        <Map {...rest} ref={ref}>
            {markers.map((m, index) => {
                return (
                    <Marker key={index} longitude={m.longitude} latitude={m.latitude}>
                        {m.element}
                    </Marker>
                );
            })}
        </Map>
    );
});

export { DefaultMap };
export type { LocationMarker, DefaultMapProps };
