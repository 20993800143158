import { GeoCoordinate } from "../common";

const MileToKmConversionValue = 1.609344;

export function getDistanceInMeters(coord1: GeoCoordinate, coord2: GeoCoordinate) {
    const R = 6371e3; // radius of Earth in metres
    const φ1 = (coord1.latitude * Math.PI) / 180; // φ, λ in radians
    const φ2 = (coord2.latitude * Math.PI) / 180;
    const Δφ = ((coord2.latitude - coord1.latitude) * Math.PI) / 180;
    const Δλ = ((coord2.longitude - coord1.longitude) * Math.PI) / 180;

    const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // in metres
    return d;
}

export function metersToMiles(value: number) {
    return value / (MileToKmConversionValue * 1000);
}
