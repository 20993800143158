enum EventTypes {
    onSignOut = "onSignOut",
    onStoreCreated = "onStoreInitialized",
    onAppInitialized = "onAppInitialized",
    onAppInitializedError = "onAppInitializedError",

    vehicleLoaded = "vehicleLoaded",
    vehicleConfigured = "vehicleConfigured",
    vehicleSold = "vehicleSold",

    locationUpdated = "locationUpdated",

    deviceLocationRequested = "deviceLocationRequested",
    deviceLocationRequestComplete = "deviceLocationRequestComplete",
    deviceLocationPermissionRequested = "deviceLocationPermissionRequested",
    deviceLocationPermissionRequestComplete = "deviceLocationPermissionRequestComplete",

    notificationPermissionRequested = "notificationPermissionRequested",
    notificationPermissionRequestComplete = "notificationPermissionRequestComplete",

    vehicleAdded = "vehicleAdded",
    sensorAdded = "sensorAdded",
    sensorRemoved = "sensorRemoved",
    driverAdded = "driverAdded",
    vehicleRemoved = "vehicleRemoved",

    onRemoveSensor = "onRemoveSensor",
    onChangeVehicle = "onChangeVehicle",
    onRemoveVehicle = "onRemoveVehicle",

    onUserMembershipUpdated = "onUserMembershipUpdated",
    onUserMembershipDowngraded = "onUserMembershipDowngraded",
    onUserMembershipUpgraded = "onUserMembershipUpgraded",

    pedalConnectServiceConsent = "pedalConnectServiceConsent",
    pedalConnectServiceConsentComplete = "pedalConnectServiceConsentComplete",

    // account
    accountDeleted = "accountDeleted",

    navigateTo = "navigateTo",

    onError = "onError",
    onMessage = "onMessage",

    onApiError = "onApiError",

    // share
    shareData = "shareData",

    // chat
    openChat = "openChat",
    openNewChat = "openNewChat",

    // request
    requestVehicleDetails = "requestVehicleDetails",

    // memberships
    displayMembershipUpgrade = "displayMembershipUpgrade",
    requestMembershipUpgrade = "requestMembershipUpgrade",
    onMembershipUpgradeComplete = "onMembershipUpgradeComplete",

    // app specific
    openAppSettings = "openAppSettings",
    openShareSettings = "openShareSettings",
    navigateToReview = "navigateToReview",
    goToStore = "goToStore",

    // routing
    showValuations = "showValuations",
    showVehicleMotHistory = "showVehicleMotHistory",
    viewLocation = "viewLocation",
    showDashboard = "showDashboard",
    showManageSettings = "showManageSettings",
    showGarageSelector = "showGarageSelector",
    showFuelMap = "showFuelMap",
    addVehicle = "addVehicle",
    addPedalConnect = "addPedalConnect",
    addDriver = "addDriver",
}

export { EventTypes };
