/**
 * Raises the event of type {@link type} with the event payload data set to {@link data}.
 *
 * @typedef T The event data type.
 * @param {string} type The event type.
 * @param {T} data The event data.
 */
function raiseEvent<TData>(type: string, data?: TData): void {
    window.dispatchEvent(new CustomEvent(type, { detail: data }));
}

/**
 * Listens for events of type {@link type} and fires the {@link callback} with the event data.
 *
 * This should be used in conjunction with {@link raiseEvent}.
 *
 * When invoked this function returns a disposer function, which, when called, unbinds the listening of the
 * event. This disposer function should be called when it is no longer required to listen to the event.
 *
 * @param {string} type The event type.
 * @param {Function} callback The callback to fire when the event is raised.
 * @param {boolean | AddEventListenerOptions} options The event listener options.
 * @returns {Function} A disposer function to unbind the listening of the event.
 */
function listenEvent<TData = unknown>(
    type: string,
    callback: (data: TData) => void,
    options?: boolean | AddEventListenerOptions
): () => void {
    const eventListener = ((e: CustomEvent<TData>) => {
        callback(e.detail);
    }) as EventListener;
    window.addEventListener(type, eventListener, options);

    return () => window.removeEventListener(type, eventListener);
}

function listenEventOnce<TData = unknown>(type: string): Promise<TData> {
    return new Promise<TData>((resolver) => {
        const disposer = listenEvent<TData>(type, (data) => {
            resolver(data);
            disposer();
        });
    });
}

if (process.env.NODE_ENV === "development" && typeof window !== "undefined") {
    console.log("Adding raiseEvent to global");
    const w = window as any;
    w.dev = w.dev || {};
    w.dev.raiseEvent = raiseEvent;
}

export { raiseEvent, listenEvent, listenEventOnce };
