import { graphQL } from "@pedal/pedal-api";
import { User } from "api";

export async function getMe() {
    const res = await graphQL.query<"me", User>({
        query: `
me {
    id
    firstname
    surname
    mobileNumber
    emailAddress
    linkedUserId
    partnerId
    displayName
    dateJoined
    subscription {
        subscriptionId
        subscriptionEnds
        plan
    }
    roles {
        name
    }
    linkedUsers {
        userId
        vehicleId
        receiveNotification
    }
    userDevices {
        deviceId
    }
    identifiers {
        intercomWeb
    }
}`,
    });

    return res;
}
