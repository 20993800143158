import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { pick, keys, throttle } from "lodash";
import { raiseEvent } from "@pedal/infrastructure";
import { DeviceLocation, DeviceCoordinates, PedalConnectType } from "api";
import { RootState } from "store";
import { EventTypes } from "common";
import { LocationState } from "./locationTypes";
import { getDeviceLocation } from "./queries/getDeviceLocation";
import { setDeviceLocation as setDeviceLocationApi } from "./mutations/setDeviceLocation";

const getDeviceLocationByVehicleId = createAsyncThunk(
    "location/getDeviceLocationByVehicleId",
    async (id: string) => {
        const res = await getDeviceLocation(id);
        return res;
    }
);

async function setDeviceLocation(location: DeviceCoordinates, rootState: () => RootState) {
    const vehicle = rootState().currentVehicle.vehicle;
    if (!vehicle) {
        throw new Error("Vehicle is required");
    }

    await setDeviceLocationApi(vehicle.id, location);

    return location;
}
const throttledSetDeviceLocation = throttle(setDeviceLocation, 5000);

const setLocation = createAsyncThunk(
    "location/setLocation",
    async (location: DeviceCoordinates, thunkApi) =>
        setDeviceLocation(location, thunkApi.getState as () => RootState)
);

const asyncUpdateLocation = createAsyncThunk(
    "location/updateLocation",
    async ({ saveOnly, ...location }: DeviceCoordinates & { saveOnly: boolean }, thunkApi) => {
        await throttledSetDeviceLocation(location, thunkApi.getState as () => RootState);
        return saveOnly ? undefined : location;
    }
);

const initialState: LocationState = {
    location: undefined,
    coordinates: undefined,
    deviceType: "MOBILE",
    isConnected: false,
    requiresPermission: false,
    loading: false,
};

function calculateIsConnected(state: LocationState) {
    return state.deviceType === "SENSOR" || state.deviceType === "SERVICE";
}

function setLocationFromDeviceLocation(state: LocationState, location: DeviceLocation | null) {
    state.loading = false;
    if (location !== null) {
        state.location = location;
        state.coordinates = pick(location, "latitude", "longitude");
        state.deviceType = location.device.type;
        state.isConnected = calculateIsConnected(state);
        state.requiresPermission = false;
    } else {
        (keys(initialState) as [keyof LocationState]).forEach(
            (k) => ((state[k] as any) = initialState[k])
        );
    }
}

const eventHandlers = [
    {
        filter: (type: string) =>
            type.startsWith(locationSlice.name) &&
            !type.includes("/pending") &&
            !type.includes("/rejected") &&
            type !== setLoading.type &&
            type !== setLocationRequiresPermission.type,
        handler: () => {
            raiseEvent(EventTypes.locationUpdated);
        },
    },
];

const locationSlice = createSlice({
    name: "location",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(getDeviceLocationByVehicleId.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getDeviceLocationByVehicleId.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(
            getDeviceLocationByVehicleId.fulfilled,
            (state, action: PayloadAction<DeviceLocation | null>) => {
                setLocationFromDeviceLocation(state, action.payload);
            }
        );
        builder.addCase(setLocation.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(setLocation.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(
            setLocation.fulfilled,
            (state, action: PayloadAction<DeviceCoordinates | undefined>) => {
                if (!action.payload) {
                    return state;
                }

                const { deviceType, ...coordinates } = action.payload;

                state.requiresPermission = false;
                state.loading = false;
                state.deviceType = deviceType;
                state.isConnected = calculateIsConnected(state);
                state.coordinates = coordinates;
            }
        );
        builder.addCase(
            asyncUpdateLocation.fulfilled,
            (state, action: PayloadAction<DeviceCoordinates | undefined>) => {
                if (!action.payload) {
                    return state;
                }

                const { deviceType, ...coordinates } = action.payload;

                state.deviceType = deviceType;
                state.isConnected = calculateIsConnected(state);
                state.coordinates = coordinates;
            }
        );
    },
    reducers: {
        setLocationFromVehicle(state, action: PayloadAction<DeviceLocation | null>) {
            if (!state) {
                return state;
            }

            setLocationFromDeviceLocation(state, action.payload);
        },
        setLocationRequiresPermission(state) {
            if (!state) {
                return state;
            }

            state.loading = false;
            state.requiresPermission = true;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            if (!state) {
                return state;
            }

            state.loading = action.payload;
        },
        setConnectedDeviceType(
            state,
            action: PayloadAction<{ resetLocation?: boolean; deviceType: PedalConnectType }>
        ) {
            if (!state) {
                return state;
            }

            state.deviceType = action.payload.deviceType;
            state.isConnected = true;

            if (action.payload.resetLocation) {
                // reset location
                state.location = undefined;
                state.coordinates = undefined;
            }
        },
        resetDeviceType(state) {
            if (!state) {
                return state;
            }

            state.deviceType = initialState.deviceType;
            state.isConnected = false;
        },
    },
});

export const {
    setLocationFromVehicle,
    setLocationRequiresPermission,
    setLoading,
    setConnectedDeviceType,
    resetDeviceType,
} = locationSlice.actions;
export { getDeviceLocationByVehicleId, setLocation, asyncUpdateLocation };

export { eventHandlers };

export default locationSlice.reducer;
