import { formatDistanceStrict, formatDistanceToNow, formatDistanceToNowStrict } from "date-fns";

const defaultOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
};
const monthOptions: Intl.DateTimeFormatOptions = { month: "long" };
const monthDateOptions: Intl.DateTimeFormatOptions = { month: "short", day: "numeric" };
const shortDateTimeOptions: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    hour: "2-digit",
    minute: "2-digit",
};
const fullDateTimeOptions: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
};

export const locale = "en-GB";
export const numericDateStringOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
};
export const timeOptions: Intl.DateTimeFormatOptions = { hour: "2-digit", minute: "2-digit" };

export function toDateString(dateTime: Date | undefined | null) {
    if (!dateTime) {
        return;
    }

    const str = dateTime.toISOString().split("T")[0];
    return str;
}

export function dateTimeToNumericDateString(dateTime: string) {
    return new Date(dateTime).toLocaleString(locale, numericDateStringOptions);
}

export function dateTimeToDateString(dateTime: string) {
    return new Date(dateTime).toLocaleString(locale, defaultOptions);
}

export function dateTimeToFullDateTimeString(dateTime: string) {
    return new Date(dateTime).toLocaleString(locale, fullDateTimeOptions);
}

export function dateTimeToShortDateTimeString(dateTime: string) {
    return new Date(dateTime).toLocaleString(locale, shortDateTimeOptions);
}

export function dateTimeToMonthDateString(dateTime: string) {
    const date = new Date(dateTime);
    return date.toLocaleDateString(locale, monthDateOptions);
}

export function dateTimeToMonthString(dateTime: string) {
    const date = new Date(dateTime);
    return date.toLocaleString(locale, monthOptions);
}

export function dateTimeToWeekdayString(dateTime: string | Date) {
    const d = typeof dateTime === "string" ? new Date(dateTime) : dateTime;
    return d.toLocaleString(locale, {
        weekday: "short",
        day: "numeric",
        month: "long",
    });
}

export function dateTimeToTimeString(dateTime: string) {
    var date = new Date(dateTime);
    return date.toLocaleTimeString(locale, timeOptions);
}

export function getDistanceBetweenDates(dateTimeStrStart: string, dateTimeStrEnd: string) {
    const dateTimeStart = new Date(dateTimeStrStart);
    const dateTimeEnd = new Date(dateTimeStrEnd);

    return formatUnit(formatDistanceStrict(dateTimeStart, dateTimeEnd));
}

export function getFullDistanceToNowDate(dateTimeStr: string) {
    const dateTime = new Date(dateTimeStr);
    return formatUnit(formatDistanceToNow(dateTime, { addSuffix: true }));
}

export function getDistanceToNowDate(dateTimeStr: string) {
    const dateTime = new Date(dateTimeStr);
    return formatUnit(formatDistanceToNowStrict(dateTime));
}

// unfortunately date-fns doesn't have support to change the unit string representation, e.g mins instead of minutes
// so for now just do it manually
function formatUnit(str: string) {
    return str.replace("minute", "min").replace("second", "sec");
}
