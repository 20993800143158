import React from "react";
import { styled } from "@material-ui/core/styles";
import { LinearProgress, LinearProgressProps } from "@material-ui/core";

type ProgressLoaderProps = Pick<LinearProgressProps, "color">;

const LoaderContainer = styled("div")({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
});

function ProgressLoader({ color }: ProgressLoaderProps) {
    return (
        <LoaderContainer>
            <LinearProgress color={color} />
        </LoaderContainer>
    );
}

export { ProgressLoader };
export type { ProgressLoaderProps };
