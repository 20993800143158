import { listenEvent, raiseEvent } from "@pedal/infrastructure";
import { GraphQLBaseResponse } from "@pedal/pedal-api";
import { EventTypes } from "../eventTypes";

const onErrorMessageEvent = "onErrorMessage";

function setupApiErrorsListener() {
    listenEvent<GraphQLBaseResponse>(EventTypes.onApiError, (res) => {
        const errors = res.errors;
        if (!errors || errors.length === 0) {
            return;
        }

        const messages = errors.map((e) => e.message);

        raiseEvent(onErrorMessageEvent, messages);
    });
}

export { setupApiErrorsListener };
