import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Garage } from "../types";
import { GarageItemDetails } from "../GarageItemDetails/GarageItemDetails";
import { GarageItemServices } from "./GarageItemServices";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    container: {
        "& > :first-child": {
            paddingBottom: theme.spacing(1),
            [theme.breakpoints.up("sm")]: {
                paddingRight: theme.spacing(1),
                paddingBottom: 0,
            },
        },
    },
}));

type Props = {
    garage: Garage;
    className?: string;
};

const GarageItem = ({ garage, className }: Props) => {
    const styles = useStyles();
    return (
        <Grid container direction="row" className={clsx(styles.container, className)}>
            <GarageItemDetails garage={garage} item xs={12} sm={6} />
            <GarageItemServices garage={garage} item xs={12} sm={6} />
        </Grid>
    );
};

export { GarageItem };
